import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import utilReducer from "./utilReducer";
import InventoryReducer from "./InventoryReducer";
import UserDataReducer from "./UserDataReducer";
import OrderFulfillmentReducer from "./OrderFulfillmentReducer";
import ReportReducer from "./ReportReducer";
const appReducer = combineReducers({
  form: formReducer,
  util: utilReducer,
  InventoryInfo: InventoryReducer,
  UserInfo: UserDataReducer,
  OrderFulfillmentInfo: OrderFulfillmentReducer,
  ReportInfo: ReportReducer,
});
export const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return appReducer(state, action);
};
