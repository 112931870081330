import React, { Fragment } from "react";
import PurchaseTopBar from "./PurchaseTopBar";
const Order = () => {
  return (
    <Fragment>
      <PurchaseTopBar />
    </Fragment>
  );
};

export default Order;
