import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CircularProgress from "@material-ui/core/CircularProgress";
// import logo from "assets/img/atspig.png";
import logo from "assets/img/AT&S VMI Logo-03.png";


import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import "./login.css";
import { apiConfig } from "../../config/api";
import api from "../../config/apiconfig";
import { withTranslation } from "react-i18next";
import { RTL_SUPPORT, GET_USERNAME, GET_USERINFO } from "store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";
import LoginForm from "./LoginForm";
import EmailValidation from "./EmailValidation";
import Alert from "@material-ui/lab/Alert";
var CryptoJS = require("crypto-js");

const useStyles = makeStyles(styles);

const LoginPage = (props) => {
  let history = useHistory();
  const { t, Rtl, error, loginfail } = props; // t = translation , Rtl = for right to left
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [direction, setDirection] = useState("ltr");
  const [status, setStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Invalid Credentials");
  const [Emailstatus, setEmailStatus] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [loginFlow, setloginFlow] = useState("login");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const useCompare = (val) => {
    const prevVal = usePrevious(val);
    return prevVal !== val;
  };
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  let prevRtl = useCompare(Rtl);
  useEffect(() => {
    if (prevRtl) {
      Rtl ? setDirection("rtl") : setDirection("ltr");
    }
  }, [Rtl, prevRtl]);

  //Email validation
  const handleEmailSubmit = (formvalues) => {
    setSpinner(true);
    api
      .post(
        apiConfig.ResetPasswordMail,
        { email: formvalues.username.toUpperCase() },
        { handlerEnabled: false }
      )
      .then((res) => {
        setSpinner(false);
        setEmailStatus(res.data.STATUS === "SUCCESS" ? true : false);
        if (res.data.STATUS === "SUCCESS" ? true : false) {
        }
      })
      .catch((error) => {});
  };

  //login submit
  const handleSubmit = (formvalues) => {
    sessionStorage.setItem("username", formvalues.username);
    props.GET_USERNAME(formvalues.username);
    setSpinner(true);
    if (formvalues.username) {
      var ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(formvalues.password),
        "atswe1c0me@4i"
      ).toString();
      let userData = {
        username: formvalues.username.toUpperCase(),
        password: ciphertext,
      };
      api
        .post(apiConfig.Login, userData, { handlerEnabled: false })
        .then((res) => {
          setSpinner(false);
          if (res.data.userinfo[0].STATUS === "VALID") {
            sessionStorage.setItem("tokens", res.data.access_token);
            sessionStorage.setItem("refreshToken", res.data.refresh_token);
            sessionStorage.setItem(
              "AccessLevel",
              res.data.userinfo[0].ACCESS_LEVEL
            );
            sessionStorage.setItem("AGREEMENT", res.data.userinfo[0].AGREEMENT);
            // SALESPERSON_EMAIL_ADDRESS===getState().UserInfo.userInfo.CONTACT_EMAIL
            props.GET_USERINFO(res.data.userinfo[0]);
            if (res.data.userinfo[0].ACCOUNT_TYPE === "SALESPERSON") {
              sessionStorage.setItem("ACCOUNT_TYPE", "SALESPERSON");
              history.push("/admin/securityPrivacy");
            } else if (res.data.userinfo[0].ACCOUNT_TYPE === "WAREHOUSE") {
              sessionStorage.setItem("ACCOUNT_TYPE", "WAREHOUSE");
              history.push("/admin/securityPrivacy");
            } else if (res.data.userinfo[0].ACCOUNT_TYPE === "IT") {
              sessionStorage.setItem("ACCOUNT_TYPE", "IT");
              history.push("/admin/securityPrivacy");
            } else if (res.data.userinfo[0].ACCOUNT_TYPE === "CUSTOMER") {
              sessionStorage.setItem("ACCOUNT_TYPE", "CUSTOMER");
              history.push("/admin/personalInfo");
            } else {
              sessionStorage.setItem("ACCOUNT_TYPE", "OTHERS");
              history.push("/admin/securityPrivacy");
            }
          } else {
            if (res.data.userinfo[0].STATUS === "LOCKED") {
              setErrorMessage("Account is Locked. Please Reset the password!");
            } else if ((res.data.userinfo[0].STATUS = "FAILED")) {
              setErrorMessage("Invalid Credentials");
            }
            setStatus(true);
          }
        })
        .catch((error) => {
          setSpinner(false);
          if (error.response) {
            if (error.response.status === "403") {
              setStatus(true);
              setErrorMessage(error.response.data.message);
            }
          } else {
            setStatus(true);
            setErrorMessage("Something went wrong Please try again");
          }
        });
    }
  };

  //Language Detection
  const detectLanguage = (language) => {
    props.i18n.changeLanguage(language);
    props.RTL_SUPPORT(language);
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem>
          <div className="logoheader">
            <img src={logo} alt="logo" />
          </div>
          {loginFlow === "login" ? (
            <Card login className={classes[cardAnimaton] + " " + "logincard"}>
              <CardBody className="alignCenter">
                <span style={{ fontSize: "24px" }}>Welcome to AT&S VMI!</span>
                <LoginForm onSubmit={handleSubmit} />
              </CardBody>
              <div className="alignCenter">
                {spinner ? (
                  <div className="spinner">
                    <CircularProgress className="innerspinner" />
                  </div>
                ) : null}
                {status ? (
                  <div className="loginerror">{errorMessage}</div>
                ) : null}
                <div className="forgotPwd">
                  <a
                    onClick={() => {
                      setloginFlow("forgot");
                      setStatus(null);
                    }}
                  >
                    Forgot Password
                  </a>
                </div>
              </div>
            </Card>
          ) : (
            <Card login className={classes[cardAnimaton] + " " + "logincard"}>
              <CardBody className="alignCenter">
                <span style={{ fontSize: "24px" }}>Forgot Password</span>
                {Emailstatus === null ? null : Emailstatus ? (
                  <div className="loginsuccess">
                    <Alert severity="success">
                      Reset password link has been sent to your mail
                    </Alert>
                  </div>
                ) : (
                  <div className="loginerror">
                    <Alert severity="error">
                      Something went wrong please try after sometime
                    </Alert>
                  </div>
                )}
                <EmailValidation onSubmit={handleEmailSubmit} />
              </CardBody>
              <div className="alignCenter">
                {spinner ? (
                  <div className="spinner">
                    <CircularProgress className="innerspinner" />
                  </div>
                ) : null}
                <div className="forgotPwd">
                  <a
                    onClick={() => {
                      setloginFlow("login");
                      setEmailStatus(null);
                    }}
                  >
                    {" "}
                    Login{" "}
                  </a>
                </div>
              </div>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Rtl: state.util.Rtl,
  };
};

const mapDispatchToProps = {
  RTL_SUPPORT,
  GET_USERNAME,
  GET_USERINFO,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation("translations")
)(LoginPage);
