import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import Moment from "moment";
import { compose } from "redux";
import InputLabel from "@material-ui/core/InputLabel";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  Line,
} from "recharts";
import { GET_USERINFO } from "../../../store/actions/index";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { connect } from "react-redux";
const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "16.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
    },
  },
  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
});

const columns = [
  {
    id: "Submitted",
    label: "Submitted",
    align: "center",
    minWidth: 100,
  },
  {
    id: "Confirmed Orders",
    label: "Confirmed Orders",
    align: "center",
    minWidth: 100,
  },
  {
    id: "Peding Delivery",
    label: "Pending Delivery",
    align: "center",
    minWidth: 100,
  },
  {
    id: "YTD Delivered",
    label: "YTD Delivered",
    align: "center",
    minWidth: 100,
  },
];

class CostSavingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AOverdue: [],
      OrderVolume: [],
      OrderUtilization: { PERCENTAGE: 0 },
      OrderYTDOverview: [],
    };
  }
  componentDidMount() {
    this.getCostSavingApi();
    if (!this.props.UserInfo.CONTACT_EMAIL) {
      api
        .get(apiConfig.UserInfo, { handlerEnabled: true })
        .then((res) => {
          this.props.GET_USERINFO(res.data[0]);
          this.ShipToLov();
        })
        .catch((error) => {});
    }
  }
  getCostSavingApi = () => {
    this.setState({ spinner: true });
    api
      .get(apiConfig.AOverdue, { handlerEnabled: true })
      .then((res) => {
        this.setState({ AOverdue: res.data });
      })
      .catch((error) => {});
    api
      .get(apiConfig.OrderUtilization, { handlerEnabled: true })
      .then((res) => {
        this.setState({ OrderUtilization: res.data });
      })
      .catch((error) => {});
    api
      .get(apiConfig.OrderYTDOverview, { handlerEnabled: true })
      .then((res) => {
        this.setState({ OrderYTDOverview: res.data });
      })
      .catch((error) => {});
    api
      .get(apiConfig.OrderVolume, { handlerEnabled: true })
      .then((res) => {
        this.setState({ spinner: false });
        this.setState({ OrderVolume: res.data });
      })
      .catch((error) => {});
  };
  getOverDuetotal = () => {
    var overDueReceivables = 0.0;
    this.state.AOverdue.map((data, i) => {
      overDueReceivables += parseFloat(data.OUTSTANDING_BALANCE.toFixed(2));
    });
    return overDueReceivables;
  };
  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip tooltipbg">
          <p className="label" style={{ color: "black" }}>
            {label}
          </p>
          <p className="label" style={{ color: "black" }}>{`${
            payload[0].name
          } : ${parseFloat(payload[0].value)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</p>
        </div>
      );
    }
    return null;
  };
  render() {
    const {
      OrderUtilization,
      OrderYTDOverview,
      OrderVolume,
      spinner,
    } = this.state;
    var OrderYTDOverviewBySort = OrderYTDOverview.sort(function(a, b) {
      return a.ORDER_BY - b.ORDER_BY;
    });
    const { CURRENCY_CODE, AGREEMENT } = this.props.UserInfo;
    var countarry = [];
    var totalarry = [];
    let count = {};
    let total = {};
    OrderVolume.map((data, i) => {
      if (data.STATUS === "Submitted") {
        count[data.STATUS] = data.COUNT;
        total[data.STATUS] = data.TOTAL;
      } else if (data.STATUS === "Confirmed") {
        count[data.STATUS] = data.COUNT;
        total[data.STATUS] = data.TOTAL;
      } else if (data.STATUS === "Pending Delivery") {
        count["PendingDelivery"] = data.COUNT;
        total["PendingDelivery"] = data.TOTAL;
      } else if (data.STATUS === "YTD Delivered") {
        count["YTDDelivered"] = data.COUNT;
        total["YTDDelivered"] = data.TOTAL;
      } else {
        count["nostatus"] = 0;
      }
    });
    totalarry.push(total);
    countarry.push(count);
    return (
      <div>
        {spinner === false ? (
          <div>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardBody>
                    <GridContainer className="costsTopBar">
                      <GridItem xs={12} sm={12} md={8}>
                        {" "}
                        <div className="grapheader">Order Summary</div>
                        <Table className="orderFulfillment">
                          <TableHead>
                            <TableRow hover>
                              {columns.map((column) => (
                                <TableCell
                                  className="bold tablePadding"
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {countarry.map((data, i) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className="tablePadding tablecell"
                                  >
                                    {data.Submitted ? data.Submitted : 0}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tablePadding tablecell"
                                  >
                                    {data.Confirmed ? data.Confirmed : 0}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tablePadding tablecell"
                                  >
                                    {data.PendingDelivery
                                      ? data.PendingDelivery
                                      : 0}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tablePadding tablecell"
                                  >
                                    {data.YTDDelivered ? data.YTDDelivered : 0}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            {totalarry.map((data, i) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className="tablePadding tablecell bold"
                                  >
                                    {data.Submitted
                                      ? `${CURRENCY_CODE}  ${parseFloat(
                                          data.Submitted
                                        )
                                          .toFixed(2)
                                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                                      : 0.0}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tablePadding tablecell bold"
                                  >
                                    {data.Confirmed
                                      ? `${CURRENCY_CODE}  ${parseFloat(
                                          data.Confirmed
                                        )
                                          .toFixed(2)
                                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                                      : 0.0}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tablePadding tablecell bold"
                                  >
                                    {data.PendingDelivery
                                      ? `${CURRENCY_CODE}  ${parseFloat(
                                          data.PendingDelivery
                                        )
                                          .toFixed(2)
                                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                                      : 0.0}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tablePadding tablecell bold"
                                  >
                                    {data.YTDDelivered
                                      ? `${CURRENCY_CODE}  ${parseFloat(
                                          data.YTDDelivered
                                        )
                                          .toFixed(2)
                                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                                      : 0.0}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>{" "}
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={4}
                        className="alignLabelCostReport mbtm-40 bold"
                      >
                        <div className="costReportDate">
                          <InputLabel className="reporttextcolor">
                            {Moment(new Date()).format("MM/DD/YYYY")}
                          </InputLabel>
                        </div>
                        <div>
                          <InputLabel className="reporttextcolor mbtm-10">
                            Overdue Receivables
                          </InputLabel>
                          <InputLabel
                            style={{ color: "red" }}
                          >{`${CURRENCY_CODE} ${this.getOverDuetotal()
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</InputLabel>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer className="CostSavingContainer">
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                      <div className="grapheader">
                        Last Year Vs YTD Delivery
                      </div>
                      <ComposedChart
                        width={500}
                        height={300}
                        data={OrderYTDOverviewBySort}
                        style={{ margin: "auto" }}
                      >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="AGAINST" />
                        <YAxis />
                        <Tooltip content={this.CustomTooltip} />
                        <Bar dataKey="TOTAL" barSize={20} fill="#8ddfe3" />
                        <Line
                          type="monotone"
                          dataKey="TOTAL"
                          stroke="#ee916c"
                        />
                      </ComposedChart>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {AGREEMENT === "Y" ? (
                        <Fragment>
                          <div className="grapheader">
                            Contract Completion Status
                          </div>
                          <CircularProgressbar
                            value={OrderUtilization.PERCENTAGE}
                            text={`${OrderUtilization.PERCENTAGE}%`}
                          />
                        </Fragment>
                      ) : null}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridContainer>
          </div>
        ) : (
          <div className="spinner">
            <CircularProgress className="innerspinner" />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserInfo: state.UserInfo.userInfo,
  };
};
export default compose(
  connect(
    mapStateToProps,
    { GET_USERINFO }
  ),
  withStyles(styles, { withTheme: true })
)(CostSavingReport);
