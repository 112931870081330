import React, { Component, Fragment } from "react";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
class ExcelDownload extends Component {
  render() {
    return (
      <Fragment>
        <ExcelFile hideElement="false" filename={this.props.excelName}>
          <ExcelSheet
            dataSet={this.props.data}
            name={this.props.excelName}
          ></ExcelSheet>
        </ExcelFile>
      </Fragment>
    );
  }
}
export default ExcelDownload;
