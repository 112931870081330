import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { compose } from "redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import withStyles from "@material-ui/core/styles/withStyles";
const styles = (theme) => ({
  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
});
const columns = [
  {
    id: "PRODUCT_CODE",
    label: "Product Code",
    align: "left",
    minWidth: 100,
  },
  {
    id: "PRODUCT_CATEGORY",
    label: "Product Category",
    align: "left",
    minWidth: 100,
  },
  {
    id: "PRODUCT_CLASS",
    label: "Product Class",
    align: "left",
    minWidth: 94,
  },
  {
    id: "PRIMARY_UOM_CODE",
    label: "Primary UOM Code",
    align: "left",
    minWidth: 94,
  },
  {
    id: "AGREEMENT_QTY",
    label: "Agreement Quantity",
    align: "right",
    minWidth: 94,
  },
  {
    id: "AVAILABLE_QTY",
    label: "Available Quantity",
    align: "right",
    minWidth: 94,
  },
  {
    id: "AGREEMENT_VALUE",
    label: "Agreement Value",
    align: "right",
    minWidth: 94,
  },
  {
    id: "AVAILABLE_VALUE",
    label: "Agreement Value",
    align: "right",
    minWidth: 94,
  },
  {
    id: "CONSUMPTION_QTY",
    label: "Consumption Quantity",
    align: "right",
    minWidth: 94,
  },
  {
    id: "CONSUMPTION_VALUE",
    label: "Consumption Value",
    align: "left",
    minWidth: 94,
  },
];
class AgreedTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 5,
      page: 0,
      selectedRowId: null,
    };
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  render() {
    const { classes } = this.props;
    const { spinner } = this.props.headervalue;
    return (
      <div>
        <GridContainer className="ordertable">
          <GridItem xs={12}>
            <Card>
              <CardBody>
                {spinner === false ? (
                  this.props.agreedActualTable.length === 0 ? (
                    <div className="nodatafound">No Data Found</div>
                  ) : (
                    <Table>
                      <TableHead>
                        <TableRow hover>
                          {columns.map((column) => (
                            <TableCell
                              className="bold tablePadding"
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.agreedActualTable
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((data, i) => {
                            return (
                              <TableRow
                                hover
                                onClick={() => {
                                  this.setState({ selectedRowId: i });
                                }}
                                selected={this.state.selectedRowId === i}
                                key={i}
                                classes={{
                                  hover: classes.hover,
                                  selected: classes.selected,
                                }}
                                className={classes.tableRow}
                              >
                                <TableCell
                                  align="left"
                                  className="tablePadding tablecell"
                                >
                                  {data.PRODUCT_CODE}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding tablecell"
                                >
                                  {data.PRODUCT_CATEGORY}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding tablecell"
                                >
                                  {data.PRODUCT_CLASS}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding tablecell"
                                >
                                  {data.PRIMARY_UOM_CODE}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding tablecell"
                                >
                                  {data.AGREEMENT_QTY}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding tablecell"
                                >
                                  {data.AVAILABLE_QTY}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding tablecell"
                                >
                                  {parseFloat(data.AGREEMENT_VALUE)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding tablecell"
                                >
                                  {parseFloat(data.AVAILABLE_VALUE)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding tablecell"
                                >
                                  {data.CONSUMPTION_QTY}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding tablecell"
                                >
                                  {parseFloat(data.CONSUMPTION_VALUE)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={this.props.agreedActualTable.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            SelectProps={{
                              inputProps: { "aria-label": "rows per page" },
                              native: true,
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  )
                ) : (
                  <div className="spinner">
                    <CircularProgress className="innerspinner" />
                  </div>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default compose(withStyles(styles, { withTheme: true }))(AgreedTable);
