import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import PageviewIcon from "@material-ui/icons/Pageview";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { GET_MYORDERINFO } from "../../../store/actions/index";
import { compose } from "redux";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { IndicatorsContainer } from "../../../utils/components/InputComponent";
import Moment from "moment";

const customStyles = {
  control: (base, state, meta, input) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
    // width: "94%",
    height: 47,
    minHeight: 47,
    // overwrittes hover style
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      boxShadow: "none",
    },
  }),
};

const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
    },
  },
  table: {
    width: "max-content",
  },
  datePick: {
    "&.MuiFormControl-marginNormal": {
      marginTop: "0px !important",
      marginBottom: "0px !important",
    },
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "red",
    },
  },
});
class OrderTopbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      shipToLov: [],
      emptyData: false,
      inventoryData: [],
      rowsPerPage: 5,
      page: 0,
      InventoryInfo: [],
      selectedfilter: {},
      shipToLov: [],
      ModelDialog: false,
      selectedorder: [],
      Uomerror: false,
      error: false,
      filteredOrderColumn: [],
      filterOptions: null,
      isLoading: false,
      Alt_addressform: {},
      open: true,
      selectedRowId: null,
      OrderDate: null,
    };
  }

  componentDidMount() {
    let filterOptions = [
      { value: "ORDER_NUMBER", label: "Order Number" },
      { value: "ORDERED_DATE", label: "Order Date" },
    ];
    let statusOptions = [
      { value: "Submitted", label: "Submitted" },
      { value: "Progress", label: "Progress" },
    ];
    this.setState({
      filterOptions: filterOptions,
      statusOptions: statusOptions,
    });
  }

  handleDateChange = (Date) => {
    this.setState({ OrderDate: Date });
    if (Date === null) {
      this.props.GET_MYORDERINFO(this.props.MyOrderSearch);
    } else {
      let date = Moment(Date).format("MM/DD/YYYY");
      let MyOrderSearch = this.props.MyOrderSearch.filter(
        (o) => Moment(o.ORDERED_DATE).format("MM/DD/YYYY") === date
      );
      this.props.GET_MYORDERINFO(MyOrderSearch);
    }
  };
  handleChangeFilter = (value) => {
    this.setState({ selectedfilter: value });
  };
  onclickSearch = (filterText) => {
    var result = this.props.MyOrderSearch.filter((objectval) =>
      Object.keys(objectval).some(
        (k) =>
          objectval[k] != null &&
          objectval[k]
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())
      )
    );
    this.props.GET_MYORDERINFO(result);
  };
  handleChangeStatus = (value) => {
    this.setState({ selectedStatus: value });
    let MyOrderSearch = this.props.MyOrderSearch.filter(
      (o) => o.STATUS === value.value
    );
    this.props.GET_MYORDERINFO(MyOrderSearch);
  };
  render() {
    const { classes } = this.props;
    var selectedfilter = this.state.selectedfilter.value;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GridContainer className="ordertopbar">
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">
                      Search By
                    </InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selectedfilter}
                      onChange={this.handleChangeFilter}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.filterOptions}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">
                      Search Criteria
                    </InputLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} className="mbtm-10">
                    {selectedfilter === "ORDERED_DATE" ? (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputProps={{
                            style: {
                              padding: "13.5px 14px",
                            },
                          }}
                          fullWidth
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          placeholder="Select Order Date"
                          autoOk={true}
                          value={this.state.OrderDate}
                          onChange={this.handleDateChange}
                          // onBlur={()=>this.onBlurLineDate(data.EstimatedDate,data)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          className={`${classes.datePick} ${classes.materialinput}`}
                        />
                      </MuiPickersUtilsProvider>
                    ) : selectedfilter === "STATUS" ? (
                      <ReactSelect
                        value={this.state.selectedStatus}
                        onChange={this.handleChangeStatus}
                        components={{ IndicatorsContainer }}
                        fullWidth
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                        styles={customStyles}
                        style={{ width: "100%" }}
                        options={this.state.statusOptions}
                        menuPlacement={this.state.portalPlacement}
                      />
                    ) : selectedfilter === "ORDER_NUMBER" ? (
                      <TextField
                        placeholder="Search"
                        variant="outlined"
                        onChange={(event) => {}}
                        className={classes.materialinput}
                        fullWidth
                        onChange={(e) => {
                          this.onclickSearch(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PageviewIcon className="search_icon" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <TextField
                        placeholder="Search"
                        variant="outlined"
                        onChange={(event) => {}}
                        className={classes.materialinput}
                        onChange={(e) => {
                          this.onclickSearch(e.target.value);
                        }}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PageviewIcon className="search_icon" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserInfo: state.UserInfo.userInfo,
    MyOrderSearch: state.OrderFulfillmentInfo.MyOrderSearch,
  };
};
export default compose(
  connect(
    mapStateToProps,
    { GET_MYORDERINFO }
  ),
  withStyles(styles, { withTheme: true })
)(OrderTopbar);
