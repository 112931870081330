import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { compose } from "redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DisputesForm from "./DisputesForm";
import { apiConfig } from "../config/api";
import api from "../config/apiconfig";
import withStyles from "@material-ui/core/styles/withStyles";
import PageviewIcon from "@material-ui/icons/Pageview";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import { DISPUTE_DATA } from "../../src/store/actions/index";
import { connect } from "react-redux";
import "./Dispute.css";
const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
});
const columns = [
  {
    id: "DISPUTE_ID",
    label: "Dispute Id",
    align: "left",
    minWidth: 100,
  },
  {
    id: "Item",
    label: "Item",
    align: "left",
    minWidth: 100,
  },
  {
    id: "Description",
    label: "Description",
    align: "left",
    minWidth: 135,
  },
  {
    id: "Status",
    label: "Status",
    align: "left",
    minWidth: 50,
  },
  {
    id: "Quantity",
    label: "Quantity",
    align: "right",
    minWidth: 50,
  },
  {
    id: "Amount",
    label: "Amount",
    align: "right",
    minWidth: 100,
  },
  {
    id: "Created By",
    label: "Created By",
    align: "left",
    minWidth: 100,
  },
  {
    id: "Creation Date",
    label: "Creation Date",
    align: "left",
    minWidth: 100,
  },
  {
    id: "Updated By",
    label: "Updated By",
    align: "left",
    minWidth: 100,
  },
  {
    id: "Updated Date",
    label: "Updated Date",
    align: "left",
    minWidth: 80,
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 50,
  },
];
class Dispute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      rowsPerPage: 5,
      isFixed: false,
      page: 0,
      selectedRowId: null,
      dialogopen: false,
      selectedRow: {},
      OriginalDisputedItem: [],
      DisputedItem: [],
      flow: null,
    };
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  openDocument = (data, type) => {
    this.setState({
      dialogopen: true,
      selectedRow: data,
      documentViewType: type,
    });
  };
  cancel = () => {
    this.setState({ dialogopen: false, flow: null });
    this.getDisputeItem();
  };
  componentDidMount() {
    this.getDisputeItem();
  }
  getDisputeItem = () => {
    this.setState({ spinner: true });
    api
      .get(apiConfig.GetDispute, { handlerEnabled: true })
      .then((res) => {
        this.setState({ spinner: false });
        this.setState({
          DisputedItem: res.data,
          OriginalDisputedItem: res.data,
        });
      })
      .catch((error) => {});
  };
  onClickAdd = () => {
    this.setState({ dialogopen: true });
    this.props.DISPUTE_DATA();
  };
  SearchProduct = (filtertext) => {
    if (filtertext) {
      var result = this.state.OriginalDisputedItem.filter((objectval) =>
        Object.keys(objectval).some(
          (k) =>
            objectval[k] != null &&
            objectval[k]
              .toString()
              .toLowerCase()
              .includes(filtertext.toLowerCase())
        )
      );
      this.setState({ DisputedItem: result });
    } else {
      this.setState({ DisputedItem: this.state.OriginalDisputedItem });
    }
  };
  onClickEdit = (data) => {
    this.setState({ dialogopen: true, flow: "edit" });
    this.props.DISPUTE_DATA(data);
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.dialogopen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title" className="dialogboxheader">
            {this.state.flow === "edit" ? "Update Dispute" : "Add Dispute"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <DisputesForm cancel={this.cancel} flow={this.state.flow} />
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {this.state.spinner === false ? (
          this.state.DisputedItem === 0 ? (
            <div className="nodatafound">No Data Found</div>
          ) : (
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardBody style={{ overflowX: "scroll" }}>
                    <Fragment>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <TextField
                            placeholder="Search"
                            variant="outlined"
                            onChange={(event) => {
                              this.SearchProduct(event.target.value);
                            }}
                            className={classes.materialinput}
                            style={{ width: "32%" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <PageviewIcon className="search_icon" />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Button
                            onClick={() => this.onClickAdd()}
                            className="Disputebton"
                          >
                            Add Dispute
                          </Button>
                        </div>
                      </div>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                className="bold tablePadding"
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.DisputedItem.slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          ).map((data, i) => {
                            return (
                              <TableRow
                                hover
                                onClick={() => {
                                  this.setState({ selectedRowId: i });
                                }}
                                selected={this.state.selectedRowId === i}
                                key={i}
                                classes={{
                                  hover: classes.hover,
                                  selected: classes.selected,
                                }}
                                className={classes.tableRow}
                              >
                                <TableCell
                                  align="left"
                                  className="tablePadding tablecell"
                                >
                                  {data.DISPUTE_ID}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding tablecell"
                                >
                                  {data.ITEM}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  {data.DESCRIPTION}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  {data.STATUS}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding tablecell"
                                >
                                  {parseFloat(data.QUANTITY)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding"
                                >
                                  {parseFloat(data.AMOUNT)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  {data.CREATED_BY}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  {data.CREATION_DATE}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  {data.UPDATED_BY}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  {data.UPDATE_DATE}
                                </TableCell>
                                <TableCell
                                  align="cenetr"
                                  className="tablePadding"
                                >
                                  <EditIcon
                                    onClick={() => {
                                      this.onClickEdit(data);
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25]}
                              count={this.state.DisputedItem.length}
                              rowsPerPage={this.state.rowsPerPage}
                              page={this.state.page}
                              SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                              }}
                              onChangePage={this.handleChangePage}
                              onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Fragment>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )
        ) : (
          <div className="spinner">
            <CircularProgress className="innerspinner" />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    DisputeData: state.ReportInfo.DisputeData,
  };
};
export default compose(
  connect(
    mapStateToProps,
    { DISPUTE_DATA }
  ),
  withStyles(styles, { withTheme: true })
)(Dispute);
