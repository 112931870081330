const INITIAL_STATE = {
  PurchaseData: [],
  PurchaseQuantityData: [],
  DisputeData: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PURCHASE_DATA":
      return {
        ...state,
        PurchaseData: action.payload,
      };
    case "DISPUTE_DATA":
      return {
        ...state,
        DisputeData: action.payload,
      };
    case "PURCHASE_QUANTITY_DATA":
      return {
        ...state,
        PurchaseQuantityData: action.payload,
      };

    default:
      return state;
  }
};
