import { apiConfig } from "../../config/api";
import api from "../../config/apiconfig";
import { initialize } from "redux-form";
import history from "../../config/history";
export const Filter_InventoryData = (response) => ({
  type: "INVENTORY_DATA",
  payload: response,
});
export const Filter_InventorySaleData = (response) => async (
  dispatch,
  getState
) => {
  dispatch({ type: "INVENTORYSALE_DATA", payload: response });
};
export const Spinner = (response) => ({ type: "SPINNER", payload: response });
export const ShipToLov = () => async (dispatch, getState) => {
  let userData = { username: sessionStorage.getItem("username") };
  var ShipToLOV = await api.post(apiConfig.ShipToLOV, userData);
  dispatch({ type: "SHIP_TO_LOV", payload: ShipToLOV.data });
};
export const GET_USERINFO = (response) => async (dispatch, getState) => {
  dispatch({ type: "USERINFO", payload: response });
};

export const GET_USERNAME = (response) => {
  return { type: "USERNAME", payload: response };
};
export const REDUX_FORM_CLEAR = (response) => async (dispatch, getState) => {
  dispatch(initialize(response, {}));
};

export const GET_MYORDERINFO = (response) => ({
  type: "GET_MYORDERINFO",
  payload: response,
});

export const SELECTED_ORDER = (response) => ({
  type: "SELECTED_ORDER",
  payload: response,
});
export const ORDER_HEADER_DETAILS = (response) => ({
  type: "ORDER_HEADER_DETAILS",
  payload: response,
});

export const ADDTO_CART_QUANTITY = (response) => ({
  type: "ADDTO_CART_QUANTITY",
  payload: response,
});
export const PURCHASE_DATA = (response) => ({
  type: "PURCHASE_DATA",
  payload: response,
});
export const PURCHASE_QUANTITY_DATA = (response) => ({
  type: "PURCHASE_QUANTITY_DATA",
  payload: response,
});

export const RTL_SUPPORT = (response) => ({
  type: "RTL_SUPPORT",
  payload: response,
});
export const DISPUTE_DATA = (response) => ({
  type: "DISPUTE_DATA",
  payload: response,
});

export const SPINNER = (response) => ({ type: "SPINNER", payload: response });
export const MYORDER_SEARCH = (response) => ({
  type: "MYORDER_SEARCH",
  payload: response,
});
export const LOGOUT_ACTION = () => (dispatch) => {
  sessionStorage.clear();
  history.push("/auth/login-page");
  dispatch({
    type: "RESET",
  });
};

export const OrderFulfillment_Data = (response) => async (
  dispatch,
  getState
) => {
  if (response) {
    dispatch({ type: "ORDERFULFILLMENT_DATA", payload: response });
  } else {
    var inventorydata = await api.get(apiConfig.orderFulfillment, {
      handlerEnabled: true,
    });
    dispatch({ type: "ORDERFULFILLMENT_DATA", payload: inventorydata.data });
  }
};
export const Inventory_Data = () => async (dispatch, getState) => {
  var inventorydata = await api.get(apiConfig.Inventory, {
    handlerEnabled: true,
  });
  dispatch({ type: "INVENTORY_DATA", payload: inventorydata.data });
  dispatch({ type: "INVENTORY_DATA_SEARCH", payload: inventorydata.data });
};

export const InventorySale_Data = () => async (dispatch, getState) => {
  var inventorysaledata = await api.get(apiConfig.InventorySalesperson, {
    handlerEnabled: true,
  });
  dispatch({ type: "INVENTORYSALE_DATA", payload: inventorysaledata.data });
  dispatch({
    type: "INVENTORYSALE_DATA_SEARCH",
    payload: inventorysaledata.data,
  });
};
