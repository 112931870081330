import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { compose } from "redux";
import { connect } from "react-redux";
import { GET_USERINFO } from "../../../store/actions/index";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
class PersonalInfo extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.UserInfo.CONTACT_EMAIL) {
      api
        .get(apiConfig.UserInfo, { handlerEnabled: true })
        .then((res) => {
          this.props.GET_USERINFO(res.data[0]);
        })
        .catch((error) => {});
    }
  }
  render() {
    const {
      COMPANY_NAME,
      CUSTOMER_NAME,
      LOCATION,
      ADDRESS,
      PAYMENT_TERM,
      TAX_CODE,
      CONTACT_NAME,
      CONTACT_NO,
      CONTACT_EMAIL,
      ACCESS_LEVEL,
      OTHER_CONTACT_DETAILS,
      SALESPERSON_NAME,
      SALESPERSON_EMAIL_ADDRESS,
      CURRENCY_CODE,
      CONTACT_POSITION,
      TAX_CODE_DESCRIPTION,
      SALESPERSON_CONTACT_NO,
    } = this.props.UserInfo;
    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader className="personalCard">
                CUSTOMER INFORMATION
              </CardHeader>
              <CardBody>
                <GridContainer style={{ padding: "0 0 5px 1.25rem" }}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div className="labelNoWrap">AT&S Subsidiary Name:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">{COMPANY_NAME}</div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Customer Name:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-20">
                    <div className="personalinfo bold">{CUSTOMER_NAME}</div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Billing Address:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">{ADDRESS}</div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Currency:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">{CURRENCY_CODE}</div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Payment Terms:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">{PAYMENT_TERM}</div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Tax Code:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">
                      {TAX_CODE_DESCRIPTION}
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader className="personalCard">
                PERSON IN CHARGE (PIC)
              </CardHeader>
              <CardBody>
                <GridContainer style={{ padding: "0 0 5px 1.25rem" }}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Name:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">{CONTACT_NAME}</div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Designation:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-20">
                    <div className="personalinfo bold">{CONTACT_POSITION}</div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Contact No:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">{CONTACT_NO}</div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Email:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">
                      {CONTACT_EMAIL ? CONTACT_EMAIL.toLowerCase() : null}
                    </div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Access Level:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">{ACCESS_LEVEL}</div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="personalCard">
                AT&S KEY ACCOUNT MANAGER
              </CardHeader>
              <CardBody>
                <GridContainer style={{ padding: "0 0 5px 1.25rem" }}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Name:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">{SALESPERSON_NAME}</div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Contact No:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-20">
                    <div className="personalinfo bold">
                      {SALESPERSON_CONTACT_NO}
                    </div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Email:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">
                      {SALESPERSON_EMAIL_ADDRESS}
                    </div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className="alignLabel mbtm-10"
                  >
                    <div>Other Contact Details:</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="mbtm-10">
                    <div className="personalinfo bold">
                      {OTHER_CONTACT_DETAILS}
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserInfo: state.UserInfo.userInfo,
  };
};
const mapDispatchToProps = {
  GET_USERINFO,
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PersonalInfo);
