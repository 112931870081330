import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import { compose } from 'redux';
import { connect } from "react-redux";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { LOGOUT_ACTION, SPINNER } from '../../store/actions/index';
const useStyles = makeStyles(styles);
function HeaderLinks(props) {
  const [openNotification, setOpenNotification] = React.useState(null);
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const handleLogout = () => {
    props.LOGOUT_ACTION();
  }


  const [openProfile, setOpenProfile] = React.useState(null);
  const classes = useStyles();
  const { rtlActive } = props;

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });
  return (
    <Fragment>

      <div className="mobilewrap">
        <div className="centeralign">
          <span>Categories</span>
        </div>
      </div>
      <div className={wrapper + " " + "navIcon"}>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? "إجلاء أوزار الأسيوي حين بل, كما"
                        : "Mike John responded to your email"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? "شعار إعلان الأرضية قد ذلك"
                        : "You have 5 new tasks"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? "ثمّة الخاصّة و على. مع جيما"
                        : "You're now friend with Andrew"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive ? "قد علاقة" : "Another Notification"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive ? "قد فاتّبع" : "Another One"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <div onClick={handleLogout} className={managerClasses}>
          <Button
            color="white"
            aria-label="Person"
            style={{ padding: "0px 12px", borderRadius: "30px", height: "41px", width: "41px" }}
            aria-owns={openProfile ? "profile-menu-list" : null}
            aria-haspopup="true"
            className={rtlActive ? classes.buttonLinkRTL + " " + "profile" : classes.buttonLink + " " + "profile"}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
          >
            <Hidden smDown implementation="css">
              <ExitToAppIcon
                className="logouticonblack"
              />
            </Hidden>
            <Hidden mdUp implementation="css">
              <ExitToAppIcon
                className="logouticon"
              />
            </Hidden>
            <Hidden mdUp implementation="css">
              <div className="logoutdiv">
                <span className={classes.linkText} style={{ color: "#202557" }}>
                  {rtlActive ? "الملف الشخصي" : "Log out"}
                </span>
              </div>
            </Hidden>
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
const mapStateToProps = state => {
  return {
    UserInfo: state.UserInfo.userInfo,
  }
}
const mapDispatchToProps = {
  LOGOUT_ACTION, SPINNER
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderLinks)