import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import {
  OrderFulfillment_Data,
  REDUX_FORM_CLEAR,
  ADDTO_CART_QUANTITY,
  GET_USERINFO,
  SELECTED_ORDER,
} from "../../../store/actions/index";
import { Form, Field, reduxForm, getFormValues } from "redux-form";
import InputLabel from "@material-ui/core/InputLabel";
import history from "../../../config/history";
import {
  DynamicTextField,
  renderSelectField,
  renderOrderTextField,
  renderDatePicker,
} from "../../../utils/components/InputComponent";
import { compose } from "redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import Moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Snackbar from "@material-ui/core/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import logo from "../../../assets/img/AT&S VMI Logo-03.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "16.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {},
    "& .MuiOutlinedInput-root": {
      "& fieldset": {},
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
  datePick: {
    "&.MuiFormControl-marginNormal": {
      marginTop: "0px !important",
      marginBottom: "0px !important",
    },
  },
});
class OrderCart extends Component {
  mobileBrowser;
  promap = {};
  totalpriceamt;
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      snackbarMsg: "",
      shipToLov: [],
      emptyData: false,
      inventoryData: [],
      rowsPerPage: 5,
      page: 0,
      InventoryInfo: [],
      selectedShip: null,
      shipToLov: [],
      ModelDialog: false,
      selectedorder: [],
      error: false,
      filteredOrderColumn: [],
      EstimatedDt: new Date().setDate(new Date().getDate() + 7),
      AddtoCartQuantity: [],
      alert: null,
      loadingspinner: false,
      selectedRowId: null,
    };
  }

  componentDidMount() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      this.mobileBrowser = true;
    } else {
      this.mobileBrowser = false;
    }
    var filteredOrderColumn;
    const columns = [
      {
        id: "Product",
        label: "Product",
        align: "left",
        minWidth: 155,
      },
      {
        id: "Customer Product Code",
        label: "Customer Product Code",
        align: "left",
        minWidth: 160,
      },
      {
        id: "Product Description",
        label: "Product Description",
        align: "left",
        minWidth: 225,
      },
      {
        id: "Product Class",
        label: "Product Class",
        align: "left",
        minWidth: 94,
      },
      {
        id: "Agreement Quantity",
        label: "Agreement Quantity",
        align: "right",
        minWidth: 60,
      },
      {
        id: "Available Quantity",
        label: "Available Quantity",
        align: "right",
        minWidth: 85,
      },
      {
        id: "UOM",
        label: "UOM",
        align: "left",
        minWidth: 30,
      },

      {
        id: "Price",
        label: `Selling Price(${this.props.UserInfo.CURRENCY_CODE})`,
        align: "right",
        minWidth: 60,
      },
      {
        id: "Quantity",
        label: "Quantity",
        align: "left",
        minWidth: 100,
      },
      {
        id: "Order UOM",
        label: "Order UOM",
        align: "left",
        minWidth: 96,
      },
      {
        id: "price",
        label: `Total Price(${this.props.UserInfo.CURRENCY_CODE})`,
        align: "right",
        minWidth: 80,
      },
      {
        id: "Est Ship Date",
        label: "Est Ship Date",
        align: "left",
        minWidth: 146,
      },
      {
        id: "Location",
        label: "Location",
        align: "left",
        minWidth: 160,
      },
      {
        id: "Remarks",
        label: "Remarks",
        align: "left",
        minWidth: 50,
      },

      {
        id: "Action",
        label: "Action",
        align: "left",
        minWidth: 10,
      },
    ];
    if (this.props.UserInfo.AGREEMENT === "N" ? true : false) {
      filteredOrderColumn = columns.filter(
        (item) => item.label !== "Agreement Quantity"
      );
    } else {
      filteredOrderColumn = columns;
    }
    this.setState({
      AddtoCartQuantity: this.props.AddtoCartQuantity,
      filteredOrderColumn: filteredOrderColumn,
    });

    if (this.props.UserInfo.CONTACT_EMAIL) {
    }
    if (!this.props.UserInfo.CONTACT_EMAIL) {
      api
        .get(apiConfig.UserInfo, { handlerEnabled: true })
        .then((res) => {
          this.props.GET_USERINFO(res.data[0]);
        })
        .catch((error) => {});
    }
  }
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  onClickOrderSubmit = () => {
    this.setState({ spinner: true });
    this.setState({ loadingspinner: true });

    var orderlines = [];
    this.props.AddtoCartQuantity.map((data) => {
      orderlines.push({
        product_id: data.INVENTORY_ITEM_ID.toString(),
        product_code: data.PRODUCT_CODE.toString(),
        customer_product_code: data.CUSTOMER_PRODUCT_CODE
          ? data.CUSTOMER_PRODUCT_CODE.toString()
          : "",
        product_description: data.PRODUCT_DESCRIPTION.toString(),
        product_class: data.PRODUCT_CLASS.toString(),
        product_uom: data.UOMtype.label.toString(),
        inv_location: data.LOCATION.toString(),
        order_quantity: data.quantity.toString(),
        selling_price: data.SELLING_PRICE.includes(",")
          ? data.SELLING_PRICE.replace(/,/g, "").toString()
          : data.SELLING_PRICE.toString(),
        price: data.price.includes(",")
          ? data.price.replace(/,/g, "").toString()
          : data.price.toString(),
        estimated_ship_date: data.EstimatedDate,
        remarks: data.remark.toString(),
      });
    });
    var saleDate = Moment(new Date()).format("MM/DD/YYYY");
    const {
      CURRENCY_CODE,
      PAYMENT_TERM_ID,
      ORG_ID,
      SALESREP_ID,
      CUSTOMER_ID,
      ORDER_TYPE_ID,
      TAX_CODE,
      CONTACT_ID,
      PRICE_LIST_ID,
      SITE_USE_ID,
    } = this.props.UserInfo;
    const {
      ShipTo,
      customerpoNum,
      HeaderRemark,
      EstimatedDt,
      Country,
      Ship_Companyname,
      Ship_AddressLine1,
      Postal_Code,
      Ship_AddressLine2,
    } = this.props.OrderheaderDet;
    if (this.totalpriceamt > 0) {
      api
        .post(
          apiConfig.Order,
          {
            org_id: ORG_ID,
            sales_order_date: saleDate,
            customer_id: CUSTOMER_ID,
            invoice_to_org_id: SITE_USE_ID,
            ship_to_org_id: ShipTo.value,
            ship_from_org_id: ORG_ID,
            price_list_id: PRICE_LIST_ID,
            order_source: "0",
            sale_order_type: ORDER_TYPE_ID.toString(),
            sale_person_id: SALESREP_ID,
            customer_po_num: customerpoNum,
            payment_term_id: PAYMENT_TERM_ID,
            tax_code: TAX_CODE,
            estimated_ship_date: Moment(EstimatedDt).format("MM/DD/YYYY"),
            remarks: HeaderRemark,
            order_total: parseFloat(this.totalpriceamt),
            currency_code: CURRENCY_CODE,
            contact_id: CONTACT_ID,
            alt_address: ShipTo.ALT_ADDRESS,
            alt_ship_to_company_name: Ship_Companyname,
            alt_ship_to_address1: Ship_AddressLine1,
            alt_ship_to_address2: Ship_AddressLine2,
            alt_ship_to_country: Country.value,
            alt_ship_to_postal_code: Postal_Code,
            order_lines: orderlines,
          },
          { handlerEnabled: true }
        )
        .then((res) => {
          this.setState({ loadingspinner: false });
          this.sucessMessage(res.data);
          this.setState({ spinner: false });
        })
        .catch((error) => {
          this.setState({ spinner: false });
          this.setState({ loadingspinner: false });
          this.errorMessage(
            "Some Thing Went Wrong:" + error.response.data.MESSAGE
          );
        });
    } else {
      this.setState({ spinner: false });
      this.setState({ loadingspinner: false });
      this.errorMessage("Total Order Amount should be greater than zero");
    }
  };
  reloadData = () => {
    this.props.SELECTED_ORDER([]);
    this.props.ADDTO_CART_QUANTITY([]);
    this.props.REDUX_FORM_CLEAR("OrderFulfillment");
    this.props.history.push("/admin/MyOrders");
  };
  sucessMessage = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnCssClass="cnfrmbtn"
          onConfirm={() => this.reloadData(data)}
        >
          <span>{`Order `}</span>
          <span
            style={{ fontWeight: "bold", color: "#5CB85C" }}
          >{`#${data.ORDER_NUMBER}`}</span>
          <span>{` has been placed successfully.`}</span>
          <br />
          <br />
          <span>
            Your order is currently being processed. You will receive an order
            confirmation email shortly.
          </span>
        </SweetAlert>
      ),
    });
  };
  hiddenCell = (label) =>
    label === "Est Ship Date" ||
    label === "Location" ||
    label === "Product Class";
  restrictMinus = (e) => {
    var inputKeyCode = e.keyCode ? e.keyCode : e.which;
    if (inputKeyCode != null) {
      if (inputKeyCode == 45) e.preventDefault();
    }
  };
  errorMessage = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          error
          style={{ display: "block", marginTop: "-100px" }}
          confirmBtnCssClass="cnfrmbtn"
          onConfirm={() => this.setState({ alert: null })}
        >
          <span style={{ fontWeight: "bold", color: "#D9534F" }}>{data}</span>
        </SweetAlert>
      ),
    });
  };
  componentDidUpdate(prevProps) {
    var filteredOrderColumn;
    if (
      this.props.AddtoCartQuantity &&
      this.props.AddtoCartQuantity !== prevProps.AddtoCartQuantity
    ) {
      const columns = [
        {
          id: "Product",
          label: "Product",
          align: "left",
          minWidth: 155,
        },
        {
          id: "Customer Product Code",
          label: "Customer Product Code",
          align: "left",
          minWidth: 160,
        },
        {
          id: "Product Description",
          label: "Product Description",
          align: "left",
          minWidth: 225,
        },
        {
          id: "Product Class",
          label: "Product Class",
          align: "left",
          minWidth: 94,
        },
        {
          id: "Agreement Quantity",
          label: "Agreement Quantity",
          align: "right",
          minWidth: 60,
        },
        {
          id: "Available Quantity",
          label: "Available Quantity",
          align: "right",
          minWidth: 85,
        },
        {
          id: "UOM",
          label: "UOM",
          align: "left",
          minWidth: 30,
        },

        {
          id: "Price",
          label: `Selling Price(${this.props.UserInfo.CURRENCY_CODE})`,
          align: "right",
          minWidth: 60,
        },
        {
          id: "Quantity",
          label: "Quantity",
          align: "left",
          minWidth: 100,
        },
        {
          id: "Order UOM",
          label: "Order UOM",
          align: "left",
          minWidth: 96,
        },
        {
          id: "price",
          label: `Total Price(${this.props.UserInfo.CURRENCY_CODE})`,
          align: "right",
          minWidth: 80,
        },
        {
          id: "Est Ship Date",
          label: "Est Ship Date",
          align: "left",
          minWidth: 146,
        },
        {
          id: "Location",
          label: "Location",
          align: "left",
          minWidth: 160,
        },
        {
          id: "Remarks",
          label: "Remarks",
          align: "left",
          minWidth: 50,
        },

        {
          id: "Action",
          label: "Action",
          align: "left",
          minWidth: 10,
        },
      ];
      if (this.props.UserInfo.AGREEMENT === "N" ? true : false) {
        filteredOrderColumn = columns.filter(
          (item) => item.label !== "Agreement Quantity"
        );
      } else {
        filteredOrderColumn = columns;
      }
      this.setState({
        AddtoCartQuantity: this.props.AddtoCartQuantity,
        filteredOrderColumn: filteredOrderColumn,
      });
    }
  }

  handleChange = (value) => {
    this.setState({ selectedShip: value });
  };

  onChangequanity = (value, data) => {
    var changedprice = "0.00";
    if (value) {
      var removedComma = data.SELLING_PRICE.includes(",")
        ? data.SELLING_PRICE.replace(/,/g, "")
        : data.SELLING_PRICE;
      changedprice = parseFloat(removedComma * value)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
    const updatedItem = {
      INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
      PRODUCT_CODE: data.PRODUCT_CODE,
      PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
      PRODUCT_CLASS: data.PRODUCT_CLASS,
      CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
      PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
      OAGREEMENT_QTY: data.OAGREEMENT_QTY,
      OAVAILABLE_QTY: data.OAVAILABLE_QTY,
      OsellingPrice: data.OsellingPrice,
      UOMtype: data.UOMtype,
      LOCATION: data.LOCATION,
      SELLING_PRICE: data.SELLING_PRICE,
      AVAILABLE_QTY: data.AVAILABLE_QTY,
      AGREEMENT_QTY: data.AGREEMENT_QTY,
      quantity: value,
      price: changedprice,
      remark: data.remark,
      EstimatedDate: data.EstimatedDate,
    };
    const newArr = this.props.AddtoCartQuantity.map((item) =>
      item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
    );
    this.props.ADDTO_CART_QUANTITY(newArr);
  };
  totalprice = () => {
    let totalamount = 0.0;
    this.props.AddtoCartQuantity.map((data) => {
      var price = data.price.includes(",")
        ? data.price.replace(/,/g, "")
        : data.price;
      totalamount =
        parseFloat(totalamount) + parseFloat(price == "" ? 0 : price);
    });
    this.totalpriceamt = parseFloat(totalamount.toFixed(2)).toFixed(2);
    return parseFloat(totalamount.toFixed(2)).toFixed(2);
  };
  onClickAddtoCart = () => {
    this.props.ADDTO_CART_QUANTITY(this.state.selectedorder);
  };
  quantityValue = (value, data) => {
    if (value) {
      if (value == 0) {
        const filterselecorder = this.props.AddtoCartQuantity.filter(
          (item) => item.PRODUCT_CODE !== data.PRODUCT_CODE
        );
        this.props.ADDTO_CART_QUANTITY(filterselecorder);
      } else if (parseInt(value) > parseInt(data.AVAILABLE_QTY)) {
        this.promap[data.PRODUCT_CODE] = true;
        this.setState({ error: true });
        this.setState({
          snackbarMsg: (
            <Snackbar open autoHideDuration={6000}>
              <Alert
                onClose={() => this.setState({ snackbarMsg: "" })}
                variant="filled"
                severity="error"
              >
                should be less than available quantity
              </Alert>
            </Snackbar>
          ),
        });
      } else {
        this.promap[data.PRODUCT_CODE] = false;
        this.setState({ error: false });
        this.setState({ snackbarMsg: "" });
        var removedComma = data.SELLING_PRICE.includes(",")
          ? data.SELLING_PRICE.replace(/,/g, "")
          : data.SELLING_PRICE;
        var sellingPrice = parseFloat(removedComma * value)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        const updatedItem = {
          INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
          PRODUCT_CODE: data.PRODUCT_CODE,
          PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
          PRODUCT_CLASS: data.PRODUCT_CLASS,
          CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
          PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
          OAGREEMENT_QTY: data.OAGREEMENT_QTY,
          OAVAILABLE_QTY: data.OAVAILABLE_QTY,
          OsellingPrice: data.OsellingPrice,
          UOMtype: data.UOMtype,
          LOCATION: data.LOCATION,
          SELLING_PRICE: data.SELLING_PRICE,
          AVAILABLE_QTY: data.AVAILABLE_QTY,
          AGREEMENT_QTY: data.AGREEMENT_QTY,
          quantity: value,
          price: sellingPrice,
          remark: data.remark,
          EstimatedDate: data.EstimatedDate,
        };

        const newArr = this.props.AddtoCartQuantity.map((item) =>
          item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
        );
        this.props.ADDTO_CART_QUANTITY(newArr);
      }
    } else {
      const filterselecorder = this.props.AddtoCartQuantity.filter(
        (item) => item.PRODUCT_CODE !== data.PRODUCT_CODE
      );
      this.props.ADDTO_CART_QUANTITY(filterselecorder);
    }
  };
  BacktoOrder = () => {
    this.props.SELECTED_ORDER([]);
    history.push({ pathname: "/admin/orderFulfillment", data: "backToOrder" });
  };
  onClickDelete = (data) => {
    const filterselecorder = this.props.AddtoCartQuantity.filter(
      (item) => item.PRODUCT_CODE !== data.PRODUCT_CODE
    );
    this.props.ADDTO_CART_QUANTITY(filterselecorder);
    api
      .post(
        apiConfig.loggingRemovedItems,
        {
          inventory_item_id: data.INVENTORY_ITEM_ID,
          quantity: data.quantity,
        },
        { handlerEnabled: true }
      )
      .then((res) => {})
      .catch((error) => {});
  };
  renderTableData = () => {
    return this.state.AddtoCartQuantity.map((cart, index) => {
      const {
        PRODUCT_CODE,
        PRODUCT_DESCRIPTION,
        UOMtype,
        EstimatedDate,
        price,
        quantity,
      } = cart; //destructuring
      return (
        <tr key={index}>
          <td
            align="left"
            style={{
              border: "1px solid black",
              padding: "0.5em;",
              borderCollapse: "collapse",
            }}
          >
            {PRODUCT_CODE}
          </td>
          <td
            align="left"
            style={{
              border: "1px solid black",
              padding: "0.5em;",
              borderCollapse: "collapse",
            }}
          >
            {PRODUCT_DESCRIPTION}
          </td>
          <td
            align="right"
            style={{
              border: "1px solid black",
              padding: "0.5em;",
              borderCollapse: "collapse",
            }}
          >
            {quantity}
          </td>
          <td
            align="right"
            style={{
              border: "1px solid black",
              padding: "0.5em;",
              borderCollapse: "collapse",
            }}
          >
            {price}
          </td>
          <td
            align="left"
            style={{
              border: "1px solid black",
              padding: "0.5em;",
              borderCollapse: "collapse",
            }}
          >
            {EstimatedDate}
          </td>
        </tr>
      );
    });
  };
  onClickPrint = () => {
    var divContents = document.getElementById("printpage").innerHTML;
    var a = window.open("");
    a.document.write("<html>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
      a.close();
    }, 250);
  };
  onClickDowload = () => {
    var hiddenDiv = document.getElementById("printpage");
    hiddenDiv.style.display = "block";
    html2canvas(document.querySelector("#printpage")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      hiddenDiv.style.display = "none";
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("CartItems.pdf");
    });
  };
  render() {
    var ALT_ADDRESS = this.props.OrderheaderDet
      ? this.props.OrderheaderDet.ShipTo
        ? this.props.OrderheaderDet.ShipTo.ALT_ADDRESS === "Y"
          ? true
          : false
        : false
      : false;
    var agreement = this.props.UserInfo
      ? this.props.UserInfo.AGREEMENT === "N"
        ? true
        : false
      : "";
    const { classes } = this.props;
    return (
      <LoadingOverlay
        active={this.state.loadingspinner}
        spinner
        text="Please wait while we are processing your order."
      >
        <Fragment>
          {this.props.OrderheaderDet ? (
            <div id="printpage" class="printpage">
              <div style={{ textAlign: "center" }}>
                <img src={logo} alt="printlogo" style={{height:"37px",marginBottom:"8px"}} />
              </div>

              <div>
                <table style={{ border: "none" }}>
                  <tbody>
                    <tr style={{ border: "none" }}>
                      <td
                        width="50%"
                        style={{ verticalAlign: "top", border: "none" }}
                      >
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Customer Name :{" "}
                          </span>
                          <span>
                            {this.props.OrderheaderDet.CustomerName
                              ? this.props.OrderheaderDet.CustomerName
                              : ""}
                          </span>
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Payment Term :{" "}
                          </span>
                          <span>{this.props.OrderheaderDet.Payment_Terms}</span>
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>Ship To : </span>
                          <span>
                            {this.props.OrderheaderDet
                              ? this.props.OrderheaderDet.ShipTo
                                ? this.props.OrderheaderDet.ShipTo.label
                                : ""
                              : ""}
                          </span>
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>Bill To : </span>
                          <span>{this.props.OrderheaderDet.Bill_To}</span>
                        </p>
                        {ALT_ADDRESS ? (
                          <Fragment>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                Ship To Company Name :{" "}
                              </span>
                              <span>
                                {this.props.OrderheaderDet.Ship_Companyname}
                              </span>
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                Ship To Address1 :{" "}
                              </span>
                              <span>
                                {this.props.OrderheaderDet.Ship_AddressLine1}
                              </span>
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                Ship To Address2 :{" "}
                              </span>
                              <span>
                                {this.props.OrderheaderDet.Ship_AddressLine2}
                              </span>
                            </p>
                          </Fragment>
                        ) : null}
                      </td>
                      <td width="50%" style={{ border: "none" }}>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Customer PO No :{" "}
                          </span>
                          <span>{this.props.OrderheaderDet.customerpoNum}</span>
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Tax Code :{" "}
                          </span>
                          <span>
                            {this.props.OrderheaderDet.TAX_CODE_DESCRIPTION}
                          </span>
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Currency :{" "}
                          </span>
                          <span>{this.props.OrderheaderDet.Currency}</span>
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Sales Person :{" "}
                          </span>
                          <span>{this.props.OrderheaderDet.Sales_Person}</span>
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>Remark : </span>
                          <span>{this.props.OrderheaderDet.HeaderRemark}</span>
                        </p>
                        {ALT_ADDRESS ? (
                          <Fragment>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                Ship To Country:{" "}
                              </span>
                              <span>
                                {this.props.OrderheaderDet.Country
                                  ? this.props.OrderheaderDet.Country.label
                                  : ""}
                              </span>
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                Postal Code:{" "}
                              </span>
                              <span>
                                {this.props.OrderheaderDet.Postal_Code}
                              </span>
                            </p>
                          </Fragment>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <table
                id="ordercart"
                style={{
                  border: "1px solid black",
                  padding: "0.5em;",
                  borderCollapse: "collapse",
                }}
              >
                <tbody>
                  <tr>
                    <th
                      align="left"
                      className="printdata"
                      style={{
                        border: "1px solid black",
                        padding: "0.5em;",
                        borderCollapse: "collapse",
                      }}
                    >
                      Product Code
                    </th>
                    <th
                      align="left"
                      className="printdata"
                      style={{
                        border: "1px solid black",
                        padding: "0.5em;",
                        borderCollapse: "collapse",
                      }}
                    >
                      Product Description
                    </th>
                    <th
                      align="right"
                      className="printdata"
                      style={{
                        border: "1px solid black",
                        padding: "0.5em;",
                        borderCollapse: "collapse",
                      }}
                    >
                      Quantity
                    </th>
                    <th
                      align="right"
                      className="printdata"
                      style={{
                        border: "1px solid black",
                        padding: "0.5em;",
                        borderCollapse: "collapse",
                      }}
                    >
                      Price ({this.props.UserInfo.CURRENCY_CODE})
                    </th>
                    <th
                      align="left"
                      className="printdata"
                      style={{
                        border: "1px solid black",
                        padding: "0.5em;",
                        borderCollapse: "collapse",
                      }}
                    >
                      Estimated Date
                    </th>
                  </tr>
                  {this.renderTableData()}
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        padding: "0.5em;",
                        borderCollapse: "collapse",
                      }}
                    ></td>
                    <td
                      colspan="2"
                      align="right"
                      style={{
                        border: "1px solid black",
                        padding: "0.5em;",
                        borderCollapse: "collapse",
                      }}
                    >
                      {" "}
                      Total Price (*excl of GST)
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        padding: "0.5em;",
                        borderCollapse: "collapse",
                      }}
                      align="right"
                    >{`${this.totalprice().replace(
                      /\d(?=(\d{3})+\.)/g,
                      "$&,"
                    )}`}</td>
                    <td
                      style={{
                        border: "1px solid black",
                        padding: "0.5em;",
                        borderCollapse: "collapse",
                      }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
          <GridContainer>
            {this.state.alert}
            {this.state.snackbarMsg}
            <GridItem xs={12}>
              <Card>
                <CardBody>
                  <Form className="mtop-20">
                    <GridContainer className="ordertopbar">
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter  mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Customer Name
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                        <Field
                          name="CustomerName"
                          type="text"
                          className={`${classes.materialinput} "ordertextcolor"`}
                          component={renderOrderTextField}
                          value={this.props.UserInfo.CUSTOMER_NAME}
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Payment Terms
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                        <Field
                          name="Payment_Terms"
                          type="text"
                          className={`${classes.materialinput} "ordertextcolor"`}
                          component={renderOrderTextField}
                          value={this.props.UserInfo.CUSTOMER_NAME}
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Ship To
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                        <Field
                          name="ShipTo"
                          type="text"
                          component={renderSelectField}
                          options={this.state.shipToLov}
                          disabled={true}
                        />
                      </GridItem>
                      {ALT_ADDRESS ? (
                        <Fragment>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            className="alignLabelcenter mbtm-10 bold"
                          >
                            <InputLabel className="ordertextcolor">
                              Ship To Company Name
                            </InputLabel>
                          </GridItem>
                          <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                            <Field
                              name="Ship_Companyname"
                              className={`${classes.materialinput} "ordertextcolor"`}
                              component={renderOrderTextField}
                              value={this.props.UserInfo.CUSTOMER_NAME}
                              disabled={true}
                            />
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            className="alignLabelcenter mbtm-10 bold"
                          >
                            <InputLabel className="ordertextcolor">
                              Ship To Address..1
                            </InputLabel>
                          </GridItem>
                          <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                            <Field
                              name="Ship_AddressLine1"
                              className={`${classes.materialinput} "ordertextcolor"`}
                              component={renderOrderTextField}
                              disabled={true}
                            />
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            className="alignLabelcenter mbtm-10 bold"
                          >
                            <InputLabel className="ordertextcolor">
                              Ship To Address..2
                            </InputLabel>
                          </GridItem>
                          <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                            <Field
                              name="Ship_AddressLine2"
                              type="text"
                              className={`${classes.materialinput} "ordertextcolor"`}
                              component={renderOrderTextField}
                              disabled={true}
                            />
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            className="alignLabelcenter mbtm-10 bold"
                          >
                            <InputLabel className="ordertextcolor">
                              Ship To Country
                            </InputLabel>
                          </GridItem>
                          <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                            <Field
                              name="Country"
                              placeholder="Select Country"
                              type="text"
                              component={renderSelectField}
                              options={this.state.Countryoptions}
                              disabled={true}
                            />
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            className="alignLabelcenter mbtm-10 bold"
                          >
                            <InputLabel className="ordertextcolor">
                              Postal Code
                            </InputLabel>
                          </GridItem>
                          <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                            <Field
                              name="Postal_Code"
                              className={`${classes.materialinput} "ordertextcolor"`}
                              component={renderOrderTextField}
                              disabled={true}
                            />
                          </GridItem>
                        </Fragment>
                      ) : null}
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Tax Description
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                        <Field
                          name="TAX_CODE_DESCRIPTION"
                          className={`${classes.materialinput} "ordertextcolor"`}
                          component={renderOrderTextField}
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Bill To
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                        <Field
                          name="Bill_To"
                          className={`${classes.materialinput} "ordertextcolor"`}
                          component={renderOrderTextField}
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Currency
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                        <Field
                          name="Currency"
                          className={`${classes.materialinput} "ordertextcolor"`}
                          component={renderOrderTextField}
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Sales Person
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                        <Field
                          name="Sales_Person"
                          className={`${classes.materialinput} "ordertextcolor"`}
                          component={renderOrderTextField}
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Estimated Ship Date
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                        <Field
                          name="EstimatedDt"
                          className={classes.datePick}
                          component={renderDatePicker}
                          onChange={this.handleDateChange}
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Customer PO Number
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                        <Field
                          name="customerpoNum"
                          className={`${classes.materialinput} "ordertextcolor"`}
                          component={renderOrderTextField}
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Notes/Remark
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                        <Field
                          name="HeaderRemark"
                          className={`${classes.materialinput} "ordertextcolor"`}
                          component={renderOrderTextField}
                          disabled={true}
                        ></Field>
                      </GridItem>
                    </GridContainer>
                  </Form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer className="ordertable">
            <GridItem xs={12}>
              <Card>
                <CardBody style={{ overflowX: "scroll" }}>
                  <form>
                    {this.props.AddtoCartQuantity.length == 0 ? (
                      <div className="nodatafound">No Items in the Cart</div>
                    ) : (
                      <Table>
                        <TableHead>
                          <TableRow>
                            {this.state.filteredOrderColumn.map((column) => (
                              <TableCell
                                className={
                                  this.hiddenCell(column.label)
                                    ? "tblColthHide"
                                    : "bold tablePadding"
                                }
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.AddtoCartQuantity.slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          ).map((data, i) => {
                            return (
                              <TableRow
                                hover
                                onClick={() => {
                                  this.setState({ selectedRowId: i });
                                }}
                                selected={this.state.selectedRowId === i}
                                key={i}
                                classes={{
                                  hover: classes.hover,
                                  selected: classes.selected,
                                }}
                                className={classes.tableRow}
                              >
                                <TableCell
                                  align="left"
                                  className="tablePadding tablecell"
                                >
                                  {data.PRODUCT_CODE}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  {data.CUSTOMER_PRODUCT_CODE}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  {data.PRODUCT_DESCRIPTION}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding tblColHide"
                                >
                                  {data.PRODUCT_CLASS}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={`tablePadding ${
                                    agreement ? "AgreementQuantity" : ""
                                  }`}
                                >
                                  {data.AGREEMENT_QTY}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding"
                                >
                                  {data.AVAILABLE_QTY}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  {data.PRIMARY_UOM_CODE}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding"
                                >
                                  {data.SELLING_PRICE}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  <DynamicTextField
                                    type="number"
                                    onChange={(e) =>
                                      this.onChangequanity(e.target.value, data)
                                    }
                                    label="Quantity"
                                    value={data.quantity}
                                    error={
                                      this.state.error &&
                                      this.promap[data.PRODUCT_CODE]
                                    }
                                    onBlur={() =>
                                      this.quantityValue(data.quantity, data)
                                    }
                                    variant="outlined"
                                    onKeypress={(event) =>
                                      this.restrictMinus(event)
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  {" "}
                                  {data.UOMtype.label}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding"
                                >
                                  {data.price}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding tblColHide"
                                >
                                  {data.EstimatedDate}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding tblColHide"
                                >
                                  {data.LOCATION}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ maxWidth: "152px" }}
                                  className="tablePadding"
                                >
                                  {data.remark}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tablePadding"
                                >
                                  <DeleteForeverIcon
                                    className="deleteicon"
                                    onClick={() => this.onClickDelete(data)}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          <TableRow>
                            {agreement ? (
                              <TableCell colSpan={7}></TableCell>
                            ) : (
                              <TableCell colSpan={8}></TableCell>
                            )}
                            <TableCell
                              colSpan={2}
                              className="bold"
                              align="right"
                            >
                              <span>Total Price</span>{" "}
                              <span className="totalprice">{`(*excl of GST)`}</span>
                            </TableCell>
                            <TableCell
                              align="right"
                              className="tablePadding"
                            >{`${this.totalprice().replace(
                              /\d(?=(\d{3})+\.)/g,
                              "$&,"
                            )}`}</TableCell>
                            <TableCell colSpan={3}></TableCell>
                          </TableRow>
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25]}
                              count={this.state.AddtoCartQuantity.length}
                              rowsPerPage={this.state.rowsPerPage}
                              page={this.state.page}
                              SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                              }}
                              onChangePage={this.handleChangePage}
                              onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    )}
                  </form>
                  <div>
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      {this.props.AddtoCartQuantity.length == 0 ? null : this
                          .mobileBrowser ? (
                        <Button
                          className="Orderbutton mright-10"
                          disabled={
                            this.props.AddtoCartQuantity.length == 0
                              ? true
                              : false
                          }
                          onClick={() => {
                            this.onClickDowload();
                          }}
                        >
                          Download quote
                        </Button>
                      ) : (
                        <Button
                          className="Orderbutton mright-10"
                          disabled={
                            this.props.AddtoCartQuantity.length == 0
                              ? true
                              : false
                          }
                          onClick={this.onClickPrint}
                        >
                          Print quote
                        </Button>
                      )}
                      {this.props.AddtoCartQuantity.length == 0 ? null : (
                        <Button
                          className="Orderbutton"
                          disabled={
                            this.props.AddtoCartQuantity.length == 0
                              ? true
                              : false
                          }
                          onClick={this.onClickOrderSubmit}
                        >
                          submit order
                        </Button>
                      )}
                      <Button
                        className="Orderbutton mleft-10"
                        onClick={this.BacktoOrder}
                      >
                        Back to order
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Fragment>
      </LoadingOverlay>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    OrderheaderDet: getFormValues("OrderFulfillment")(state),
    shipToLov: state.UserInfo.shipToLov,
    UserInfo: state.UserInfo.userInfo,
    AddtoCartQuantity: state.OrderFulfillmentInfo.AddtoCartQuantity,
  };
};
export default compose(
  connect(
    mapStateToProps,
    {
      OrderFulfillment_Data,
      ADDTO_CART_QUANTITY,
      GET_USERINFO,
      SELECTED_ORDER,
      REDUX_FORM_CLEAR,
    }
  ),
  reduxForm({
    form: "OrderFulfillment",
    destroyOnUnmount: false,
  }),
  withStyles(styles, { withTheme: true })
)(OrderCart);
