import React, { useState, useEffect, useRef } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.js";
// import Button from '@material-ui/core/Button';


import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DevicesIcon from '@material-ui/icons/Devices';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SecurityIcon from '@material-ui/icons/Security';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ReceiptIcon from '@material-ui/icons/Receipt';


import history from '../../../src/config/history';
import { connect } from "react-redux";


//redux

const useStyles = makeStyles(styles);

const AdminNavbar = (props) => {
  const classes = useStyles();
  const [direction, setDirection] = useState("ltr");
  const { color, rtlActive, brandText, brandLogo, Rtl } = props;

  const accelevel = sessionStorage.getItem("AccessLevel").includes("Level 3") ? false : sessionStorage.getItem("AccessLevel").includes("Level 0") ? false : true;
  const accountype = sessionStorage.getItem("ACCOUNT_TYPE") === "SALESPERSON" ? false : sessionStorage.getItem("ACCOUNT_TYPE") === "WAREHOUSE" ? false : true;
const useCompare = (val) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  let prevRtl = useCompare(Rtl);
  useEffect(() => {
    if (prevRtl) {
      Rtl ? setDirection("rtl") : setDirection("ltr");
    }
  }, [Rtl, prevRtl]);
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });

  const brandLogoVal = (name) => {
    if (name === "Purchase") {
      return <AssessmentIcon style={{ marginRight: "5px" }} />   
    }
    if(name === "Reports"){
      return <AssessmentIcon style={{marginRight:"5px"}}/>
    }
    if (name === "e-learning") {
      return <ShoppingCartIcon style={{ marginRight: "5px" }} />
    }
    if (name === "Inventory Management") {
      return <AssignmentIcon style={{ marginRight: "5px" }} />
    }
    if (name === "Account Info") {
      return <PersonIcon style={{ marginRight: "5px" }} />
    }
    if (name === "Security & Privacy") {
      return <SecurityIcon style={{ marginRight: "5px" }} />
    }
    if (name === "Devices") {
      return <DevicesIcon style={{ marginRight: "5px" }} />
    }
    if (name === "Dispute") {
      return <FeedbackIcon style={{ marginRight: "5px" }} />
    }
    
    if (name === "Account Management") {
      return <PeopleIcon style={{ marginRight: "5px" }} />
    }
    if (name === "Order Fulfilment") {
      return <ShoppingCartIcon style={{ marginRight: "5px" }} />
    }
    if (name === "My Orders") {
      return <ShopTwoIcon style={{ marginRight: "5px" }} />
    }
    if (name === "Orders") {
      return <ShopTwoIcon style={{ marginRight: "5px" }} />
    }
    if(name === "Cart"){
      return <ShoppingCartIcon style={{ marginRight: "5px" }} />
    }
    if(name === "Purchase Report"){
      return <AssessmentIcon style={{ marginRight: "5px" }} />
    }

    if(name === "Outstanding Payments(AR)"){
      return <ReceiptIcon style={{ marginRight: "5px" }} />
    }
    if(name === "Stock Utilization Report"){
      return <ReceiptIcon style={{ marginRight: "5px" }} />
    }
    if(name === "Overview"){
      return <ReceiptIcon style={{ marginRight: "5px" }} />
    }
    
  }

  const callOrderCart = () => {
history.push('/admin/OrderCart');
}
  return (
    <AppBar className={classes.appBar + appBarClasses} dir={direction}>
<Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
                <Button
                  justIcon
                  round
                  color="white"
                  onClick={props.sidebarMinimize}
                >
                  <MoreVert className={classes.sidebarMiniIcon} />
                </Button>
              )}
          </div>
        </Hidden>
        <div className="topbartext" >
          {/* Here we create navbar brand, based on route name */}
          <div href="#" className={classes.title} color="transparent" style={{ display: "flex", alignItems: "center" }}>
            {
              brandLogoVal(brandText)
            }
            {brandText}
          </div>
        </div>

        {accelevel || accountype ? <Button
          justIcon
          round
          disabled={props.AddtoCartQuantity.length === 0}
          onClick={callOrderCart}
          style={{ marginRight: "10px" }}
          
          color="white">
          <ShoppingCartIcon  style={{ color: "black",  }}></ShoppingCartIcon>
          {props.AddtoCartQuantity.length == 0 ? null :
            <div className="cartlength">{props.AddtoCartQuantity.length}</div>}
        </Button> : null
        }

        <Hidden smDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu style={{ color: "white" }} />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func
};

const mapStateToProps = state => {
  return {
    Rtl: state.util.Rtl,
    AddtoCartQuantity: state.OrderFulfillmentInfo.AddtoCartQuantity

  }
}

export default connect(mapStateToProps, null)(AdminNavbar)