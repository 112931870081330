import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import { apiConfig } from "../config/api";
import api from "../config/apiconfig";
import { reduxForm, Field, Form } from "redux-form";
import InputLabel from "@material-ui/core/InputLabel";
import {
  renderOrderTextField,
  renderSelectField,
} from "../utils/components/InputComponent";
import { compose } from "redux";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
const customStyles = {
  control: (base, state, meta, input) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
    // width: "94%",
    height: 52,
    minHeight: 52,
    // overwrittes hover style
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      boxShadow: "none",
    },
  }),
};
const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "16.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
  materialinputSearch: {
    "& .MuiOutlinedInput-input": {
      padding: "16.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
    },
  },
  labelAsterisk: {
    color: "red",
  },

  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
  datePick: {
    "&.MuiFormControl-marginNormal": {
      marginTop: "0px !important",
      marginBottom: "0px !important",
    },
    "&.MuiIconButton-root": {
      padding: "0px",
    },
  },
});
class DisputesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disputeStatusOption: [],
    };
  }
  componentDidMount() {
    let disputeStatusOption = [
      { value: "Entered", label: "Entered" },
      { value: "Processing", label: "Processing" },
      { value: "Completed", label: "Completed" },
    ];
    this.setState({ disputeStatusOption: disputeStatusOption });
  }
  submitDisputeForm = (formvalues) => {
    if (
      formvalues.Item != null &&
      formvalues.Description != null &&
      formvalues.Status != null &&
      formvalues.Amount != null &&
      formvalues.Quantity != null
    ) {
      if (this.props.flow === "edit") {
        api
          .post(
            apiConfig.UpdateDispute,
            {
              dispute_id: this.props.DISPUTE_ID,
              status: formvalues.Status.value,
            },
            { handlerEnabled: true }
          )
          .then((res) => {
            this.props.cancel();
          })
          .catch((error) => {});
      } else {
        api
          .post(
            apiConfig.AddDispute,
            {
              item: formvalues.Item,
              description: formvalues.Description,
              status: formvalues.Status.value,
              quantity: parseFloat(formvalues.Quantity),
              amount: parseFloat(formvalues.Amount),
            },
            { handlerEnabled: true }
          )
          .then((res) => {
            this.props.cancel();
          })
          .catch((error) => {});
      }
    } else {
      this.setState({
        snackbarMsg: (
          <Snackbar open autoHideDuration={6000}>
            <Alert
              onClose={() => this.setState({ snackbarMsg: "" })}
              variant="filled"
              severity="error"
            >
              Please fill the all the fields
            </Alert>
          </Snackbar>
        ),
      });
    }
  };
  cancel = () => {
    this.props.cancel();
  };
  render() {
    const { classes, handleSubmit } = this.props;
    return (
      <div>
        {this.state.snackbarMsg}
        <Form
          autoComplete="off"
          onSubmit={handleSubmit(this.submitDisputeForm)}
          className="mtop-20"
        >
          <GridContainer>
            <GridItem
              xs={6}
              sm={6}
              md={2}
              className="alignLabelcenter  mbtm-10 bold"
            >
              <InputLabel className="ordertextcolor  asterisk">Item</InputLabel>
            </GridItem>
            <GridItem xs={6} sm={6} md={4} className="mbtm-10">
              <Field
                name="Item"
                type="text"
                className={`${classes.materialinput} "ordertextcolor"`}
                component={renderOrderTextField}
                disabled={this.props.flow === "edit"}
              />
            </GridItem>
            <GridItem
              xs={6}
              sm={6}
              md={2}
              className="alignLabelcenter  mbtm-10 bold"
            >
              <InputLabel className="ordertextcolor  asterisk">
                Description
              </InputLabel>
            </GridItem>
            <GridItem xs={6} sm={6} md={4} className="mbtm-10">
              <Field
                name="Description"
                type="text"
                className={`${classes.materialinput} "ordertextcolor"`}
                component={renderOrderTextField}
                disabled={this.props.flow === "edit"}
              />
            </GridItem>
            <GridItem
              xs={6}
              sm={6}
              md={2}
              className="alignLabelcenter  mbtm-10 bold"
            >
              <InputLabel className="ordertextcolor  asterisk">
                Status
              </InputLabel>
            </GridItem>
            <GridItem xs={6} sm={6} md={4} className="mbtm-10">
              <Field
                name="Status"
                component={renderSelectField}
                options={this.state.disputeStatusOption}
              />
            </GridItem>
            <GridItem
              xs={6}
              sm={6}
              md={2}
              className="alignLabelcenter  mbtm-10 bold"
            >
              <InputLabel className="ordertextcolor  asterisk">
                Quantity
              </InputLabel>
            </GridItem>
            <GridItem xs={6} sm={6} md={4} className="mbtm-10">
              <Field
                name="Quantity"
                type="number"
                className={`${classes.materialinput} "ordertextcolor"`}
                component={renderOrderTextField}
                disabled={this.props.flow === "edit"}
              />
            </GridItem>
            <GridItem
              xs={6}
              sm={6}
              md={2}
              className="alignLabelcenter  mbtm-10 bold"
            >
              <InputLabel className="ordertextcolor  asterisk">
                Amount SGD
              </InputLabel>
            </GridItem>
            <GridItem xs={6} sm={6} md={4} className="mbtm-10">
              <Field
                name="Amount"
                type="text"
                className={`${classes.materialinput} "ordertextcolor"`}
                component={renderOrderTextField}
                disabled={this.props.flow === "edit"}
              />
            </GridItem>
          </GridContainer>
          <div style={{ textAlign: "center" }}>
            <Button type="submit" className="DisputeforBtn">
              Save
            </Button>
            <Button onClick={this.cancel} className="DisputeforBtn mleft-10">
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    DISPUTE_ID: state.ReportInfo.DisputeData
      ? state.ReportInfo.DisputeData.DISPUTE_ID
      : "",
    initialValues: {
      Item: state.ReportInfo.DisputeData
        ? state.ReportInfo.DisputeData.ITEM
        : "",
      Description: state.ReportInfo.DisputeData
        ? state.ReportInfo.DisputeData.DESCRIPTION
        : "",
      Status: state.ReportInfo.DisputeData
        ? {
            value: state.ReportInfo.DisputeData.STATUS,
            label: state.ReportInfo.DisputeData.STATUS,
          }
        : "",
      Amount: state.ReportInfo.DisputeData
        ? state.ReportInfo.DisputeData.AMOUNT
        : "",
      Quantity: state.ReportInfo.DisputeData
        ? state.ReportInfo.DisputeData.QUANTITY
        : "",
    },
  };
};
export default compose(
  connect(
    mapStateToProps,
    null
  ),
  reduxForm({
    form: "DisputesForm",
    enableReinitialize: true,
  }),
  withStyles(styles, { withTheme: true })
)(DisputesForm);
