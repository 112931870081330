import * as actionType from "../type";

const INITIAL_STATE = { Rtl: false, spinner: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.RTL_SUPPORT: {
      return {
        ...state,
        Rtl:
          action.payload === "arb" ? (state.Rtl = true) : (state.Rtl = false),
      };
    }
    case actionType.SPINNER: {
      return {
        ...state,
        spinner: action.payload,
      };
    }
    default:
      return state;
  }
};
