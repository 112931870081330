import React, { Component, Fragment } from "react";
import ExcelDownload from "./ExcelDownload";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from "@material-ui/icons/GetApp";
import { apiConfig } from "../../config/api";
import api from "../../config/apiconfig";
class HistoricalOrderDetails extends Component {
  responseData = [
    {
      columns: [
        {
          title: "Customer Number",
          width: { wch: 17 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Customer Name",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Ship To Location",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Order Number",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Customer PO Number",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Ordered Date",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Delivery Number",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Shipment Date",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Invoice Number",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Invoice Date",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Quarter",
          width: { wch: 8 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Currency",
          width: { wch: 8 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "SO Line No",
          width: { wch: 10 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Product Code",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Customer Product Code",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Product Description",
          width: { wch: 70 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Product Category",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Product Class",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Brand",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Qty",
          width: { wch: 5 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "UOM",
          width: { wch: 5 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Unit Selling Price",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Invoice Amount",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Sales Person Name",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
      ],
      data: [],
    },
  ];

  constructor(props) {
    super(props);
    this.state = { historicalOrderDetails: false, spinner: false };
  }

  showHistoricalOrderDetails() {
    this.setState({ spinner: true });
    this.responseData[0].data = [];
    api
      .post(
        apiConfig.historicalOrderDetailsReport,
        {
          from_date: this.props.fromDate,
          to_date: this.props.toDate,
          product_class: this.props.productClass,
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((element) => {
            var array = [];
            array.push({
              value:
                element.CUSTOMER_NUMBER !== null ? element.CUSTOMER_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.CUSTOMER_NAME !== null ? element.CUSTOMER_NAME : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.SHIP_TO_LOCATION !== null
                  ? element.SHIP_TO_LOCATION
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.ORDER_NUMBER !== null ? element.ORDER_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.CUST_PO_NUMBER !== null ? element.CUST_PO_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.ORDERED_DATE !== null ? element.ORDERED_DATE : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.DELIVERY_NUMBER !== null ? element.DELIVERY_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.SHIPMENT_DATE !== null ? element.SHIPMENT_DATE : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.INVOICE_NUMBER !== null ? element.INVOICE_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.INVOICE_DATE !== null ? element.INVOICE_DATE : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.QUARTER !== null ? element.QUARTER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.CURRENCY !== null ? element.CURRENCY : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.SO_LINE_NO !== null ? element.SO_LINE_NO : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.PRODUCT_CODE !== null ? element.PRODUCT_CODE : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.CUSTOMER_PRODUCT_CODE !== null
                  ? element.CUSTOMER_PRODUCT_CODE
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.PRODUCT_DESCRIPTION !== null
                  ? element.PRODUCT_DESCRIPTION
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.PRODUCT_CATEGORY !== null
                  ? element.PRODUCT_CATEGORY
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.PRODUCT_CLASS !== null ? element.PRODUCT_CLASS : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.BRAND !== null ? element.BRAND : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.QTY !== null ? element.QTY : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.UOM !== null ? element.UOM : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.UNIT_SELLING_PRICE !== null
                  ? element.UNIT_SELLING_PRICE
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.INVOICE_AMOUNT !== null ? element.INVOICE_AMOUNT : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.SALESPERSON_NAME !== null
                  ? element.SALESPERSON_NAME
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            this.responseData[0].data.push(array);
          });
          this.setState({ historicalOrderDetails: true, spinner: false });
          setTimeout(() => {
            this.setState({ historicalOrderDetails: false });
          }, 3000);
        } else {
          this.setState({ spinner: false });
          this.props.showerror();
        }
      });
  }

  render() {
    return (
      <Fragment>
        <div>
          {this.state.spinner ? null : (
            <GetAppIcon
              className="attachicon"
              onClick={() => this.showHistoricalOrderDetails()}
            />
          )}
          {this.state.spinner ? (
            <CircularProgress
              className="innerspinner"
              style={{ height: "24px", width: "24px" }}
            />
          ) : null}
          {this.state.historicalOrderDetails ? (
            <ExcelDownload
              data={this.responseData}
              excelName="Historical Orders - Detail"
            ></ExcelDownload>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default HistoricalOrderDetails;
