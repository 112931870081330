import React, { Component, Fragment } from 'react';
import HistoricalOrderDetails from "./HistoricalOrderDetails";
import HistoricalOrdersSummary from "./HistoricalOrdersSummary";
import InventoryOnHand from "./InventoryOnHand";
import OpenOrdersDetail from "./OpenOrdersDetail";
import OpenOrdersSummary from "./OpenOrdersSummary";
import CustomerInventoryMgmt from "./CustomerInventoryMgmt";
import PaymentHistory from "./PaymentHistory";
import OutstandingAR from "./OutstandingAR";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { apiConfig } from '../../config/api';
import api from '../../config/apiconfig';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { IndicatorsContainer } from '../../utils/components/InputComponent';
import ReactSelect from 'react-select';
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from 'redux';
import InputLabel from '@material-ui/core/InputLabel';
import Moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
const customStyles = {
    control: (base, state, meta, input) => ({
        ...base,
        // state.isFocused can display different borderColor if you need it
        borderColor: "rgba(0, 0, 0, 0.23)",
        boxShadow: "none",
        // width: "94%",
        height: 52,
        minHeight: 52,
        // overwrittes hover style
        '&:hover': {
            borderColor: "rgba(0, 0, 0, 0.23)",
            boxShadow: "none"
        }
    })
}

const styles = theme => ({
    materialinput: {
        '& .MuiOutlinedInput-input': {
            padding: "16.5px 14px",
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            //borderBottomColor: 'yellow',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                // borderColor: ooredooRedColor,
            },
            '&:hover fieldset': {
                borderColor: "rgba(0, 0, 0, 0.23)",
            },
            '&.Mui-focused fieldset': {
                borderColor: "rgba(0, 0, 0, 0.23)",
            },
        },
    },
    materialinputSearch: {
        '& .MuiOutlinedInput-input': {
            padding: "16.5px 14px",
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            //borderBottomColor: 'yellow',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                // borderColor: ooredooRedColor,
            },
            '&:hover fieldset': {
                borderColor: "rgba(0, 0, 0, 0.23)",
            },
            '&.Mui-focused fieldset': {
                borderColor: "rgba(0, 0, 0, 0.23)",
            },
            '&.MuiOutlinedInput-adornedEnd': {
                paddingRight: "0px"
            }
        },
    },
    labelAsterisk: {
        color: "red"
    },

    table: {
        width: 'max-content',
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
    },
    hover: {},
    selected: {},
    datePick: {
        "&.MuiFormControl-marginNormal": {
            marginTop: "0px !important",
            marginBottom: "0px !important"
        }
    }
});
class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reportsAccess: [],
            EndDate:Moment(new Date()).format('MM/DD/YYYY'),
            fromDate:Moment(new Date().setDate(new Date().getDate()-30)).format('MM/DD/YYYY'),
            selecteProductClass: "All",
            ProductClassLov: [],
            snackbarMsg:''
        };
        var reportsAccess = [];
        api.get(apiConfig.reportsAccess, { handlerEnabled: true }).then((res) => {
            res.data.forEach(element => {
                reportsAccess.push(element.REPORT_NAME);
            });
            this.setState({ reportsAccess });
        });
    }


    componentDidMount() {
        this.ProductClassLov();
    }

    handlefromDate(Date){
        var fromDate = Moment(Date).format('MM/DD/YYYY')
        this.setState({fromDate:fromDate})
    }
    handleEndDate(Date){
        var EndDate = Moment(Date).format('MM/DD/YYYY')
        this.setState({EndDate:EndDate})
    }
    handleReportChange = (e) => {
        var Reportfields = this.state.Reportfields
        Reportfields[e.target.name] = e.target.value
        this.setState({ Reportfields: Reportfields })
    }
    ProductClassLov = () => {
        api.get(apiConfig.reportsProductClassLOV, { handlerEnabled: true }).then(res => {
            let ProductClassLov = [{ value: "All", label: "All Product Class" }];
            res.data.map((data) => {
                ProductClassLov.push({ value: data.PRODUCT_CLASS, label: data.PRODUCT_CLASS });
            })
            this.setState({ selecteProductClass: { value: ProductClassLov[0].value, label: ProductClassLov[0].label }, ProductClassLov: ProductClassLov })
        }
        ).catch(error => {

        });
    }

    showErrorMessage = () => {
        this.setState({
            snackbarMsg: <Snackbar
              open
              autoHideDuration={1000}>
              <Alert onClose={() => this.setState({ snackbarMsg: '' })} variant="filled" severity="error">
There is no data matching your search criteria Please amend your sear  options and try again</Alert>
            </Snackbar>
          });
    }

    handleProductClasslov = (value) => {
        this.setState({ selecteProductClass: value })
    }

    render() {

        const { classes } = this.props;

        return (
            <Fragment>
                <GridContainer >
                    <GridItem xs={12}>
                        <Card >
                            <CardBody >
                                <GridContainer className="ordertopbar">
                                    <GridItem xs={6} sm={6} md={2} className="alignLabelcenter  mbtm-10 bold">
                                        <InputLabel className="ordertextcolor" >Start Date</InputLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} className="mbtm-10">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                inputProps={{
                                                    style: {
                                                        padding: "13.5px 14px"
                                                    },

                                                }}
                                                autoOk={true}
                                                fullWidth
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                name="Start Date"
                                                value={this.state.fromDate}
                                                onChange={(val) => this.handlefromDate(val)}
                                                maxDate={new Date()}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className={`${classes.datePick} ${classes.materialinput}`}
                                            />
                                        </MuiPickersUtilsProvider>

                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={2} className="alignLabelcenter  mbtm-10 bold">
                                        <InputLabel className="ordertextcolor" >End Date</InputLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} className="mbtm-10">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                inputProps={{
                                                    style: {
                                                        padding: "13.5px 14px"
                                                    },

                                                }}
                                                autoOk={true}
                                                fullWidth
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                name="End Date"
                                                value={this.state.EndDate}
                                                onChange={(val) => this.handleEndDate(val)}
                                                maxDate={new Date()}
                                                minDate={this.state.fromDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className={`${classes.datePick} ${classes.materialinput}`}
                                            />
                                        </MuiPickersUtilsProvider>

                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={2} className="alignLabelcenter  mbtm-10 bold">
                                        <InputLabel className="ordertextcolor" >Product Class</InputLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} style={{ marginTop: "auto" }} className="mbtm-10">
                                        <ReactSelect
                                            value={this.state.selecteProductClass}
                                            onChange={this.handleProductClasslov}
                                            components={{ IndicatorsContainer }}
                                            fullWidth
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPosition={this.state.isFixed ? 'fixed' : 'absolute'}
                                            styles={customStyles}
                                            style={{ width: "100%" }}
                                            options={this.state.ProductClassLov}
                                            menuPlacement={this.state.portalPlacement}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer style={{marginTop:"-35px"}}>
                    <GridItem xs={12}>
                    <Card>                           
                     <CardBody >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className="bold tablePadding" Key="reportname">Report Name</TableCell>
                                    <TableCell align="left" className="bold tablePadding" Key="reporttype">Report Type</TableCell>
                                    <TableCell align="left" className="bold tablePadding" Key="reportformat">Format</TableCell>
                                    <TableCell align="center" className="bold tablePadding" Key="download">Download</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { this.state.reportsAccess.includes("Open  Orders - Summary") ? 
                                <TableRow key="openorderssummary">
                                    <TableCell align="left" className="tablePadding tablecell">Open Orders Report</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Summary</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Microsoft Excel</TableCell>
                                    <TableCell align="center" className="tablePadding tablecell"><OpenOrdersSummary fromDate={this.state.fromDate} toDate={this.state.EndDate} productClass={this.state.selecteProductClass.value==="All" ? null : this.state.selecteProductClass.value} showerror={this.showErrorMessage}/></TableCell>
                                </TableRow> : null }
                            { this.state.reportsAccess.includes("Open  Orders - Detail") ? 
                                <TableRow key="openordersdetails">
                                    <TableCell align="left" className="tablePadding tablecell">Open Orders Report</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Detailed</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Microsoft Excel</TableCell>
                                    <TableCell align="center" className="tablePadding tablecell"><OpenOrdersDetail fromDate={this.state.fromDate} toDate={this.state.EndDate} productClass={this.state.selecteProductClass.value==="All" ? null : this.state.selecteProductClass.value} showerror={this.showErrorMessage}/></TableCell>
                                </TableRow> : null }
                            { this.state.reportsAccess.includes("Historical Orders - Summary") ? 
                                <TableRow key="historicalorderssummary">
                                    <TableCell align="left" className="tablePadding tablecell">Historical Orders Report</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Summary</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Microsoft Excel</TableCell>
                                    <TableCell align="center" className="tablePadding tablecell"><HistoricalOrdersSummary fromDate={this.state.fromDate} toDate={this.state.EndDate} productClass={this.state.selecteProductClass.value==="All" ? null : this.state.selecteProductClass.value} showerror={this.showErrorMessage}/></TableCell>
                                </TableRow> : null }
                            { this.state.reportsAccess.includes("Historical Orders - Detail") ? 
                                <TableRow key="historicalordersdetails">
                                    <TableCell align="left" className="tablePadding tablecell">Historical Orders Report</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Detailed</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Microsoft Excel</TableCell>
                                    <TableCell align="center" className="tablePadding tablecell"><HistoricalOrderDetails fromDate={this.state.fromDate} toDate={this.state.EndDate} productClass={this.state.selecteProductClass.value==="All" ? null : this.state.selecteProductClass.value} showerror={this.showErrorMessage}/></TableCell>
                                </TableRow> : null }
                            { this.state.reportsAccess.includes("Inventory Onhand Details") ? 
                                <TableRow key="stockonhanddetails">
                                    <TableCell align="left" className="tablePadding tablecell">Stock On-hand Report</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Summary</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Microsoft Excel</TableCell>
                                    <TableCell align="center" className="tablePadding tablecell"><InventoryOnHand productClass={this.state.selecteProductClass.value==="All" ? null : this.state.selecteProductClass.value} showerror={this.showErrorMessage}/></TableCell>
                                </TableRow> : null }
                            { this.state.reportsAccess.includes("Customer Inventory Management") ? 
                                <TableRow key="customerinventorymanagement">
                                    <TableCell align="left" className="tablePadding tablecell">Customer Inventory Report</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Summary</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Microsoft Excel</TableCell>
                                    <TableCell align="center" className="tablePadding tablecell"><CustomerInventoryMgmt productClass={this.state.selecteProductClass.value==="All" ? null : this.state.selecteProductClass.value} showerror={this.showErrorMessage}/></TableCell>
                                </TableRow> : null }
                            { this.state.reportsAccess.includes("Payment History") ? 
                                <TableRow key="PaymentHistory">
                                    <TableCell align="left" className="tablePadding tablecell">Payment History Report</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Summary</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Microsoft Excel</TableCell>
                                    <TableCell align="center" className="tablePadding tablecell"><PaymentHistory fromDate={this.state.fromDate} toDate={this.state.EndDate} showerror={this.showErrorMessage}/></TableCell>
                                </TableRow> : null }
                            { this.state.reportsAccess.includes("Outstanding AR") ? 
                                <TableRow key="OutstandingAR">
                                    <TableCell align="left" className="tablePadding tablecell">Outstanding AR Report</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Summary</TableCell>
                                    <TableCell align="left" className="tablePadding tablecell">Microsoft Excel</TableCell>
                                    <TableCell align="center" className="tablePadding tablecell"><OutstandingAR showerror={this.showErrorMessage}/></TableCell>
                                </TableRow> : null }
                            </TableBody>
                        </Table>
                        {this.state.snackbarMsg}
                    </CardBody>
                </Card>
                </GridItem>
            </GridContainer>
            </Fragment>
        )
    }
}


export default compose(withStyles(styles, { withTheme: true }),
)(Reports)