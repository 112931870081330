import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import CardHeader from "components/Card/CardHeader.js";
import CircularProgress from "@material-ui/core/CircularProgress";
export default class Devices extends Component {
  state = {
    Devices: [],
    spinner: true,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    api
      .get(apiConfig.Devices, { handlerEnabled: true })
      .then((res) => {
        this.setState({ Devices: res.data, spinner: false });
      })
      .catch((error) => {});
  }

  render() {
    return (
      <div>
        <Fragment>
          {this.state.spinner === true ? (
            <div className="spinner">
              <CircularProgress className="innerspinner" />
            </div>
          ) : (
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader className="personalCard">Devices</CardHeader>
                  <CardBody>
                    {this.state.Devices.map((data) => {
                      return (
                        <GridItem xs={12} sm={6} md={8}>
                          <div className="devicediv">
                            <div> {data.DEVICE_NAME}</div>
                            <div style={{ color: "silver" }}>
                              {" "}
                              Last Logged In at {data.LAST_LOGIN}
                            </div>
                          </div>
                        </GridItem>
                      );
                    })}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        </Fragment>
      </div>
    );
  }
}
