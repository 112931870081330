import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import PageviewIcon from "@material-ui/icons/Pageview";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { apiConfig } from "../config/api";
import api from "../config/apiconfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  OrderFulfillment_Data,
  ADDTO_CART_QUANTITY,
  GET_USERINFO,
  SELECTED_ORDER,
  ORDER_HEADER_DETAILS,
} from "../store/actions/index";
import { reduxForm, Field, Form, getFormValues } from "redux-form";
import InputLabel from "@material-ui/core/InputLabel";
import {
  DynamicTextField,
  renderOrderTextField,
  renderSelectField,
  renderDatePicker,
} from "../utils/components/InputComponent";
import { compose } from "redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import ReactSelect, { components } from "react-select";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import Moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import "./OrderFultillment.css";
import EditIcon from "@material-ui/icons/Edit";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const IndicatorsContainer = (props) => {
  return (
    <div className="arrowicon">
      <components.IndicatorsContainer {...props}>
        <components.DropdownIndicator {...props} className="padding4px">
          <ExpandMoreIcon />
        </components.DropdownIndicator>
      </components.IndicatorsContainer>
    </div>
  );
};
const customStyles = {
  control: (base, state, meta, input) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
    // width: "94%",
    height: 52,
    minHeight: 52,
    // overwrittes hover style
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      boxShadow: "none",
    },
  }),
};
const UomStyles = {
  control: (base, state, meta, input) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
    // width: "94%",
    height: 49,
    minHeight: 49,
    // overwrittes hover style
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      boxShadow: "none",
    },
    "&.IndicatorsContainer": {
      padding: "4px",
    },
  }),
};
const styles = (theme) => ({
  textareainput: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "16.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
  materialinputSearch: {
    "& .MuiOutlinedInput-input": {
      padding: "16.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
    },
  },
  labelAsterisk: {
    color: "red",
  },

  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
  datePick: {
    "&.MuiFormControl-marginNormal": {
      marginTop: "0px !important",
      marginBottom: "0px !important",
    },
    "&.MuiIconButton-root": {
      padding: "0px",
    },
  },
});

class OrderFulfillment extends Component {
  promap = {};
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      shipToLov: [],
      emptyData: false,
      inventoryData: [],
      rowsPerPage: 5,
      page: 0,
      InventoryInfo: [],
      selectedShip: null,
      shipToLov: [],
      ModelDialog: false,
      selectedorder: [],
      Uomerror: false,
      error: false,
      filteredOrderColumn: [],
      ALT_ADDRESS: false,
      EstimatedDt: Moment(new Date().setDate(new Date().getDate() + 7)).format(
        "MM/DD/YYYY"
      ),
      isLoading: false,
      Alt_addressform: {},
      open: true,
      selectedRowId: null,
      OriginalOrderInfo: [],
      selecteProductClass: null,
      ProductClassLov: [],
      lovresult: [],
      selectedRow: {},
    };
  }
  ProductClassLov = () => {
    api
      .get(apiConfig.ProductClassLov, { handlerEnabled: true })
      .then((res) => {
        let ProductClassLov = [{ value: "All", label: "All Product Class" }];
        res.data.map((data) => {
          ProductClassLov.push({
            value: data.PRODUCT_CLASS,
            label: data.PRODUCT_CLASS,
          });
        });
        this.setState({
          selecteProductClass: {
            value: ProductClassLov[0].value,
            label: ProductClassLov[0].label,
          },
          ProductClassLov: ProductClassLov,
        });
      })
      .catch((error) => {});
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleClose = () => {
    this.setState({ ModelDialog: false });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  componentDidMount() {
    if (this.props.UserInfo.CONTACT_EMAIL) {
      this.ShipToLov();
    }
    if (!this.props.UserInfo.CONTACT_EMAIL) {
      api
        .get(apiConfig.UserInfo, { handlerEnabled: true })
        .then((res) => {
          this.props.GET_USERINFO(res.data[0]);
          this.ShipToLov();
        })
        .catch((error) => {});
    }
    this.props.OrderFulfillment_Data();
    this.ProductClassLov();
  }
  ShipToLov = () => {
    api
      .post(
        apiConfig.ShipToLOV,
        {
          org_id: this.props.UserInfo.ORG_ID,
          customer_id: this.props.UserInfo.CUSTOMER_ID,
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        let shipToLov = [];
        res.data.map((data) => {
          shipToLov.push({
            value: data.SITE_USE_ID,
            label: data.ADDRESS,
            ALT_ADDRESS: data.ALT_ADDRESS,
          });
        });
        this.setState({
          selectedShip: {
            value: res.data[0].SITE_USE_ID,
            lable: res.data[0].ADDRESS,
          },
          shipToLov: shipToLov,
        });
      })
      .catch((error) => {});
  };
  componentWillUnmount() {
    this.props.OrderFulfillment_Data([]);
  }
  componentDidUpdate(prevProps) {
    var filteredOrderColumn;
    if (
      this.props.InventoryInfo &&
      this.props.InventoryInfo !== prevProps.InventoryInfo
    ) {
      if (this.props.InventoryInfo.length !== prevProps.InventoryInfo.length) {
        this.setState({ spinner: false });
        const columns = [
          {
            id: "Product",
            label: "Product",
            align: "left",
            minWidth: 155,
          },
          {
            id: "Customer Product Code",
            label: "Customer Product Code",
            align: "left",
            minWidth: 160,
          },
          {
            id: "Product Description",
            label: "Product Description",
            align: "left",
            minWidth: 225,
          },
          {
            id: "Product Class",
            label: "Product Class",
            align: "left",
            minWidth: 94,
          },
          {
            id: "Agreement Quantity",
            label: "Agreement Quantity",
            align: "right",
            minWidth: 60,
          },
          {
            id: "Available Quantity",
            label: "Available Quantity",
            align: "right",
            minWidth: 85,
          },
          {
            id: "Primary UOM",
            label: "UOM",
            align: "left",
            minWidth: 30,
          },
          {
            id: "Price",
            label: `Selling Price(${this.props.UserInfo.CURRENCY_CODE})`,
            align: "right",
            minWidth: 60,
          },
          {
            id: "Quantity",
            label: "Quantity",
            align: "left",
            minWidth: 100,
          },
          {
            id: "Order UOM",
            label: "Order UOM",
            align: "left",
            minWidth: 96,
          },
          {
            id: "price",
            label: `Total Price(${this.props.UserInfo.CURRENCY_CODE})`,
            align: "right",
            minWidth: 80,
          },
          {
            id: "Est Ship Date",
            label: "Est Ship Date",
            align: "left",
            minWidth: 146,
          },
          {
            id: "Location",
            label: "Location",
            align: "left",
            minWidth: 160,
          },
          {
            id: "Remarks",
            label: "Remarks",
            align: "left",
            minWidth: 75,
          },
        ];
        if (this.props.UserInfo.AGREEMENT === "N" ? true : false) {
          filteredOrderColumn = columns.filter(
            (item) => item.label !== "Agreement Quantity"
          );
        } else {
          filteredOrderColumn = columns;
        }
        this.setState({ filteredOrderColumn: filteredOrderColumn });
        this.setState({
          InventoryInfo: this.props.InventoryInfo,
          OriginalOrderInfo: this.props.InventoryInfo,
        });
        this.setState({ spinner: false }, () => {
          this.setState({ emptyData: false });
        });
      }
    }
  }
  handleChange = (value) => {
    var ALT_ADDRESS = value.ALT_ADDRESS === "Y" ? true : false;
    if (ALT_ADDRESS) {
      api
        .get(apiConfig.altshiptocountry, { handlerEnabled: true })
        .then((res) => {
          let Countryoptions = [];
          res.data.map((data) => {
            Countryoptions.push({ value: data.COUNTRY_CODE, label: data.NAME });
          });
          this.setState({ Countryoptions: Countryoptions });
        })
        .catch((error) => {});
    }
    this.setState({ selectedShip: value, ALT_ADDRESS: ALT_ADDRESS });
  };
  handlCountryeChange = (value) => {
    this.setState({ selectedCountry: value });
  };
  handleDateChange = (Date) => {
    var hEstimatedShipDt = Moment(Date).format("MM/DD/YYYY");
    this.setState({ EstimatedDt: hEstimatedShipDt });
    var resu = _.forEach(this.state.InventoryInfo, function(obj) {
      _.set(obj, "EstimatedDate", hEstimatedShipDt);
    });
  };
  handlelineDateChange = (Date, data) => {
    var linedate = Moment(Date).format("MM/DD/YYYY");
    const updatedItem = {
      INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
      PRODUCT_CODE: data.PRODUCT_CODE,
      PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
      PRODUCT_CLASS: data.PRODUCT_CLASS,
      CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
      PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
      OAGREEMENT_QTY: data.OAGREEMENT_QTY,
      OAVAILABLE_QTY: data.OAVAILABLE_QTY,
      OsellingPrice: data.OsellingPrice,
      UOMtype: data.UOMtype
        ? data.UOMtype
        : { value: data.PRIMARY_UOM_CODE, label: data.PRIMARY_UOM_CODE },
      LOCATION: data.LOCATION,
      SELLING_PRICE: data.SELLING_PRICE,
      AVAILABLE_QTY: data.AVAILABLE_QTY,
      AGREEMENT_QTY: data.AGREEMENT_QTY,
      quantity: data.quantity,
      price: data.price,
      remark: data.remark,
      EstimatedDate: linedate,
    };
    const OriginalOrderInfo = this.state.OriginalOrderInfo.map((item) =>
      item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
    );
    const newArr = this.state.InventoryInfo.map((item) =>
      item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
    );
    this.setState({
      InventoryInfo: newArr,
      OriginalOrderInfo: OriginalOrderInfo,
    });
    let selectedOrder = this.props.selectedOrder.find(
      (o) => o.PRODUCT_CODE === data.PRODUCT_CODE
    );
    if (selectedOrder) {
      const filterOrder = this.props.selectedOrder.map((item) =>
        item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
      );
      this.props.SELECTED_ORDER(filterOrder);
    } else {
      var filterselecteditem = [...this.props.selectedOrder, updatedItem];
      this.props.SELECTED_ORDER(filterselecteditem);
    }
  };
  onChangeRemark = (value) => {
    var changedRemark = this.state.selectedRow;
    changedRemark.remark = value;
    this.setState({ selectedRow: changedRemark });
  };
  onCloseRemark = (data) => {
    const updatedItem = {
      INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
      PRODUCT_CODE: data.PRODUCT_CODE,
      PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
      PRODUCT_CLASS: data.PRODUCT_CLASS,
      CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
      PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
      OAGREEMENT_QTY: data.OAGREEMENT_QTY,
      OAVAILABLE_QTY: data.OAVAILABLE_QTY,
      OsellingPrice: data.OsellingPrice,
      UOMtype: data.UOMtype,
      LOCATION: data.LOCATION,
      SELLING_PRICE: data.SELLING_PRICE,
      AVAILABLE_QTY: data.AVAILABLE_QTY,
      AGREEMENT_QTY: data.AGREEMENT_QTY,
      quantity: data.quantity,
      price: data.price,
      remark: data.remark,
      EstimatedDate: data.EstimatedDate,
    };
    const OriginalOrderInfo = this.state.OriginalOrderInfo.map((item) =>
      item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
    );
    const newArr = this.state.InventoryInfo.map((item) =>
      item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
    );
    this.setState({
      InventoryInfo: newArr,
      OriginalOrderInfo: OriginalOrderInfo,
    });
  };
  onEditRemarks = (selectedRow) => {
    this.setState({ ModelDialog: true, selectedRow: selectedRow });
  };
  onBlurLineDate = (value, data) => {
    if (data.quantity > 0) {
      const updatedItem = {
        INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
        PRODUCT_CODE: data.PRODUCT_CODE,
        PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
        PRODUCT_CLASS: data.PRODUCT_CLASS,
        CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
        PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
        OAGREEMENT_QTY: data.OAGREEMENT_QTY,
        OAVAILABLE_QTY: data.OAVAILABLE_QTY,
        OsellingPrice: data.OsellingPrice,
        UOMtype: data.UOMtype
          ? data.UOMtype
          : { value: data.PRIMARY_UOM_CODE, label: data.PRIMARY_UOM_CODE },
        LOCATION: data.LOCATION,
        SELLING_PRICE: data.SELLING_PRICE,
        AVAILABLE_QTY: data.AVAILABLE_QTY,
        AGREEMENT_QTY: data.AGREEMENT_QTY,
        quantity: data.quantity,
        price: data.price,
        remark: data.remark,
        EstimatedDate: value,
      };
      const OriginalOrderInfo = this.state.OriginalOrderInfo.map((item) =>
        item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
      );
      const newArr = this.state.InventoryInfo.map((item) =>
        item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
      );
      this.setState({
        InventoryInfo: newArr,
        OriginalOrderInfo: OriginalOrderInfo,
      });
      let selectedOrder = this.props.selectedOrder.find(
        (o) => o.PRODUCT_CODE === data.PRODUCT_CODE
      );
      if (selectedOrder) {
        const filterOrder = this.props.selectedOrder.map((item) =>
          item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
        );
        this.props.SELECTED_ORDER(filterOrder);
      } else {
        var filterselecteditem = [...this.props.selectedOrder, updatedItem];
        this.props.SELECTED_ORDER(filterselecteditem);
      }
    }
  };
  onBlurRemark = (value, data) => {
    if (data.quantity > 0) {
      const updatedItem = {
        INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
        PRODUCT_CODE: data.PRODUCT_CODE,
        PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
        PRODUCT_CLASS: data.PRODUCT_CLASS,
        CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
        PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
        OAGREEMENT_QTY: data.OAGREEMENT_QTY,
        OAVAILABLE_QTY: data.OAVAILABLE_QTY,
        OsellingPrice: data.OsellingPrice,
        UOMtype: data.UOMtype
          ? data.UOMtype
          : { value: data.PRIMARY_UOM_CODE, label: data.PRIMARY_UOM_CODE },
        LOCATION: data.LOCATION,
        SELLING_PRICE: data.SELLING_PRICE,
        AVAILABLE_QTY: data.AVAILABLE_QTY,
        AGREEMENT_QTY: data.AGREEMENT_QTY,
        quantity: data.quantity,
        price: data.price,
        remark: value,
        EstimatedDate: data.EstimatedDate,
      };
      const OriginalOrderInfo = this.state.OriginalOrderInfo.map((item) =>
        item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
      );

      const newArr = this.state.InventoryInfo.map((item) =>
        item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
      );
      this.setState({
        InventoryInfo: newArr,
        OriginalOrderInfo: OriginalOrderInfo,
      });
      let selectedOrder = this.props.selectedOrder.find(
        (o) => o.PRODUCT_CODE === data.PRODUCT_CODE
      );
      if (selectedOrder) {
        const filterOrder = this.props.selectedOrder.map((item) =>
          item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
        );
        this.props.SELECTED_ORDER(filterOrder);
      } else {
        var filterselecteditem = [...this.props.selectedOrder, updatedItem];
        this.props.SELECTED_ORDER(filterselecteditem);
      }
    }
  };

  hiddenCell = (label) =>
    label === "Est Ship Date" ||
    label === "Location" ||
    label === "Product Class";

  SearchProduct = (filterText) => {
    if (
      this.state.selecteProductClass
        ? this.state.selecteProductClass.label
        : null
    ) {
      if (
        this.state.selecteProductClass
          ? this.state.selecteProductClass.value === "All"
            ? "All"
            : null
          : null
      ) {
        if (filterText) {
          var result = this.state.OriginalOrderInfo.filter((objectval) =>
            Object.keys(objectval).some(
              (k) =>
                objectval[k] != null &&
                objectval[k]
                  .toString()
                  .toLowerCase()
                  .includes(filterText.toLowerCase())
            )
          );
          this.setState({ InventoryInfo: result });
        } else {
          this.setState({ InventoryInfo: this.state.OriginalOrderInfo });
        }
      } else {
        if (filterText) {
          var result = this.state.lovresult.filter((objectval) =>
            Object.keys(objectval).some(
              (k) =>
                objectval[k] != null &&
                objectval[k]
                  .toString()
                  .toLowerCase()
                  .includes(filterText.toLowerCase())
            )
          );
          this.setState({ InventoryInfo: result });
        } else {
          this.setState({ InventoryInfo: this.state.lovresult });
        }
      }
    } else {
      if (filterText) {
        var result = this.props.IventoryDataSearch.filter((objectval) =>
          Object.keys(objectval).some(
            (k) =>
              objectval[k] != null &&
              objectval[k]
                .toString()
                .toLowerCase()
                .includes(filterText.toLowerCase())
          )
        );
        this.setState({ InventoryInfo: result });
      } else {
        this.setState({ InventoryInfo: this.state.OriginalOrderInfo });
      }
    }
  };

  handleChangeProdctClass = (value) => {
    this.setState({ selecteProductClass: value });
    if (value.value === "All") {
      this.setState({ InventoryInfo: this.state.OriginalOrderInfo });
    } else {
      var result = this.state.OriginalOrderInfo.filter((objectval) =>
        Object.keys(objectval).some(
          (k) =>
            objectval[k] != null &&
            objectval[k]
              .toString()
              .toLowerCase()
              .includes(value.label.toLowerCase())
        )
      );
      this.setState({ lovresult: result });
      this.setState({ InventoryInfo: result });
    }
  };

  loadUomType = (data) => {
    this.setState({ isLoading: true, Uomoptions: [] });
    this.promap[data.PRODUCT_CODE] = true;
    api
      .post(
        apiConfig.UOMConversion,
        { inventory_item_id: data.INVENTORY_ITEM_ID },
        { handlerEnabled: true }
      )
      .then((res) => {
        this.promap[data.PRODUCT_CODE] = false;
        let Uomoptions = [];
        res.data.map((data, i) => {
          Uomoptions.push({
            id: i,
            value: data.TO_UOM_CODE,
            label: data.TO_UOM_CODE,
            CONVERSION_RATE: data.CONVERSION_RATE,
          });
        });
        this.setState({
          Uomoptions: Uomoptions,
          isLoading: false,
        });
      })
      .catch((error) => {});
  };

  onChangequanity = (value, data) => {
    var changedprice = "0.00";
    if (value) {
      var removedComma = data.SELLING_PRICE.includes(",")
        ? data.SELLING_PRICE.replace(/,/g, "")
        : data.SELLING_PRICE;
      changedprice = parseFloat(removedComma * value)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
    const updatedItem = {
      INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
      PRODUCT_CODE: data.PRODUCT_CODE,
      PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
      PRODUCT_CLASS: data.PRODUCT_CLASS,
      UOMtype: data.UOMtype,
      CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
      LOCATION: data.LOCATION,
      SELLING_PRICE: data.SELLING_PRICE,
      OsellingPrice: data.OsellingPrice,
      PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
      OAGREEMENT_QTY: data.OAGREEMENT_QTY,
      OAVAILABLE_QTY: data.OAVAILABLE_QTY,
      AVAILABLE_QTY: data.AVAILABLE_QTY,
      AGREEMENT_QTY: data.AGREEMENT_QTY,
      price: changedprice,
      quantity: value,
      remark: data.remark,
      EstimatedDate: data.EstimatedDate,
    };
    const OriginalOrderInfo = this.state.OriginalOrderInfo.map((item) =>
      item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
    );
    const newArr = this.state.InventoryInfo.map((item) =>
      item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
    );
    this.setState({
      InventoryInfo: newArr,
      OriginalOrderInfo: OriginalOrderInfo,
    });
  };

  handleUOMType = (value, data) => {
    let cartdata = this.props.AddtoCartQuantity.find(
      (o) => o.PRODUCT_CODE === data.PRODUCT_CODE
    );
    if (cartdata) {
      if (cartdata.UOMtype.label !== value.label) {
        this.setState({ Uomerror: true });
        this.promap[data.PRODUCT_CODE] = true;
        this.setState({
          snackbarMsg: (
            <Snackbar open autoHideDuration={1000}>
              <Alert
                onClose={() => this.setState({ snackbarMsg: "" })}
                variant="filled"
                severity="error"
              >
                You cannot add Different UOM for items already added to the cart
              </Alert>
            </Snackbar>
          ),
        });
      } else {
        this.setState({ Uomerror: false });
        this.promap[data.PRODUCT_CODE] = false;
        this.setState({ snackbarMsg: "" });
        var filteruom = this.state.Uomoptions.filter((obj) => {
          return obj.id === value.id;
        });
        var CONVERSION_RATE = filteruom[0].CONVERSION_RATE;
        var removedComma = data.OsellingPrice.includes(",")
          ? data.OsellingPrice.replace(/,/g, "")
          : data.OsellingPrice;
        var sellingPrice = parseFloat(removedComma * CONVERSION_RATE)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        const updatedItem = {
          INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
          PRODUCT_CODE: data.PRODUCT_CODE,
          PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
          PRODUCT_CLASS: data.PRODUCT_CLASS,
          UOMtype: value,
          CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
          LOCATION: data.LOCATION,
          SELLING_PRICE: sellingPrice,
          OsellingPrice: data.OsellingPrice,
          PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
          OAGREEMENT_QTY: data.OAGREEMENT_QTY,
          OAVAILABLE_QTY: data.OAVAILABLE_QTY,
          AVAILABLE_QTY: CONVERSION_RATE * data.OAVAILABLE_QTY,
          AGREEMENT_QTY: CONVERSION_RATE * data.OAGREEMENT_QTY,
          price: parseFloat(0.0)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
          quantity: "",
          remark: "",
          EstimatedDate: data.EstimatedDate,
        };
        const OriginalOrderInfo = this.state.OriginalOrderInfo.map((item) =>
          item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
        );
        const newArr = this.state.InventoryInfo.map((item) =>
          item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
        );
        this.setState({
          InventoryInfo: newArr,
          OriginalOrderInfo: OriginalOrderInfo,
        });
      }
    } else {
      var filteruom = this.state.Uomoptions.filter((obj) => {
        return obj.id === value.id;
      });
      var CONVERSION_RATE = filteruom[0].CONVERSION_RATE;
      var removedComma = data.OsellingPrice.includes(",")
        ? data.OsellingPrice.replace(/,/g, "")
        : data.OsellingPrice;
      var sellingPrice = parseFloat(removedComma * CONVERSION_RATE)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      const updatedItem = {
        INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
        PRODUCT_CODE: data.PRODUCT_CODE,
        PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
        PRODUCT_CLASS: data.PRODUCT_CLASS,
        UOMtype: value,
        CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
        LOCATION: data.LOCATION,
        SELLING_PRICE: sellingPrice,
        OsellingPrice: data.OsellingPrice,
        PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
        OAGREEMENT_QTY: data.OAGREEMENT_QTY,
        OAVAILABLE_QTY: data.OAVAILABLE_QTY,
        AVAILABLE_QTY: CONVERSION_RATE * data.OAVAILABLE_QTY,
        AGREEMENT_QTY: CONVERSION_RATE * data.OAGREEMENT_QTY,
        price: parseFloat(0.0)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
        quantity: "",
        remark: "",
        EstimatedDate: data.EstimatedDate,
      };
      const OriginalOrderInfo = this.state.OriginalOrderInfo.map((item) =>
        item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
      );
      const newArr = this.state.InventoryInfo.map((item) =>
        item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
      );
      this.setState({
        InventoryInfo: newArr,
        OriginalOrderInfo: OriginalOrderInfo,
      });
    }
  };
  onClickAddtoCart = () => {
    const {
      ShipTo,
      customerpoNum,
      Ship_Companyname,
      Ship_AddressLine1,
      Ship_AddressLine2,
      Country,
      Postal_Code,
    } = this.props.OrderheaderDet;
    var alt_add_validation = ShipTo
      ? ShipTo.ALT_ADDRESS === "Y"
        ? true
        : false
      : false;
    var Shipto_add = ShipTo ? (ShipTo.ALT_ADDRESS === "Y" ? "Y" : null) : null;
    if (this.props.selectedOrder.length > 0 ? true : false) {
      if (customerpoNum !== "" && ShipTo !== "null") {
        if (Shipto_add === "Y") {
          if (
            Ship_Companyname !== "" &&
            Ship_AddressLine1 !== "" &&
            Ship_AddressLine2 !== "" &&
            Country !== "" &&
            Postal_Code != ""
          ) {
            var addtocartitem = this.props.AddtoCartQuantity;
            this.props.selectedOrder.map((data, i) => {
              if (data.quantity != 0) {
                let selectedcart = addtocartitem.find(
                  (o) => o.PRODUCT_CODE === data.PRODUCT_CODE
                );
                if (selectedcart) {
                  const updatedItem = {
                    INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
                    PRODUCT_CODE: data.PRODUCT_CODE,
                    PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
                    PRODUCT_CLASS: data.PRODUCT_CLASS,
                    CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
                    PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
                    OAGREEMENT_QTY: data.OAGREEMENT_QTY,
                    OAVAILABLE_QTY: data.OAVAILABLE_QTY,
                    OsellingPrice: data.OsellingPrice,
                    UOMtype: data.UOMtype
                      ? data.UOMtype
                      : {
                          value: data.PRIMARY_UOM_CODE,
                          label: data.PRIMARY_UOM_CODE,
                        },
                    LOCATION: data.LOCATION,
                    SELLING_PRICE: data.SELLING_PRICE,
                    AVAILABLE_QTY: data.AVAILABLE_QTY,
                    AGREEMENT_QTY: data.AGREEMENT_QTY,
                    quantity:
                      parseFloat(data.quantity) +
                      parseFloat(selectedcart.quantity),
                    price: (
                      parseFloat(
                        data.price.includes(",")
                          ? data.price.replace(/,/g, "")
                          : data.price
                      ) +
                      parseFloat(
                        selectedcart.price.includes(",")
                          ? selectedcart.price.replace(/,/g, "")
                          : selectedcart.price
                      )
                    )
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                    remark: data.remark,
                    EstimatedDate: data.EstimatedDate,
                  };
                  if (selectedcart.EstimatedDate === data.EstimatedDate) {
                    const newArr = addtocartitem.map((item) =>
                      item.PRODUCT_CODE === data.PRODUCT_CODE
                        ? updatedItem
                        : item
                    );
                    addtocartitem = newArr;
                  } else {
                    const updatedItem = {
                      INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
                      PRODUCT_CODE: data.PRODUCT_CODE,
                      PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
                      PRODUCT_CLASS: data.PRODUCT_CLASS,
                      CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
                      PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
                      OAGREEMENT_QTY: data.OAGREEMENT_QTY,
                      OAVAILABLE_QTY: data.OAVAILABLE_QTY,
                      OsellingPrice: data.OsellingPrice,
                      UOMtype: data.UOMtype
                        ? data.UOMtype
                        : {
                            value: data.PRIMARY_UOM_CODE,
                            label: data.PRIMARY_UOM_CODE,
                          },
                      LOCATION: data.LOCATION,
                      SELLING_PRICE: data.SELLING_PRICE,
                      AVAILABLE_QTY: data.AVAILABLE_QTY,
                      AGREEMENT_QTY: data.AGREEMENT_QTY,
                      quantity: data.quantity,
                      price: parseFloat(
                        data.price.includes(",")
                          ? data.price.replace(/,/g, "")
                          : data.price
                      )
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                      remark: data.remark,
                      EstimatedDate: data.EstimatedDate,
                    };
                    addtocartitem = [...addtocartitem, updatedItem];
                  }
                } else {
                  const updatedItem = {
                    INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
                    PRODUCT_CODE: data.PRODUCT_CODE,
                    PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
                    PRODUCT_CLASS: data.PRODUCT_CLASS,
                    CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
                    PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
                    OAGREEMENT_QTY: data.OAGREEMENT_QTY,
                    OAVAILABLE_QTY: data.OAVAILABLE_QTY,
                    OsellingPrice: data.OsellingPrice,
                    UOMtype: data.UOMtype
                      ? data.UOMtype
                      : {
                          value: data.PRIMARY_UOM_CODE,
                          label: data.PRIMARY_UOM_CODE,
                        },
                    LOCATION: data.LOCATION,
                    SELLING_PRICE: data.SELLING_PRICE,
                    AVAILABLE_QTY: data.AVAILABLE_QTY,
                    AGREEMENT_QTY: data.AGREEMENT_QTY,
                    quantity: data.quantity,
                    price: data.price,
                    remark: data.remark,
                    EstimatedDate: data.EstimatedDate,
                  };
                  addtocartitem = [...addtocartitem, updatedItem];
                }
              }
            });
            this.props.ADDTO_CART_QUANTITY(addtocartitem);
            if (addtocartitem.length > 0) {
              this.setState({
                snackbarMsg: (
                  <Snackbar open autoHideDuration={10}>
                    <Alert
                      onClose={() => this.setState({ snackbarMsg: "" })}
                      variant="filled"
                      severity="success"
                    >
                      Items added to the Cart
                    </Alert>
                  </Snackbar>
                ),
              });
            }
            const arr = this.state.InventoryInfo.map((el) => ({
              ...el,
              UOMtype: "",
              OsellingPrice: el.SELLING_PRICE,
              OAVAILABLE_QTY: el.AVAILABLE_QTY,
              OAGREEMENT_QTY: el.AGREEMENT_QTY,
              price: "0.00",
              quantity: "",
              remark: "",
              EstimatedDate: Moment(
                new Date().setDate(new Date().getDate() + 7)
              ).format("MM/DD/YYYY"),
            }));
            this.setState({ InventoryInfo: arr });
            this.props.SELECTED_ORDER([]);
          } else {
            this.setState({
              snackbarMsg: (
                <Snackbar open autoHideDuration={1000}>
                  <Alert
                    onClose={() => this.setState({ snackbarMsg: "" })}
                    variant="filled"
                    severity="error"
                  >
                    Please fill the Mandatory fields
                  </Alert>
                </Snackbar>
              ),
            });
          }
        } else {
          var addtocartitem = this.props.AddtoCartQuantity;
          this.props.selectedOrder.map((data, i) => {
            if (data.quantity != 0) {
              let selectedcart = addtocartitem.find(
                (o) => o.PRODUCT_CODE === data.PRODUCT_CODE
              );
              if (selectedcart) {
                const updatedItem = {
                  INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
                  PRODUCT_CODE: data.PRODUCT_CODE,
                  PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
                  PRODUCT_CLASS: data.PRODUCT_CLASS,
                  CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
                  PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
                  OAGREEMENT_QTY: data.OAGREEMENT_QTY,
                  OAVAILABLE_QTY: data.OAVAILABLE_QTY,
                  OsellingPrice: data.OsellingPrice,
                  UOMtype: data.UOMtype
                    ? data.UOMtype
                    : {
                        value: data.PRIMARY_UOM_CODE,
                        label: data.PRIMARY_UOM_CODE,
                      },
                  LOCATION: data.LOCATION,
                  SELLING_PRICE: data.SELLING_PRICE,
                  AVAILABLE_QTY: data.AVAILABLE_QTY,
                  AGREEMENT_QTY: data.AGREEMENT_QTY,
                  quantity:
                    parseFloat(data.quantity) +
                    parseFloat(selectedcart.quantity),
                  price: (
                    parseFloat(
                      data.price.includes(",")
                        ? data.price.replace(/,/g, "")
                        : data.price
                    ) +
                    parseFloat(
                      selectedcart.price.includes(",")
                        ? selectedcart.price.replace(/,/g, "")
                        : selectedcart.price
                    )
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                  remark: data.remark,
                  EstimatedDate: data.EstimatedDate,
                };
                if (selectedcart.EstimatedDate === data.EstimatedDate) {
                  const newArr = addtocartitem.map((item) =>
                    item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
                  );
                  addtocartitem = newArr;
                } else {
                  const updatedItem = {
                    INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
                    PRODUCT_CODE: data.PRODUCT_CODE,
                    PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
                    PRODUCT_CLASS: data.PRODUCT_CLASS,
                    CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
                    PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
                    OAGREEMENT_QTY: data.OAGREEMENT_QTY,
                    OAVAILABLE_QTY: data.OAVAILABLE_QTY,
                    OsellingPrice: data.OsellingPrice,
                    UOMtype: data.UOMtype
                      ? data.UOMtype
                      : {
                          value: data.PRIMARY_UOM_CODE,
                          label: data.PRIMARY_UOM_CODE,
                        },
                    LOCATION: data.LOCATION,
                    SELLING_PRICE: data.SELLING_PRICE,
                    AVAILABLE_QTY: data.AVAILABLE_QTY,
                    AGREEMENT_QTY: data.AGREEMENT_QTY,
                    quantity: data.quantity,
                    price: parseFloat(data.price)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                    remark: data.remark,
                    EstimatedDate: data.EstimatedDate,
                  };
                  addtocartitem = [...addtocartitem, updatedItem];
                }
              } else {
                const updatedItem = {
                  INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
                  PRODUCT_CODE: data.PRODUCT_CODE,
                  PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
                  PRODUCT_CLASS: data.PRODUCT_CLASS,
                  CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
                  PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
                  OAGREEMENT_QTY: data.OAGREEMENT_QTY,
                  OAVAILABLE_QTY: data.OAVAILABLE_QTY,
                  OsellingPrice: data.OsellingPrice,
                  UOMtype: data.UOMtype
                    ? data.UOMtype
                    : {
                        value: data.PRIMARY_UOM_CODE,
                        label: data.PRIMARY_UOM_CODE,
                      },
                  LOCATION: data.LOCATION,
                  SELLING_PRICE: data.SELLING_PRICE,
                  AVAILABLE_QTY: data.AVAILABLE_QTY,
                  AGREEMENT_QTY: data.AGREEMENT_QTY,
                  quantity: data.quantity,
                  price: data.price,
                  remark: data.remark,
                  EstimatedDate: data.EstimatedDate,
                };
                addtocartitem = [...addtocartitem, updatedItem];
              }
            }
          });
          this.props.ADDTO_CART_QUANTITY(addtocartitem);
          if (addtocartitem.length > 0) {
            this.setState({
              snackbarMsg: (
                <Snackbar open autoHideDuration={10}>
                  <Alert
                    onClose={() => this.setState({ snackbarMsg: "" })}
                    variant="filled"
                    severity="success"
                  >
                    Items added to the Cart
                  </Alert>
                </Snackbar>
              ),
            });
          }
          const arr = this.state.InventoryInfo.map((el) => ({
            ...el,
            UOMtype: "",
            OsellingPrice: el.SELLING_PRICE,
            OAVAILABLE_QTY: el.AVAILABLE_QTY,
            OAGREEMENT_QTY: el.AGREEMENT_QTY,
            price: "0.00",
            quantity: "",
            remark: "",
            EstimatedDate: Moment(
              new Date().setDate(new Date().getDate() + 7)
            ).format("MM/DD/YYYY"),
          }));
          this.setState({ InventoryInfo: arr });
          this.props.SELECTED_ORDER([]);
        }
      } else {
        this.setState({
          snackbarMsg: (
            <Snackbar open autoHideDuration={1000}>
              <Alert
                variant="filled"
                severity="error"
                onClose={() => this.setState({ snackbarMsg: "" })}
              >
                Please fill the Mandatory fields
              </Alert>
            </Snackbar>
          ),
        });
      }
    } else {
      this.setState({
        snackbarMsg: (
          <Snackbar open autoHideDuration={1000}>
            <Alert
              variant="filled"
              severity="error"
              onClose={() => this.setState({ snackbarMsg: "" })}
            >
              Please enter the quantity
            </Alert>
          </Snackbar>
        ),
      });
    }
  };
  quantityValue = (value, data) => {
    if (value) {
      let cartdata = this.props.AddtoCartQuantity.find(
        (o) => o.PRODUCT_CODE === data.PRODUCT_CODE
      );
      var avilableqty = cartdata
        ? parseInt(data.AVAILABLE_QTY) - parseInt(cartdata.quantity)
        : data.AVAILABLE_QTY;
      if (
        sessionStorage.getItem("AGREEMENT") === "N"
          ? false
          : parseInt(value) > parseInt(avilableqty)
          ? true
          : false
      ) {
        this.promap[data.PRODUCT_CODE] = true;
        this.setState({ error: true });
        this.setState({
          snackbarMsg: (
            <Snackbar open={this.state.open} autoHideDuration={1000}>
              <Alert
                variant="filled"
                severity="error"
                onClose={() => this.setState({ snackbarMsg: "" })}
              >
                Quantity should be less than Available Quantity
              </Alert>
            </Snackbar>
          ),
        });
      } else {
        this.promap[data.PRODUCT_CODE] = false;
        this.setState({ error: false });
        this.setState({ snackbarMsg: "" });
        var removedComma = data.SELLING_PRICE.includes(",")
          ? data.SELLING_PRICE.replace(/,/g, "")
          : data.SELLING_PRICE;
        var sellingprice = parseFloat(removedComma * value)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        const updatedItem = {
          INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
          PRODUCT_CODE: data.PRODUCT_CODE,
          PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
          PRODUCT_CLASS: data.PRODUCT_CLASS,
          CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
          PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
          OAGREEMENT_QTY: data.OAGREEMENT_QTY,
          OAVAILABLE_QTY: data.OAVAILABLE_QTY,
          OsellingPrice: data.OsellingPrice,
          UOMtype: data.UOMtype
            ? data.UOMtype
            : { value: data.PRIMARY_UOM_CODE, label: data.PRIMARY_UOM_CODE },
          LOCATION: data.LOCATION,
          SELLING_PRICE: data.SELLING_PRICE,
          AVAILABLE_QTY: data.AVAILABLE_QTY,
          AGREEMENT_QTY: data.AGREEMENT_QTY,
          quantity: data.quantity,
          price: sellingprice,
          remark: data.remark,
          EstimatedDate: data.EstimatedDate,
        };
        const OriginalOrderInfo = this.state.OriginalOrderInfo.map((item) =>
          item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
        );
        const newArr = this.state.InventoryInfo.map((item) =>
          item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
        );
        this.setState({
          InventoryInfo: newArr,
          OriginalOrderInfo: OriginalOrderInfo,
        });
        let selectedOrder = this.props.selectedOrder.find(
          (o) => o.PRODUCT_CODE === data.PRODUCT_CODE
        );
        if (selectedOrder) {
          const filterOrder = this.props.selectedOrder.map((item) =>
            item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
          );
          this.props.SELECTED_ORDER(filterOrder);
        } else {
          var filterselecteditem = [...this.props.selectedOrder, updatedItem];
          this.props.SELECTED_ORDER(filterselecteditem);
        }
      }
    } else {
      const updatedItem = {
        INVENTORY_ITEM_ID: data.INVENTORY_ITEM_ID,
        PRODUCT_CODE: data.PRODUCT_CODE,
        PRODUCT_DESCRIPTION: data.PRODUCT_DESCRIPTION,
        PRODUCT_CLASS: data.PRODUCT_CLASS,
        CUSTOMER_PRODUCT_CODE: data.CUSTOMER_PRODUCT_CODE,
        PRIMARY_UOM_CODE: data.PRIMARY_UOM_CODE,
        OAGREEMENT_QTY: data.OAGREEMENT_QTY,
        OAVAILABLE_QTY: data.OAVAILABLE_QTY,
        OsellingPrice: data.OsellingPrice,
        UOMtype: data.UOMtype
          ? data.UOMtype
          : { value: data.PRIMARY_UOM_CODE, label: data.PRIMARY_UOM_CODE },
        LOCATION: data.LOCATION,
        SELLING_PRICE: data.SELLING_PRICE,
        AVAILABLE_QTY: data.AVAILABLE_QTY,
        AGREEMENT_QTY: data.AGREEMENT_QTY,
        quantity: data.quantity,
        price: "0.00",
        remark: data.remark,
        EstimatedDate: data.EstimatedDate,
      };
      const OriginalOrderInfo = this.state.OriginalOrderInfo.map((item) =>
        item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
      );

      const newArr = this.state.InventoryInfo.map((item) =>
        item.PRODUCT_CODE === data.PRODUCT_CODE ? updatedItem : item
      );
      this.setState({
        InventoryInfo: newArr,
        OriginalOrderInfo: OriginalOrderInfo,
      });
      const filtereditem = this.props.selectedOrder.filter(
        (item) => item.PRODUCT_CODE !== data.PRODUCT_CODE
      );
      this.promap[data.PRODUCT_CODE] = false;
      this.setState({ error: false });
      this.setState({ snackbarMsg: "" });
      this.props.SELECTED_ORDER(filtereditem);
    }
  };
  restrictMinus = (e) => {
    var inputKeyCode = e.keyCode ? e.keyCode : e.which;
    if (inputKeyCode != null) {
      if (inputKeyCode == 45) e.preventDefault();
    }
  };
  render() {
    const { ShipTo, customerpoNum } = this.props.OrderheaderDet;
    var ALT_ADDRESS = ShipTo.ALT_ADDRESS === "Y" ? true : false;
    var agreement = this.props.UserInfo
      ? this.props.UserInfo.AGREEMENT === "N"
        ? true
        : false
      : "";
    const { classes, handleSubmit } = this.props;
    const { selectedShip } = this.state;
    return (
      <Fragment>
        {this.state.snackbarMsg}
        {this.state.spinner ? (
          <div className="spinner">
            <CircularProgress className="innerspinner" />
          </div>
        ) : null}
        <Dialog
          open={this.state.ModelDialog}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" onClose={this.handleClose}>
            {"Remarks"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                multiline
                style={{ width: "400px" }}
                className={classes.textareainput}
                rows={4}
                value={
                  this.state.selectedRow ? this.state.selectedRow.remark : null
                }
                onChange={(e) => {
                  this.onChangeRemark(e.target.value);
                }}
                variant="outlined"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="Orderbutton"
              onClick={() => {
                this.onCloseRemark(this.state.selectedRow);
                this.setState({ ModelDialog: false });
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <Form className="mtop-20">
                  <GridContainer className="ordertopbar">
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      className="alignLabelcenter  mbtm-10 bold"
                    >
                      <InputLabel className="ordertextcolor">
                        Customer Name
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                      <Field
                        name="CustomerName"
                        type="text"
                        className={`${classes.materialinput} "ordertextcolor"`}
                        component={renderOrderTextField}
                        value={this.props.UserInfo.CUSTOMER_NAME}
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      className="alignLabelcenter mbtm-10 bold"
                    >
                      <InputLabel className="ordertextcolor asterisk">
                        Customer PO Number
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                      <Field
                        name="customerpoNum"
                        className={`${classes.materialinput} "ordertextcolor"`}
                        component={renderOrderTextField}
                        disabled={false}
                        inputProps={{ maxLength: 30 }}
                      />
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      className="alignLabelcenter mbtm-10 bold"
                    >
                      <InputLabel className="ordertextcolor">
                        Bill To
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                      <Field
                        name="Bill_To"
                        className={`${classes.materialinput} "ordertextcolor"`}
                        component={renderOrderTextField}
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      className="alignLabelcenter mbtm-10 bold"
                    >
                      <InputLabel className="ordertextcolor">
                        Estimated Ship Date
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                      <Field
                        name="EstimatedDt"
                        className={classes.datePick}
                        component={renderDatePicker}
                        onChange={this.handleDateChange}
                        disabled={false}
                      />
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      className="alignLabelcenter mbtm-10 bold"
                    >
                      <InputLabel className="ordertextcolor  asterisk">
                        Ship To
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                      <Field
                        name="ShipTo"
                        placeholder="Select Ship To"
                        type="text"
                        onChange={this.handleChange}
                        component={renderSelectField}
                        options={this.state.shipToLov}
                      />
                    </GridItem>
                    {/* <GridItem xs={6} sm={6} md={2} className="alignLabelcenter mbtm-10 bold">
                      <InputLabel className="ordertextcolor" >Payment Terms</InputLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4} className="mbtm-10">

                      <Field
                        name="Payment_Terms"
                        type="text"
                        className={`${classes.materialinput} "ordertextcolor" `} component={renderOrderTextField}
                        value={this.props.UserInfo.CUSTOMER_NAME}
                        disabled={true}

                      />
                    </GridItem> */}
                    {ALT_ADDRESS ? (
                      <Fragment>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          className="alignLabelcenter mbtm-10 bold"
                        >
                          <InputLabel className="ordertextcolor asterisk">
                            Ship To Company Name
                          </InputLabel>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                          <Field
                            name="Ship_Companyname"
                            inputProps={{ maxLength: 240 }}
                            className={`${classes.materialinput} "ordertextcolor"`}
                            component={renderOrderTextField}
                            value={this.props.UserInfo.CUSTOMER_NAME}
                            disabled={false}
                          />
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          className="alignLabelcenter mbtm-10 bold"
                        >
                          <InputLabel className="ordertextcolor asterisk">
                            Ship To Addr..1
                          </InputLabel>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                          <Field
                            name="Ship_AddressLine1"
                            inputProps={{ maxLength: 240 }}
                            className={`${classes.materialinput} "ordertextcolor"`}
                            component={renderOrderTextField}
                            disabled={false}
                          />
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          className="alignLabelcenter mbtm-10 bold"
                        >
                          <InputLabel className="ordertextcolor asterisk">
                            Ship To Addr..2
                          </InputLabel>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                          <Field
                            name="Ship_AddressLine2"
                            type="text"
                            className={`${classes.materialinput} "ordertextcolor"`}
                            component={renderOrderTextField}
                            inputProps={{ maxLength: 240 }}
                            disabled={false}
                          />
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          className="alignLabelcenter mbtm-10 bold"
                        >
                          <InputLabel className="ordertextcolor asterisk">
                            Ship To Country
                          </InputLabel>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                          <Field
                            name="Country"
                            placeholder="Select Country"
                            type="text"
                            component={renderSelectField}
                            options={this.state.Countryoptions}
                          />
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          className="alignLabelcenter mbtm-10 bold"
                        >
                          <InputLabel className="ordertextcolor asterisk">
                            Postal Code
                          </InputLabel>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                          <Field
                            name="Postal_Code"
                            inputProps={{ maxLength: 240 }}
                            className={`${classes.materialinput} "ordertextcolor"`}
                            component={renderOrderTextField}
                            disabled={false}
                          />
                        </GridItem>
                      </Fragment>
                    ) : null}
                    {/* <GridItem xs={6} sm={6} md={2} className="alignLabelcenter mbtm-10 bold">
                      <InputLabel className="ordertextcolor">Tax Description</InputLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4} className="mbtm-10">

                      <Field
                        name="TAX_CODE_DESCRIPTION"
                        className={`${classes.materialinput} "ordertextcolor"`} component={renderOrderTextField}
                        disabled={true}
                      />
                    </GridItem> */}

                    {/* <GridItem xs={6} sm={6} md={2} className="alignLabelcenter mbtm-10 bold">
                      <InputLabel className="ordertextcolor" >Currency</InputLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4} className="mbtm-10">

                      <Field
                        name="Currency"
 className={`${classes.materialinput} "ordertextcolor"`} component={renderOrderTextField}
 disabled={true}
 />
                    </GridItem> */}
                    {/* <GridItem xs={6} sm={6} md={2} className="alignLabelcenter mbtm-10 bold">
                      <InputLabel className="ordertextcolor" >Sales Person</InputLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4} className="mbtm-10">

                      <Field
                        name="Sales_Person"
className={`${classes.materialinput} "ordertextcolor"`} component={renderOrderTextField}
disabled={true}
 />
                    </GridItem> */}

                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      className="alignLabelcenter mbtm-10 bold"
                    >
                      <InputLabel className="ordertextcolor">
                        Notes/Remarks
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4} className="mbtm-10">
                      <Field
                        name="HeaderRemark"
                        className={`${classes.materialinput} "ordertextcolor"`}
                        component={renderOrderTextField}
                        disabled={false}
                      ></Field>
                    </GridItem>
                  </GridContainer>
                </Form>
                <GridContainer className="ordertopbar">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selecteProductClass}
                      onChange={this.handleChangeProdctClass}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.ProductClassLov}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <TextField
                      placeholder="Search for Products"
                      variant="outlined"
                      onChange={(event) => {
                        this.SearchProduct(event.target.value);
                      }}
                      className={`${classes.materialinputSearch}`}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PageviewIcon className="search_icon" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto", textAlign: "end" }}
                    className="mbtm-10"
                  >
                    <Button
                      className="addtocart"
                      onClick={() => {
                        this.onClickAddtoCart();
                      }}
                    >
                      Add to Cart
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer className="ordertable">
          <GridItem xs={12}>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {this.state.spinner === false ? (
                  <form>
                    <Table className="orderFulfillment">
                      <TableHead>
                        <TableRow hover>
                          {this.state.filteredOrderColumn.map(
                            (column, index) => (
                              <TableCell
                                className={
                                  this.hiddenCell(column.label)
                                    ? "tblColthHide"
                                    : "bold tablePadding"
                                }
                                key={index}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.InventoryInfo.slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        ).map((data, i) => {
                          return (
                            <TableRow
                              hover
                              onClick={() => {
                                this.setState({ selectedRowId: i });
                              }}
                              selected={this.state.selectedRowId === i}
                              key={i}
                              classes={{
                                hover: classes.hover,
                                selected: classes.selected,
                              }}
                              className={classes.tableRow}
                            >
                              <TableCell
                                align="left"
                                className="tablePadding tablecell"
                              >
                                {data.PRODUCT_CODE}
                              </TableCell>
                              <TableCell align="left" className="tablePadding">
                                {data.CUSTOMER_PRODUCT_CODE}
                              </TableCell>
                              <TableCell align="left" className="tablePadding">
                                {data.PRODUCT_DESCRIPTION}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tablePadding tblColHide"
                              >
                                {data.PRODUCT_CLASS}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={`tablePadding ${
                                  agreement ? "AgreementQuantity" : ""
                                }`}
                              >
                                {data.AGREEMENT_QTY}
                              </TableCell>
                              <TableCell align="right" className="tablePadding">
                                {data.AVAILABLE_QTY}
                              </TableCell>
                              <TableCell align="left" className="tablePadding">
                                {data.PRIMARY_UOM_CODE}
                              </TableCell>
                              <TableCell align="right" className="tablePadding">
                                {data.SELLING_PRICE}
                              </TableCell>
                              <TableCell align="left" className="tablePadding">
                                <DynamicTextField
                                  type="number"
                                  onChange={(e) =>
                                    this.onChangequanity(e.target.value, data)
                                  }
                                  label="Quantity"
                                  value={data.quantity}
                                  error={
                                    this.state.error &&
                                    this.promap[data.PRODUCT_CODE]
                                  }
                                  onBlur={() =>
                                    this.quantityValue(data.quantity, data)
                                  }
                                  variant="outlined"
                                  min="0"
                                  onKeypress={(event) =>
                                    this.restrictMinus(event)
                                  }
                                />
                              </TableCell>
                              <TableCell align="left" className="tablePadding">
                                {" "}
                                <div>
                                  <ReactSelect
                                    value={data.UOMtype}
                                    onMenuOpen={() => this.loadUomType(data)}
                                    components={{ IndicatorsContainer }}
                                    onChange={(val) =>
                                      this.handleUOMType(val, data)
                                    }
                                    isLoading={
                                      this.state.isLoading &&
                                      this.promap[data.PRODUCT_CODE]
                                        ? true
                                        : false
                                    }
                                    styles={UomStyles}
                                    style={{ width: "500%" }}
                                    placeholder={data.PRIMARY_UOM_CODE}
                                    options={this.state.Uomoptions}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="right" className="tablePadding">
                                {data.price}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tablePadding tblColHide"
                              >
                                <div style={{ marginTop: "-7px" }}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      disableToolbar
                                      inputProps={{
                                        style: {
                                          padding: "14.5px 8px",
                                        },
                                      }}
                                      k
                                      variant="inline"
                                      inputVariant="outlined"
                                      format="MM/dd/yyyy"
                                      margin="normal"
                                      id="date-picker-inline"
                                      placeholder="select Ship Date"
                                      minDate={new Date()}
                                      value={data.EstimatedDate}
                                      onChange={(val) =>
                                        this.handlelineDateChange(val, data)
                                      }
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                      }}
                                      className={classes.materialinput}
                                      autoOk={true}
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tablePadding tblColHide"
                              >
                                {data.LOCATION}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tablePadding"
                              >
                                <EditIcon
                                  onClick={() => {
                                    this.onEditRemarks(data);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={this.state.InventoryInfo.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            SelectProps={{
                              inputProps: { "aria-label": "rows per page" },
                              native: true,
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                    {this.state.InventoryInfo.length === 0 &&
                    this.state.spinner === false ? (
                      <div className="nodatafound">No Data Found</div>
                    ) : null}
                  </form>
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const arr = state.OrderFulfillmentInfo.IventoryData.map((el) => ({
    ...el,
    UOMtype: "",
    OsellingPrice: el.SELLING_PRICE,
    OAVAILABLE_QTY: el.AVAILABLE_QTY,
    OAGREEMENT_QTY: el.AGREEMENT_QTY,
    price: "0.00",
    quantity: "",
    remark: "",
    EstimatedDate: Moment(new Date().setDate(new Date().getDate() + 7)).format(
      "MM/DD/YYYY"
    ),
  }));
  var orderFullfillHeader = {
    CustomerName: state.UserInfo.userInfo.CUSTOMER_NAME
      ? state.UserInfo.userInfo.CUSTOMER_NAME
      : "",
    Payment_Terms: state.UserInfo.userInfo.PAYMENT_TERM
      ? state.UserInfo.userInfo.PAYMENT_TERM
      : "",
    Ship_Companyname: "",
    Ship_AddressLine1: "",
    Ship_AddressLine2: "",
    Country: "",
    Postal_Code: "",
    ShipTo: "",
    Sales_Person: state.UserInfo.userInfo.SALESPERSON_NAME
      ? state.UserInfo.userInfo.SALESPERSON_NAME
      : "",
    EstimatedDt: Moment(new Date().setDate(new Date().getDate() + 7)).format(
      "MM/DD/YYYY"
    ),
    TAX_CODE_DESCRIPTION: state.UserInfo.userInfo.TAX_CODE_DESCRIPTION
      ? state.UserInfo.userInfo.TAX_CODE_DESCRIPTION
      : "",
    Bill_To: state.UserInfo.userInfo.ADDRESS
      ? state.UserInfo.userInfo.ADDRESS
      : "",
    Currency: state.UserInfo.userInfo.CURRENCY_CODE
      ? state.UserInfo.userInfo.CURRENCY_CODE
      : "",
    customerpoNum: "",
    HeaderRemark: "",
  };
  var ShipTo = getFormValues("OrderFulfillment")(state)
    ? getFormValues("OrderFulfillment")(state).ShipTo
    : null;
  if (ShipTo) {
    orderFullfillHeader = getFormValues("OrderFulfillment")(state);
  }
  return {
    initialValues: orderFullfillHeader,
    shipToLov: state.UserInfo.shipToLov,
    UserInfo: state.UserInfo.userInfo,
    InventoryInfo: arr,
    OriginalOrderInfo: arr,
    AddtoCartQuantity: state.OrderFulfillmentInfo.AddtoCartQuantity,
    selectedOrder: state.OrderFulfillmentInfo.selectedOrder,
    OrderheaderDet: orderFullfillHeader,
  };
};
export default compose(
  connect(
    mapStateToProps,
    {
      OrderFulfillment_Data,
      ADDTO_CART_QUANTITY,
      GET_USERINFO,
      SELECTED_ORDER,
      ORDER_HEADER_DETAILS,
    }
  ),
  reduxForm({
    form: "OrderFulfillment",
    enableReinitialize: true,
    destroyOnUnmount: false,
  }),
  withStyles(styles, { withTheme: true })
)(OrderFulfillment);
