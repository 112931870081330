import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Select, { components } from "react-select";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
export const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "absenceType",
    "absenceReason",
    "startDate",
    "endDate",
    "firstChild",
    "fschoolName",
    "feducationLevel",
    "famount",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};
export const renderTextField = (formProps) => {
  const { input, meta } = formProps;
  let isrtl = "ltr";
  return (
    <div dir={isrtl} style={{ width: "100%" }}>
      <TextField
        {...input}
        inputProps={{
          style: {
            padding: "10.5px 14px",
          },
        }}
        error={meta.touched && meta.error}
        label={formProps.placeholder}
        name={formProps.name}
        disabled={formProps.disabled}
        multiline={formProps.mline}
        rows={formProps.row}
        variant="outlined"
        {...formProps.formControlProps}
      />
    </div>
  );
};
export const renderLoginTextField = (formProps) => {
  const {
    input,
    meta,
    className,
    inputProps,
    InputProps,
    direction,
  } = formProps;
  return (
    <div dir={direction} style={{ width: "100%" }}>
      <TextField
        {...input}
        type={formProps.type}
        error={meta.touched && meta.error ? true : false}
        placeholder={formProps.placeholder}
        name={formProps.name}
        disabled={formProps.disabled}
        variant="outlined"
        {...formProps.formControlProps}
        className={className}
        InputProps={InputProps}
        inputProps={inputProps}
      />
      {meta.touched && meta.error ? (
        <div className="white mbtm-5 errormessagecolor">{meta.error}</div>
      ) : null}
    </div>
  );
};
export const renderSelectField = (formProps) => {
  const { input, options, placeholder, meta, rtl } = formProps;
  const customStyles = {
    control: (base, state, meta, input) => ({
      ...base,
      borderColor: "rgba(0, 0, 0, 0.23)",
      boxShadow: "none",
      height: 52,
      minHeight: 52,
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.23)",
        boxShadow: "none",
      },
    }),
  };
  return (
    <Select
      className="selectStyle"
      styles={customStyles}
      components={{ IndicatorsContainer }}
      {...input}
      placeholder={placeholder}
      onBlurResetsInput={false}
      onCloseResetsInput={false}
      blurInputOnSelect={false}
      onChange={(value) => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      options={options}
      onClick={() => formProps.onClick(input.value)}
      value={input.value}
      isRtl={rtl}
      isDisabled={formProps.disabled}
    />
  );
};
export const IndicatorsContainer = (props) => {
  return (
    <div className="arrowicon">
      <components.IndicatorsContainer {...props}>
        <components.DropdownIndicator {...props} className="padding4px">
          <ExpandMoreIcon />
        </components.DropdownIndicator>
      </components.IndicatorsContainer>
    </div>
  );
};
export const renderDatePicker = (formProps) => {
  const { input, meta } = formProps;
  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          keyboard
          fullWidth={true}
          disabled={formProps.disabled}
          inputVariant="outlined"
          variant="inline"
          margin="normal"
          id="date-picker-dialog"
          format="MM/dd/yyyy"
          views={["year", "month", "date"]}
          className={formProps.className}
          minDate={new Date()}
          error={meta.error && meta.touched}
          selected={input.value ? input.value : null}
          value={input.value ? input.value : null}
          onChange={(value) => input.onChange(value)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          autoOk={true}
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
};
export const DynamicTextField = (formProps) => {
  const { input, meta } = formProps;
  let isrtl = "ltr";
  return (
    <div dir={isrtl} style={{ width: "100%", marginBottom: "-4px" }}>
      <TextField
        inputProps={{
          style: {
            padding: "14.5px 14px",
          },
          min: "1",
        }}
        type="number"
        min={formProps.min}
        error={formProps.error}
        disabled={formProps.disabled}
        label={formProps.placeholder}
        name={formProps.name}
        value={formProps.value}
        disabled={formProps.disabled}
        helperText={formProps.helperText}
        rows={formProps.row}
        variant="outlined"
        onBlur={formProps.onBlur}
        onChange={formProps.onChange}
        onKeyPress={formProps.onKeypress}
      />
    </div>
  );
};
export const renderOrderTextField = (formProps) => {
  const {
    input,
    meta,
    className,
    inputProps,
    InputProps,
    direction,
  } = formProps;
  return (
    <TextField
      {...input}
      type={formProps.type}
      error={meta.touched && meta.error ? true : false}
      placeholder={formProps.placeholder}
      name={formProps.name}
      disabled={formProps.disabled}
      variant="outlined"
      fullWidth
      {...formProps.formControlProps}
      className={className}
      InputProps={InputProps}
      inputProps={inputProps}
    />
  );
};
