import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import PageviewIcon from "@material-ui/icons/Pageview";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Inventory_Data,
  Filter_InventoryData,
} from "../../../store/actions/index";
import { compose } from "redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoIcon from "@material-ui/icons/Info";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import withStyles from "@material-ui/core/styles/withStyles";

import { connect } from "react-redux";
import Scrollbar from "utils/components/Scrollbar";
import ReactSelect, { components } from "react-select";
import { GET_USERINFO } from "../../../store/actions/index";
import { IndicatorsContainer } from "../../../utils/components/InputComponent";
const customStyles = {
  control: (base, state, meta, input) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
    // width: "94%",
    height: 52,
    minHeight: 52,
    // overwrittes hover style
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      boxShadow: "none",
    },
  }),
};

const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "16.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
    },
  },
  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
});

class InventoryManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      emptyData: false,
      inventoryData: [],
      rowsPerPage: 5,
      isFixed: false,
      portalPlacement: "bottom",
      page: 0,
      ModelDialog: false,
      addtionalInfo: [],
      lovresult: [],
      selecteProductClass: null,
      ProductClassLov: [],
      selectedRowId: null,
    };
  }

  ProductClassLov = () => {
    api
      .get(apiConfig.ProductClassLov, { handlerEnabled: true })
      .then((res) => {
        let ProductClassLov = [{ value: "All", label: "All Product Class" }];
        res.data.map((data) => {
          ProductClassLov.push({
            value: data.PRODUCT_CLASS,
            label: data.PRODUCT_CLASS,
          });
        });
        this.setState({
          selecteProductClass: {
            value: ProductClassLov[0].value,
            label: ProductClassLov[0].label,
          },
          ProductClassLov: ProductClassLov,
        });
      })
      .catch((error) => {});
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleChange = (value) => {
    this.setState({ selecteProductClass: value });
    if (value.value === "All") {
      this.props.Inventory_Data();
    } else {
      var result = this.props.IventoryDataSearch.filter((objectval) =>
        Object.keys(objectval).some(
          (k) =>
            objectval[k] != null &&
            objectval[k]
              .toString()
              .toLowerCase()
              .includes(value.label.toLowerCase())
        )
      );
      this.setState({ lovresult: result });
      this.props.Filter_InventoryData(result);
    }
  };
  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  SearchProduct = (filterText) => {
    if (
      this.state.selecteProductClass
        ? this.state.selecteProductClass.label
        : null
    ) {
      if (
        this.state.selecteProductClass
          ? this.state.selecteProductClass.value === "All"
            ? "All"
            : null
          : null
      ) {
        if (filterText) {
          var result = this.props.IventoryDataSearch.filter((objectval) =>
            Object.keys(objectval).some(
              (k) =>
                objectval[k] != null &&
                objectval[k]
                  .toString()
                  .toLowerCase()
                  .includes(filterText.toLowerCase())
            )
          );
          this.props.Filter_InventoryData(result);
        } else {
          this.props.Inventory_Data();
        }
      } else {
        if (filterText) {
          var result = this.state.lovresult.filter((objectval) =>
            Object.keys(objectval).some(
              (k) =>
                objectval[k] != null &&
                objectval[k]
                  .toString()
                  .toLowerCase()
                  .includes(filterText.toLowerCase())
            )
          );
          this.props.Filter_InventoryData(result);
        } else {
          this.props.Filter_InventoryData(this.state.lovresult);
        }
      }
    } else {
      if (filterText) {
        var result = this.props.IventoryDataSearch.filter((objectval) =>
          Object.keys(objectval).some(
            (k) =>
              objectval[k] != null &&
              objectval[k]
                .toString()
                .toLowerCase()
                .includes(filterText.toLowerCase())
          )
        );
        this.props.Filter_InventoryData(result);
      } else {
        this.props.Inventory_Data();
      }
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.UserInfo.CONTACT_EMAIL) {
      api
        .get(apiConfig.UserInfo, { handlerEnabled: true })
        .then((res) => {
          this.props.GET_USERINFO(res.data[0]);
        })
        .catch((error) => {});
    }
    this.ProductClassLov();
    this.props.Inventory_Data();
  }
  componentDidUpdate(prevProps) {
    var filteredtableRow;
    if (
      this.props.InventoryInfo &&
      this.props.InventoryInfo !== prevProps.InventoryInfo
    ) {
      if (this.props.InventoryInfo.length > 0) {
        const tableRow = [
          "Product Code",
          "Customer Product Code",
          "Product Description",
          "Product Class",
          "UOM",
          "Location",
          <div style={{ textAlign: "right" }}>Agreement Quantity</div>,
          <div style={{ textAlign: "right" }}>Available Quantity</div>,
          <div style={{ textAlign: "right" }}>
            Selling Price({this.props.UserInfo.CURRENCY_CODE})
          </div>,
          <InfoIcon style={{ textAlign: "center" }} />,
          <AttachFileIcon style={{ textAlign: "center" }} />,
        ];
        if (this.props.UserInfo.AGREEMENT === "N" ? true : false) {
          filteredtableRow = [
            "Product Code",
            "Customer Product Code",
            "Product Description",
            "Product Class",
            "UOM",
            "Location",
            <div style={{ textAlign: "right" }}>Available Quantity</div>,
            <div style={{ textAlign: "right" }}>
              Selling Price({this.props.UserInfo.CURRENCY_CODE})
            </div>,
            <InfoIcon style={{ textAlign: "center" }} />,
            <AttachFileIcon style={{ textAlign: "center" }} />,
          ];
        } else {
          filteredtableRow = tableRow;
        }
        this.setState(
          { spinner: false, filteredtableRow: filteredtableRow },
          () => {
            this.setState({ emptyData: false });
          }
        );
      } else {
        this.setState({ spinner: false }, () => {
          this.setState({ emptyData: true });
        });
      }
    }
  }

  handleClose = () => {
    this.setState({ ModelDialog: false });
  };

  download = (base64, fileName) => {
    const downloadLink = document.createElement("a");

    downloadLink.href = base64;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  inventoryImage = (inventory_item_id) => {
    this.setState({ downloadspinner: true });
    api
      .post(
        apiConfig.inventoryImage,
        { inventory_item_id },
        { handlerEnabled: true }
      )
      .then((res) => {
        this.setState({ downloadspinner: false });
        this.download(
          "data:" +
            res.data.FILE_CONTENT_TYPE +
            ";base64," +
            res.data.FILE_DATA,
          res.data.FILE_NAME
        );
      })
      .catch((error) => {});
  };

  onAddtionalInformation = (addtionalInfo) => {
    var additionalArray;
    if (addtionalInfo) {
      additionalArray = addtionalInfo.split("|");
    }
    this.setState({ ModelDialog: true, addtionalInfo: additionalArray });
  };
  render() {
    var agreement = this.props.UserInfo
      ? this.props.UserInfo.AGREEMENT === "N"
        ? true
        : false
      : "";
    const { classes } = this.props;
    return (
      <Fragment>
        {this.state.spinner ? (
          <div className="spinner">
            <CircularProgress className="innerspinner" />
          </div>
        ) : null}
        <Dialog
          open={this.state.ModelDialog}
          onClose={this.handleClose}
          keepMounted
          onClick={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" onClose={this.handleClose}>
            <div className="additionalInfoTitle">Additional Information</div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="dialogcontent">
              {this.state.addtionalInfo.map((item) => {
                return <div className="additionalInfoItems">{item}</div>;
              })}
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GridContainer className="ordertopbar">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selecteProductClass}
                      onChange={this.handleChange}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.ProductClassLov}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} className="mbtm-10">
                    <TextField
                      placeholder="Search for Products"
                      variant="outlined"
                      onChange={(event) => {
                        this.SearchProduct(event.target.value);
                      }}
                      className={classes.materialinput}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PageviewIcon className="search_icon" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer className="ordertable">
          <GridItem xs={12}>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {this.state.emptyData ? (
                  <div className="nodatafound">No Data Found</div>
                ) : null}
                {this.state.spinner === false &&
                this.state.emptyData === false ? (
                  <Table>
                    {this.state.downloadspinner ? (
                      <div className="spinner">
                        <CircularProgress className="innerspinner" />
                      </div>
                    ) : null}
                    <TableHead>
                      <TableRow>
                        {this.state.filteredtableRow.map((data, i) => (
                          <TableCell className="bold tablePadding" key={i}>
                            {data}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow></TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.InventoryInfo.slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      ).map((data, i) => {
                        return (
                          <TableRow
                            hover
                            onClick={() => {
                              this.setState({ selectedRowId: i });
                            }}
                            selected={this.state.selectedRowId === i}
                            key={i}
                            classes={{
                              hover: classes.hover,
                              selected: classes.selected,
                            }}
                            className={classes.tableRow}
                          >
                            <TableCell
                              width="15%"
                              className="tablePadding tablecell"
                            >
                              {data.PRODUCT_CODE}
                            </TableCell>
                            <TableCell
                              width="20%"
                              className="tablePadding tablecell"
                            >
                              {data.CUSTOMER_PRODUCT_CODE}
                            </TableCell>
                            <TableCell width="40%" className="tablePadding">
                              {data.PRODUCT_DESCRIPTION}
                            </TableCell>
                            <TableCell width="10%" className="tablePadding">
                              {data.PRODUCT_CLASS}
                            </TableCell>
                            <TableCell className="tablePadding">
                              {data.PRIMARY_UOM_CODE}
                            </TableCell>
                            <TableCell width="15%" className="tablePadding">
                              {data.LOCATION}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={`tablePadding ${
                                agreement ? "AgreementQuantity" : ""
                              }`}
                            >
                              {data.AGREEMENT_QTY}
                            </TableCell>
                            <TableCell align="right" className="tablePadding">
                              {data.AVAILABLE_QTY}
                            </TableCell>
                            <TableCell align="right" className="tablePadding">
                              {data.SELLING_PRICE}
                            </TableCell>
                            <TableCell className="tablePadding" align="center">
                              {" "}
                              <div
                                onClick={() => {
                                  this.onAddtionalInformation(
                                    data.ADDITIONAL_INFO
                                  );
                                }}
                              >
                                <InfoIcon className="infoicon" />
                              </div>
                            </TableCell>
                            <TableCell
                              className={`tablePadding ${
                                data.FILE_NAME == null
                                  ? "AgreementQuantity"
                                  : ""
                              }`}
                              align="center"
                            >
                              <div
                                onClick={() => {
                                  this.inventoryImage(data.INVENTORY_ITEM_ID);
                                }}
                              >
                                <AttachFileIcon className="attachicon" />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          count={this.props.InventoryInfo.length}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserInfo: state.UserInfo.userInfo,
    Rtl: state.util.Rtl,
    InventoryInfo: state.InventoryInfo.IventoryData,
    IventoryDataSearch: state.InventoryInfo.IventoryDataSearch,
  };
};
export default compose(
  connect(
    mapStateToProps,
    { Inventory_Data, Filter_InventoryData, GET_USERINFO }
  ),
  withStyles(styles, { withTheme: true })
)(InventoryManagement);
