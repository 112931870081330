import React, { Component, Fragment } from "react";
import ExcelDownload from "./ExcelDownload";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from "@material-ui/icons/GetApp";
import { apiConfig } from "../../config/api";
import api from "../../config/apiconfig";
class PaymentHistory extends Component {
  responseData = [
    {
      columns: [
        {
          title: "Customer Number",
          width: { wch: 17 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Customer Name",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Sales Person Name",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "SO Number",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Customer PO Number",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Invoice Number",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Currency",
          width: { wch: 8 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Invoice Amount",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Invoice Due Date",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Receipt Number",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Receipt Date",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Receipt Amount",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Applied Amount",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Balance Amount",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
      ],
      data: [],
    },
  ];

  constructor(props) {
    super(props);
    this.state = { showExcel: false, spinner: false };
  }

  showExcelComponent() {
    this.setState({ spinner: true });
    this.responseData[0].data = [];
    api
      .post(
        apiConfig.paymentHistory,
        { from_date: this.props.fromDate, to_date: this.props.toDate },
        { handlerEnabled: true }
      )
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((element) => {
            var array = [];
            array.push({
              value:
                element.CUSTOMER_NUMBER !== null ? element.CUSTOMER_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.CUSTOMER_NAME !== null ? element.CUSTOMER_NAME : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.SALESPERSON_NAME !== null
                  ? element.SALESPERSON_NAME
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.SO_NUMBER !== null ? element.SO_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.CUST_PO_NUMBER !== null ? element.CUST_PO_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.INVOICE_NUMBER !== null ? element.INVOICE_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.CURRENCY !== null ? element.CURRENCY : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.INVOICE_AMOUNT !== null ? element.INVOICE_AMOUNT : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.INVOICE_DUE_DATE !== null
                  ? element.INVOICE_DUE_DATE
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.RECEIPT_NUMBER !== null ? element.RECEIPT_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.RECEIPT_DATE !== null ? element.RECEIPT_DATE : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.RECEIPT_AMOUNT !== null ? element.RECEIPT_AMOUNT : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.APPLIED_AMOUNT !== null ? element.APPLIED_AMOUNT : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.BALANCE_AMOUNT !== null ? element.BALANCE_AMOUNT : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            this.responseData[0].data.push(array);
          });
          this.setState({ showExcel: true, spinner: false });
          setTimeout(() => {
            this.setState({ showExcel: false });
          }, 3000);
        } else {
          this.setState({ spinner: false });
          this.props.showerror();
        }
      });
  }

  render() {
    return (
      <Fragment>
        <div>
          {this.state.spinner ? null : (
            <GetAppIcon
              className="attachicon"
              onClick={() => this.showExcelComponent()}
            />
          )}
          {this.state.spinner ? (
            <CircularProgress
              className="innerspinner"
              style={{ height: "24px", width: "24px" }}
            />
          ) : null}
          {this.state.showExcel ? (
            <ExcelDownload
              data={this.responseData}
              excelName="Payment History"
            ></ExcelDownload>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default PaymentHistory;
