export default {
  login:{
    "userName" : "UserName",
    "password" : "Password",
    "selectLanguage ": "Select Language",
    "login" : "Login",
    "english" : "English",
    "arabic" : "عربى",
    "forogotPassword" : "Forgot Password"
  },
  leave:{
    table:{
      "absenceType":"Absence Type",
      "absenceCategory":"Absence Category",
      "absenceStatus":"Absence Status",
      "startDate":"Start Date",
      "endDate":"End Date",
      "addLeave":"Add Leave"
    },
    add:{
      "absenceReason":"Absence Reason",
      "attachment":"Attachment",
      "calculateDays":"Calculate Days",
      "comments":"Comments",
      "note":"Note",
      "duration":"Duration",
      "detail":"Always Select an Absence Type and enter any other information you want to create your request. You can choose the Calculation duration button to choose the number of days or hour you are requesting"
    },
    button:{
      "submit":"Submit",
      "cancel":"Cancel"
    }
  },
  salary:{
    "numberofWeeks":"Number of Weeks",
    "comments":"Comments",
    "attachment":"Attachment",
    "status":"Status",
    "new":"New",
    "submit":"Submit",
    "cancel":"Cancel"
  },
  allowance:{
    "name":"Child Name",
    "school":"School Name",
    "education":"Education Level",
    "startDate":"Start Date",
    "amount":"Amount",
    "new":"New",
    add:{
      "startDate":"Start Date",
    },
    button:{
      "submit":"Submit",
      "cancel":"Cancel"
    }
  },
  personalInfo:{
    "basicDetail":"Basic Detail",
    "edit":"Edit",
    "new":"New",
    "fullName":"Full Name",
    "mStatus":"Married",
    "dob":"Date of Birth",
    "civilIdentifier":"Civil Identifier",
    "empNumber":"Employee Number",
    "orgEmpAddress":"Organisation Employee Address",
    "mainAddress":"Main Address",
    "emeContacts":"Emergency Contacts",
    "depBen":"Dependent And Beneficiary",
    address:{
      "locationAdd":"Location Address",
      "governorate":"Governorate",
      "poboxNo":"Post Box Number",
      "street":"Street",
      "laneRoad":"Lane / Road",
      "buildApment":"Building / Apartment",
      "pobox":"PO Box",
      "areaName":"Area Name",
      "areaNameArb":"Area Name in Arabic",
      "areaCode":"Area Code",
      "country":"Country",
      "laneNo":"Lane Number",
      "email":"Email",
      "phoneExt":"Phone Extension",
      "atDest":"Air Ticket Destination"
    },
    emeCont:{
      "name":"Name",
      "relationship":"Relationship",
      "pContact":"Primary Contact"
    },
    dep:{
      "name":"Name",
      "relationship":"Relationship",
      "title":"Title",
      "firstName": "First Name",
      "familyName": "Family Name",
      "rlnStartDate":"Relationship Start Date",
      "useMyAddforPer":"Use my address for this person",
      "addDepBenInfo":"Additional Dependent and Beneficiary Information",
      "phNumb":"Phone Numbers",
      "type":"Type",
      "number":"Number",
      "attachment":"Attachment",
      button:{
        "addNumb":"Add Number",
        "submit":"Submit",
        "cancel":"Cancel"
      }
    },
    modal:{
      "basicDetail":"Basic Details (Choose Option)",
      "select":"Select the type of change you want to make.",
      "correct":"Correct or complete the current details.",
      "new":"Enter new information because of a real change to the current details (e.g. because of a change in marital status)"
    },
    editPage:{
      "ename":"Employee Name",
      "oempadd":"Organisation Employee Address",
      "name":"Name",
      "effDate":"Effective Date",
      "title":"Title",
      "firstName": "First Name",
      "familyName": "Family Name",
      "addInfo":"Additional Information",
      "empNo":"Employee Number",
      "civilIdent":"Civil Identifier",
      "dob":"Date of Birth",
      "gender":"Gender",
      "marStatus":"Marital Status",
      "nation":"Nationality",
      "attachment":"Attachment",
      button:{
        "submit":"Submit",
        "cancel":"Cancel"
      }
    }
  }
}