import React, { Fragment } from "react";
import OrderView from "./OrderView";
import OrderTopbar from "./OrderTopbar";
const Order = () => {
  return (
    <Fragment>
      <OrderTopbar />
      <OrderView />
    </Fragment>
  );
};
export default Order;
