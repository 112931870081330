import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import Moment from "moment";
import { compose } from "redux";
import { IndicatorsContainer } from "../../../utils/components/InputComponent";
import ReactSelect, { components } from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Symbols,
  Surface,
  LabelList,
  Label,
  ResponsiveContainer,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { stubFalse } from "lodash";
const colors = scaleOrdinal(schemeCategory10).range();
const customStyles = {
  control: (base, state, meta, input) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
    // width: "94%",
    height: 52,
    minHeight: 52,
    // overwrittes hover style
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      boxShadow: "none",
    },
  }),
};

const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "16.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
    },
  },
  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
});

const columns = [
  {
    id: "COMPANY_CODE",
    label: "Company Code",
    align: "left",
    minWidth: 100,
  },
  {
    id: "CUSTOMER_GROUP",
    label: "Company Group",
    align: "left",
    minWidth: 100,
  },
  {
    id: "CUSTOMER_NAME",
    label: "Customer Name",
    align: "left",
    minWidth: 100,
  },
  {
    id: "SALESPERSON_NAME",
    label: "Sales Person",
    align: "left",
    minWidth: 100,
  },
  {
    id: "DAYS_OVERDUE",
    label: "Days OverDue",
    align: "left",
    minWidth: 100,
  },
  {
    id: "ORIGINAL_BALANCE",
    label: "Original Balance",
    align: "right",
    minWidth: 100,
  },
  {
    id: "OUTSTANDING_BALANCE",
    label: "OutStanding Balance",
    align: "right",
    minWidth: 100,
  },
  {
    id: "AVG_DAYS_TO_PAY",
    label: "Avg Days To Pay",
    align: "left",
    minWidth: 100,
  },
  {
    id: "INVOICE_DATE",
    label: "Invoice Date",
    align: "left",
    minWidth: 100,
  },
  {
    id: "DUE_DATE",
    label: "Due Date",
    align: "left",
    minWidth: 100,
  },
];
class ARreports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      shipToLov: [],
      emptyData: false,
      inventoryData: [],
      rowsPerPage: 5,
      page: 0,
      InventoryInfo: [],
      selectedfilter: {},
      shipToLov: [],
      ModelDialog: false,
      selectedorder: [],
      Uomerror: false,
      error: false,
      filteredOrderColumn: [],
      OverdueOptions: [],
      overDueReceivables: 0.0,
      outStandingReceivables: 0.0,
      AOverdue: [],
      AFuture: [],
      selectedDate: [{ value: "All", label: "All" }],
      isLoading: false,
      Alt_addressform: {},
      open: true,
      selectedRowId: null,
      OrderDate: null,
      outStandingDetails: [],
    };
  }

  componentDidMount() {
    var OverdueOptions = [
      { value: "All", label: "All" },
      { value: "0", label: "0" },
      { value: "0-15", label: "0-15" },
      { value: "16-30", label: "16-30" },
      { value: "31-60", label: "31-60" },
      { value: "61-90", label: "61-90" },
      { value: ">=91", label: ">=91" },
    ];
    this.setState({ OverdueOptions: OverdueOptions });
    this.getARApi();
    this.getOutstandingApi(this.state.selectedDate);
  }
  getOutstandingApi = (payload) => {
    let all = "N";
    let zero = "N";
    let oneto15 = "N";
    let sixteento30 = "N";
    let thirty1to60 = "N";
    let sixty1to90 = "N";
    let gt90 = "N";
    payload.map((data) => {
      if (data.value === "All") {
        all = "Y";
      } else if (data.value === "0-15") {
        oneto15 = "Y";
      } else if (data.value === "16-30") {
        sixteento30 = "Y";
      } else if (data.value === "31-60") {
        thirty1to60 = "Y";
      } else if (data.value === "61-90") {
        sixty1to90 = "Y";
      } else if (data.value === ">=91") {
        gt90 = "Y";
      }
    });
    this.setState({ spinner: true });

    api
      .post(
        apiConfig.CutomerOutStanding,
        {
          all: all,
          zero: zero,
          oneto15: oneto15,
          sixteento30: sixteento30,
          thirty1to60: thirty1to60,
          sixty1to90: sixty1to90,
          gt90: gt90,
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        this.setState({ spinner: false });
        this.setState({ outStandingDetails: res.data });
      })
      .catch((error) => {});
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  getARApi = () => {
    let AOverdue = [];
    var AFuture = [];
    api
      .get(apiConfig.AOverdue, { handlerEnabled: true })
      .then((res) => {
        res.data.map((data) => {
          this.setState({ spinner: false });
          AOverdue.push({
            DAYS: data.DAYS,
            OUTSTANDING_BALANCE: parseFloat(
              data.OUTSTANDING_BALANCE.toFixed(2)
            ),
          });
        });
        this.setState({ AOverdue: AOverdue, OAOverdue: AOverdue });
      })
      .catch((error) => {});

    api
      .get(apiConfig.AFuture, { handlerEnabled: true })
      .then((res) => {
        res.data.map((data, i) => {
          this.setState({ spinner: false });
          AFuture.push({
            DAYS: data.DAYS,
            OUTSTANDING_BALANCE: parseFloat(
              data.OUTSTANDING_BALANCE.toFixed(2)
            ),
          });
        });
        this.setState({ AFuture: AFuture, OAFuture: AFuture });
      })
      .catch((error) => {});
  };
  handleChangeOverdueDat = (value) => {
    var AOverdue = [];
    var AFuture = [];

    this.setState({ selectedDate: value });
    if (value != null) {
      this.getOutstandingApi(value);

      if (value[0].value === "All") {
        this.setState({
          AOverdue: this.state.OAOverdue,
          AFuture: this.state.OAFuture,
        });
      } else {
        value.map((data, i) => {
          this.state.OAOverdue.map((overdue, i) => {
            if (overdue.DAYS === data.value) {
              AOverdue.push(overdue);
            }
          });
          this.state.OAFuture.map((overdue, i) => {
            if (overdue.DAYS === data.value) {
              AFuture.push(overdue);
            }
          });
        });
        this.setState({ AOverdue: AOverdue, AFuture: AFuture });
      }
    } else {
      this.setState({
        AOverdue: this.state.OAOverdue,
        AFuture: this.state.OAFuture,
      });
    }
  };
  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip tooltipbg">
          <p className="label" style={{ color: "black" }}>
            {label}
          </p>
          <p className="label" style={{ color: "black" }}>{`${
            payload[0].name
          } : ${parseFloat(payload[0].value)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</p>
        </div>
      );
    }
    return null;
  };

  getOverDuetotal = () => {
    var overDueReceivables = 0.0;
    this.state.AOverdue.map((data, i) => {
      overDueReceivables += data.OUTSTANDING_BALANCE;
    });
    return overDueReceivables;
  };
  getOutStandingtotal = () => {
    var outStandingReceivables = 0.0;
    this.state.AFuture.map((data, i) => {
      outStandingReceivables += data.OUTSTANDING_BALANCE;
    });
    return outStandingReceivables;
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GridContainer className="ordertopbar">
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">
                      {Moment(new Date()).format("MM/DD/YYYY")}
                    </InputLabel>
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor mbtm-10">
                      Overdue Receivables
                    </InputLabel>
                    <InputLabel style={{ color: "red" }}>
                      {this.getOverDuetotal()
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                    </InputLabel>
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={3}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor mbtm-10">
                      Outstanding Receivables
                    </InputLabel>
                    <InputLabel style={{ color: "green" }}>
                      {this.getOutStandingtotal()
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                    </InputLabel>
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">
                      Overdue (Days)
                    </InputLabel>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <ReactSelect
                      value={this.state.selectedDate}
                      onChange={this.handleChangeOverdueDat}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      isMulti
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.OverdueOptions}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer className="arContainer">
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div className="grapheader">Overdue Receivables</div>
                  {this.state.spinner === false ? (
                    this.state.AOverdue.length === 0 ? (
                      <div className="nodatafound">No Data Found</div>
                    ) : (
                      <BarChart
                        width={500}
                        height={300}
                        maxBarSize={100}
                        data={this.state.AOverdue}
                        // margin={{
                        //   top: 5, right: 30, left: 20, bottom: 5,
                        // }}
                        style={{ margin: "auto" }}
                        className="barchart"
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="DAYS" />
                        <YAxis />
                        <Tooltip content={this.CustomTooltip} />
                        {/* <Legend /> */}
                        <Bar
                          dataKey="OUTSTANDING_BALANCE"
                          fill="#ee916c"
                          background={{ fill: "#eee" }}
                          isAnimationActive={false}
                        >
                          <LabelList
                            dataKey="OUTSTANDING_BALANCE"
                            position="top"
                          />
                        </Bar>
                      </BarChart>
                    )
                  ) : (
                    <div className="spinner">
                      <CircularProgress className="innerspinner" />
                    </div>
                  )}
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  {/* <Card>
              <CardBody> */}
                  <div className="grapheader">Future Due In Receivables</div>
                  {this.state.spinner === false ? (
                    this.state.AOverdue.length === 0 ? (
                      <div className="nodatafound">No Data Found</div>
                    ) : (
                      <BarChart
                        width={500}
                        height={300}
                        data={this.state.AFuture}
                        maxBarSize={100}
                        // margin={{
                        //   top: 5, right: 30, left: 20, bottom: 5,
                        // }}
                        style={{ margin: "auto" }}
                        className="barchart"
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="DAYS" />
                        <YAxis />
                        <Tooltip content={this.CustomTooltip} />
                        {/* <Legend /> */}
                        <Bar
                          dataKey="OUTSTANDING_BALANCE"
                          fill="#ABEA80"
                          background={{ fill: "#eee" }}
                          isAnimationActive={false}
                        >
                          <LabelList
                            dataKey="OUTSTANDING_BALANCE"
                            position="top"
                          />
                        </Bar>
                      </BarChart>
                    )
                  ) : (
                    <div className="spinner">
                      <CircularProgress className="innerspinner" />
                    </div>
                  )}

                  {/* </CardBody></Card> */}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridContainer>
        <GridContainer style={{ marginTop: "-43px" }}>
          <GridItem xs={12}>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {this.state.spinner === false ? (
                  this.state.outStandingDetails.length === 0 ? (
                    <div className="nodatafound">No Data Found</div>
                  ) : (
                    <Table className="orderFulfillment">
                      <TableHead>
                        <TableRow hover>
                          {columns.map((column) => (
                            <TableCell
                              className="bold tablePadding"
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.outStandingDetails
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((data, i) => {
                            return (
                              <TableRow
                                hover
                                onClick={() => {
                                  this.setState({ selectedRowId: i });
                                }}
                                selected={this.state.selectedRowId === i}
                                key={i}
                                classes={{
                                  hover: classes.hover,
                                  selected: classes.selected,
                                }}
                                className={classes.tableRow}
                              >
                                <TableCell className="tablePadding tablecell">
                                  {data.COMPANY_CODE}
                                </TableCell>
                                <TableCell className="tablePadding tablecell">
                                  {data.CUSTOMER_GROUP}
                                </TableCell>
                                <TableCell className="tablePadding tablecell">
                                  {data.CUSTOMER_NAME}
                                </TableCell>
                                <TableCell className="tablePadding tablecell">
                                  {data.SALESPERSON_NAME}
                                </TableCell>
                                <TableCell className="tablePadding tablecell">
                                  {data.DAYS_OVERDUE}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding tablecell"
                                >
                                  {parseFloat(data.ORIGINAL_BALANCE)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="tablePadding tablecell"
                                >
                                  {parseFloat(data.OUTSTANDING_BALANCE)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </TableCell>
                                <TableCell className="tablePadding tablecell">
                                  {data.AVG_DAYS_TO_PAY}
                                </TableCell>
                                <TableCell className="tablePadding tablecell">
                                  {Moment(data.INVOICE_DATE).format(
                                    "MM/DD/YYYY"
                                  )}
                                </TableCell>
                                <TableCell className="tablePadding tablecell">
                                  {Moment(data.DUE_DATE).format("MM/DD/YYYY")}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={this.state.outStandingDetails.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            SelectProps={{
                              inputProps: { "aria-label": "rows per page" },
                              native: true,
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  )
                ) : (
                  <div className="spinner">
                    <CircularProgress className="innerspinner" />
                  </div>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(ARreports);
