const INITIAL_STATE = {
  IventoryData: [],
  IventoryDataSearch: [],
  IventorySaleData: [],
  IventorySaleDataSearch: [],
  username: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "USERNAME":
      return {
        ...state,
        username: action.payload,
      };

    case "INVENTORY_DATA":
      return {
        ...state,
        IventoryData: action.payload,
      };
    case "INVENTORY_DATA_SEARCH":
      return {
        ...state,
        IventoryDataSearch: action.payload,
      };
    case "INVENTORYSALE_DATA":
      return {
        ...state,
        IventorySaleData: action.payload,
      };
    case "INVENTORYSALE_DATA_SEARCH":
      return {
        ...state,
        IventorySaleDataSearch: action.payload,
      };

    default:
      return state;
  }
};
