export const apiConfig = {

  // baseURL: "http://140.238.225.85/ats/api",
  baseURL: "https://vmi.atssh.com/ats/api",
  Login: "/auth",
  ResetPasswordMail: "/auth/emailresetpasswd",
  ResetPassword: "/auth/resetpassword",
  RefreshToken: "/auth/token",
  AccessDetails: "/myaccount/accessdetails",
  ChangePassword: "/myaccount/changepassword",
  Devices: "/myaccount/devicedetails",
  UserInfo: "/myaccount/userinfo",
  signout: "/auth/signout",
  orderFulfillment: "/inventory/orderfulfilment",
  Inventory: "/inventory",
  InventorySalesperson: "/inventory/salesperson",
  ProductClassLov: "/inventory/productclasslov",
  CompanyLov: "/inventory/companylov",
  ShipToLOV: "/customer/shipto",
  Order: "/order",
  MyOrders: "/order/history",
  OrderUtilization: "/dashboard/orderutilization",
  OrderYTDOverview: "/dashboard/orderytdoverview",
  OrderVolume: "/dashboard/ordervolume",
  OrderWarehouse: "/order/warehouse",
  PurchaseSubType: "/dashboard/purchasesubtype",
  CustomerNames:"/dashboard/customernameforsalesperson",
  PurchaseBy: "/dashboard/purchasebynew",
  altshiptocountry: "/customer/altshiptocountry",
  AddDispute: "/dashboard/itemdispute",
  GetDispute: "/dashboard/itemdispute",
  AOverdue: "/dashboard/aroverdue",
  AFuture: "/dashboard/arfuture",
  AgreedValue: "/dashboard/agreedvalue",
  ActualValue: "/dashboard/actualvalue",
  AgreedActual: "/dashboard/agreedactualtable",
  CutomerOutStanding: "/dashboard/custoutstanding",
  UpdateDispute: "/dashboard/updateitemdispute",
  UOMConversion: "/inventory/uomconversionlist",
  TotalPurchase: "/reports/totalpurchasevalue",
  PurchaseByQtr: "/reports/pvbyquater",
  PurchaseByShipTo: "/reports/pvbyshipto",
  PurchaseByBrand: "/reports/pvbybrand",
  PurchaseByProdClass: "/reports/pvbyproductclass",
  PurchaseQByBrand: "/reports/pqbybrand",
  PurchaseQByProdClass: "/reports/pqbyproductclass",
  StockUtil: "/reports/stockutilization",
  StockUtilTotal: "/reports/stockutilizationtotal",
  inventoryImage: "/inventory/itemimage",
  inventoryImageSales: "/inventory/itemimagesales",
  historicalOrderDetailsReport: "/reports/historicalordersdetail",
  historicalOrderSummaryReport: "/reports/historicalorderssummary",
  inventoryOnHandReport: "/reports/inventoryonhand",
  openOrdersDetailReport: "/reports/openordersdetail",
  openOrdersSummaryReport: "/reports/openorderssummary",
  customerInventoryManagement: "/reports/customerinvemtorymgmt",
  reportsAccess: "/reports/access",
  reportsProductClassLOV: "/reports/productclasslov",
  oracleReports: "/reports/oracle",
  paymentHistory: "/reports/paymenthistory",
  outstandingAR: "/reports/outstandingAR",
  orderDocuments: "/order/documents",
  orderDocumentUpload: "/order/documentupload",
  loggingRemovedItems: "/logging/removedfromcart",
  removedocument: "/order/removedocument"
}