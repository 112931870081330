const INITIAL_STATE = { userInfo: [], shipToLov: [] };
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "USERINFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "SHIP_TO_LOV":
      return {
        ...state,
        shipToLov: action.payload,
      };

    default:
      return state;
  }
};
