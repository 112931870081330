import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { compose } from "redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import _ from "lodash";
class PurchaseView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 5,
      page: 0,
      selectedRowId: null,
      selectedSubtypeArray: [],
    };
  }
  quarter = ["Q1", "Q2", "Q3", "Q4"];
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  getTableHeader() {
    if (this.props.Period === "YEARLY") {
      return (
        <TableHead>
          <TableRow>
            <TableCell className="bold tablePadding" key={1}>
              {this.props.headervalues}
            </TableCell>
            {this.props.yearOptions.map((data, i) => {
              if (this.checkSelectedYear(data)) {
                return (
                  <TableCell
                    className="bold tablePadding"
                    key={i + 1}
                    align="right"
                  >
                    {data}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
      );
    } else {
      return (
        <TableHead>
          <TableRow>
            <TableCell className="bold tablePadding" key={1}>
              {this.props.headervalues}
            </TableCell>
            {this.props.yearOptions.map((data, i) => {
              if (this.checkSelectedYear(data)) {
                return (
                  <TableCell
                    colSpan={4}
                    align="center"
                    className="bold tablePadding"
                    key={i + 1}
                  >
                    {data}
                  </TableCell>
                );
              }
            })}
          </TableRow>
          <TableRow>
            <TableCell className="bold tablePadding" key={1}></TableCell>
            {this.quarter.map((data, i) => {
              if (this.checkSelectedYear(data)) {
                return (
                  <TableCell
                    className="bold tablePadding"
                    key={i + 1}
                    align="right"
                  >
                    {data}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
      );
    }
  }
  getValue(type, yearData) {
    var subType = this.props.headervalues.replace(" ", "_").toUpperCase();
    let Notfound = true;
    for (const value of Object.entries(this.props.PurchaseData)) {
      let innerValue = value[1];
      if (innerValue[subType] === type && innerValue.YEAR === yearData) {
        Notfound = false;
        return parseFloat(innerValue.INVOICE_AMOUNT)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
      continue;
    }
    if (Notfound) {
      return "0.00";
    }
  }

  getQuarterValue(type, yearData, quarter) {
    var subType = this.props.headervalues.replace(" ", "_").toUpperCase();
    let Notfound = true;
    for (const value of Object.entries(this.props.PurchaseData)) {
      let innerValue = value[1];
      if (
        innerValue[subType] === type &&
        innerValue.YEAR === yearData &&
        innerValue.QUARTER === quarter
      ) {
        Notfound = false;
        return parseFloat(innerValue.INVOICE_AMOUNT)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
      continue;
    }
    if (Notfound) {
      return "0.00";
    }
  }

  checkSelectedSubType(data) {
    let subtypeString = JSON.stringify(this.props.selectedSubType);
    return subtypeString.includes("All") || subtypeString.includes(data.value);
  }

  checkSelectedYear(data) {
    return (
      this.props.selectedYear.value === "All" ||
      this.props.selectedYear.value === data
    );
  }

  getTableBody() {
    if (this.props.Period === "YEARLY") {
      return (
        <TableBody>
          {this.props.subType.map((data, i) => {
            if (data.value !== "All") {
              if (this.checkSelectedSubType(data)) {
                return (
                  <TableRow>
                    <TableCell className="tablePadding tablecell">
                      {data.value}
                    </TableCell>
                    {this.props.yearOptions.map((yearData) => {
                      if (this.checkSelectedYear(yearData)) {
                        return (
                          <TableCell
                            className="tablePadding tablecell"
                            align="right"
                          >
                            {this.getValue(data.value, yearData)}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              }
            }
          })}
        </TableBody>
      );
    } else {
      return (
        <TableBody>
          {this.props.subType.map((data, i) => {
            if (data.value !== "All") {
              if (this.checkSelectedSubType(data)) {
                return (
                  <TableRow>
                    <TableCell className="tablePadding tablecell">
                      {data.value}
                    </TableCell>
                    {this.props.yearOptions.map((yearData) => {
                      if (this.checkSelectedYear(yearData)) {
                        return this.quarter.map((quarter) => {
                          return (
                            <TableCell
                              className="tablePadding tablecell"
                              align="right"
                              key={quarter + yearData}
                            >
                              {this.getQuarterValue(
                                data.value,
                                yearData,
                                quarter
                              )}
                            </TableCell>
                          );
                        });
                      }
                    })}
                  </TableRow>
                );
              }
            }
          })}
        </TableBody>
      );
    }
  }

  getYearTotal(year) {
    let total = 0;
    for (const value of Object.entries(this.props.PurchaseData)) {
      let innerValue = value[1];
      if (innerValue.YEAR === year) {
        total = total + parseFloat(innerValue.INVOICE_AMOUNT);
      }
    }
    return parseFloat(total)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  getQuarterTotal(quarter, year) {
    let total = 0;
    for (const value of Object.entries(this.props.PurchaseData)) {
      let innerValue = value[1];
      if (innerValue.YEAR === year && innerValue.QUARTER == quarter) {
        total = total + parseFloat(innerValue.INVOICE_AMOUNT);
      }
    }
    return parseFloat(total)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  getTotal() {
    if (this.props.Period === "YEARLY") {
      return (
        <TableBody>
          <TableRow>
            <TableCell className="tablePadding tablecell">
              Accumulative
            </TableCell>
            {this.props.yearOptions.map((yearData) => {
              if (this.checkSelectedYear(yearData)) {
                return (
                  <TableCell align="right" className="tablePadding tablecell">
                    {this.getYearTotal(yearData)}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableBody>
      );
    } else {
      return (
        <TableBody>
          <TableRow>
            <TableCell className="tablePadding tablecell">
              Accumulative
            </TableCell>
            {this.props.yearOptions.map((yearData) => {
              if (this.checkSelectedYear(yearData)) {
                return this.quarter.map((quarter) => {
                  return (
                    <TableCell className="tablePadding tablecell" align="right">
                      {this.getQuarterTotal(quarter, yearData)}
                    </TableCell>
                  );
                });
              }
            })}
          </TableRow>
        </TableBody>
      );
    }
  }

  render() {
    return (
      <div>
        <GridContainer className="ordertable">
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <div className="grapheader">Purchase History(By Value SGD)</div>
                {this.props.apiSpinner === false ? (
                  <Table>
                    {this.getTableHeader()}
                    {this.getTableBody()}
                    {this.getTotal()}
                  </Table>
                ) : (
                  <div className="spinner">
                    <CircularProgress className="innerspinner" />
                  </div>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PurchaseData: state.ReportInfo.PurchaseData,
    apiSpinner: state.util.spinner,
  };
};
export default compose(
  connect(
    mapStateToProps,
    null
  )
)(PurchaseView);
