import React, { Fragment, useEffect } from "react";

import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import MailIcon from "@material-ui/icons/Mail";

//internationalization
import { withTranslation } from "react-i18next";

//child components
import { renderLoginTextField } from "utils/components/InputComponent";

import { validateEmail } from "../../validation/LoginValidation";

//css
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import "./login.css";
import { reduxForm, Field, Form, getFormValues } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
const useStyles = makeStyles(styles);

const EmailValidation = (props) => {
  useEffect(() => {
    if (props.form) {
      props.initialize({
        username: props.form.username,
      });
    }
  }, []);
  const { handleSubmit, onSubmit, t, direction } = props;
  const classes = useStyles();
  return (
    <div>
      <Fragment>
        <Form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="mtop-20"
        >
          <Field
            name="username"
            type="text"
            className={
              classes.textField + " " + classes.bottomGap + " " + classes.root
            }
            component={renderLoginTextField}
            placeholder="Email Address"
            mline={false}
            direction={direction}
            rows={1}
            formControlProps={
              {
                // fullWidth: true
              }
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon className="usericon" />
                </InputAdornment>
              ),
            }}
          />

          <div className="alignCenter">
            <Button
              variant="contained"
              disableElevation
              className="loginBtn"
              type="submit"
            >
              submit
            </Button>
          </div>
        </Form>
      </Fragment>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    form: getFormValues("LoginForm")(state),
  };
};

export default compose(
  reduxForm({
    form: "EmailValidation",
    validate: validateEmail,
  }),
  connect(
    mapStateToProps,
    null
  ),
  withTranslation("translations")
)(EmailValidation);
