import React, { Component, Fragment } from "react";
import PrintIcon from "@material-ui/icons/Print";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PaymentIcon from "@material-ui/icons/Payment";
import GetAppIcon from "@material-ui/icons/GetApp";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
class ReportDownload extends Component {
  constructor(props) {
    super(props);
    this.state = { spinner: false };
  }
  download = (base64, fileName) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = base64;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  downloadReport = () => {
    this.setState({ spinner: true });
    var fileName = this.props.value.substring(
      this.props.value.lastIndexOf("/") + 1,
      this.props.value.length
    );
    api
      .post(
        apiConfig.oracleReports,
        { report_path: this.props.value },
        { handlerEnabled: true }
      )
      .then((res) => {
        if (this.props.type === "WD") {
          this.download(
            "data:" + this.props.fileType + ";base64," + res.data.file_data,
            fileName
          );
        } else {
          this.download(
            "data:application/pdf;base64," + res.data.file_data,
            fileName
          );
        }
        this.setState({ spinner: false });
      })
      .catch((error) => {
        this.setState({ spinner: false });
      });
  };
  render() {
    return (
      <Fragment>
        <div>
          {this.state.spinner ? null : this.props.type === "DO" ? (
            this.props.value ? (
              <PrintIcon onClick={() => this.downloadReport()} />
            ) : (
              <PrintIcon style={{ color: "grey" }} />
            )
          ) : null}
          {this.state.spinner ? null : this.props.type === "PL" ? (
            this.props.value ? (
              <ListAltIcon onClick={() => this.downloadReport()} />
            ) : (
              <ListAltIcon style={{ color: "grey" }} />
            )
          ) : null}
          {this.state.spinner ? null : this.props.type === "SI" ? (
            this.props.value ? (
              <LocalShippingIcon onClick={() => this.downloadReport()} />
            ) : (
              <LocalShippingIcon style={{ color: "grey" }} />
            )
          ) : null}
          {this.state.spinner ? null : this.props.type === "PI" ? (
            this.props.value ? (
              <ReceiptIcon onClick={() => this.downloadReport()} />
            ) : (
              <ReceiptIcon style={{ color: "grey" }} />
            )
          ) : null}
          {this.state.spinner ? null : this.props.type === "TI" ? (
            this.props.value ? (
              <PaymentIcon onClick={() => this.downloadReport()} />
            ) : (
              <PaymentIcon style={{ color: "grey" }} />
            )
          ) : null}
          {this.state.spinner ? null : this.props.type === "WD" ? (
            this.props.value ? (
              <GetAppIcon onClick={() => this.downloadReport()} />
            ) : (
              <GetAppIcon style={{ color: "grey" }} />
            )
          ) : null}
          {this.state.spinner ? (
            <CircularProgress
              className="innerspinner"
              style={{ height: "24px", width: "24px" }}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default ReportDownload;
