import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import PageviewIcon from "@material-ui/icons/Pageview";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  InventorySale_Data,
  Filter_InventorySaleData,
} from "../../../store/actions/index";
import ReactSelect from "react-select";
import { compose } from "redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoIcon from "@material-ui/icons/Info";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { GET_USERINFO } from "../../../store/actions/index";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { IndicatorsContainer } from "../../../utils/components/InputComponent";
const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "16.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "&.MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
});
const customStyles = {
  control: (base, state, meta, input) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
    // width: "94%",
    height: 52,
    minHeight: 52,
    // overwrittes hover style
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      boxShadow: "none",
    },
  }),
};
class InventoryManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      emptyData: false,
      inventoryData: [],
      lovresult: [],
      rowsPerPage: 5,
      page: 0,
      ModelDialog: false,
      selectedCompany: null,
      companyLov: [],
      isFixed: false,
      portalPlacement: "bottom",
      filteredtableRow: [],
      addtionalInfo: [],
      selectedRowId: null,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.UserInfo.CONTACT_EMAIL) {
      api
        .get(apiConfig.UserInfo, { handlerEnabled: true })
        .then((res) => {
          this.props.GET_USERINFO(res.data[0]);
        })
        .catch((error) => {});
    }
    this.props.InventorySale_Data();
    this.compoanyLov();
  }
  compoanyLov = () => {
    api
      .get(apiConfig.CompanyLov, { handlerEnabled: true })
      .then((res) => {
        let companyLov = [{ value: "All", label: "All Company" }];
        res.data.map((data) => {
          companyLov.push({
            value: data.COMPANY_CODE,
            label: data.COMPANY_CODE,
          });
        });
        this.setState({
          selectedCompany: {
            value: companyLov[0].value,
            label: companyLov[0].label,
          },
          companyLov: companyLov,
        });
      })
      .catch((error) => {});
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  handleChange = (value) => {
    this.setState({ selectedCompany: value });
    if (value.value === "All") {
      this.props.InventorySale_Data();
    } else {
      var result = this.props.IventoryDataSearch.filter((objectval) =>
        Object.keys(objectval).some(
          (k) =>
            objectval[k] != null &&
            objectval[k]
              .toString()
              .toLowerCase()
              .includes(value.label.toLowerCase())
        )
      );
      this.setState({ lovresult: result });
      this.props.Filter_InventorySaleData(result);
    }
  };
  SearchProduct = (filterText) => {
    if (this.state.selectedCompany ? this.state.selectedCompany.label : null) {
      if (
        this.state.selectedCompany
          ? this.state.selectedCompany.value === "All"
            ? "All"
            : null
          : null
      ) {
        if (filterText) {
          var result = this.props.IventoryDataSearch.filter((objectval) =>
            Object.keys(objectval).some(
              (k) =>
                objectval[k] != null &&
                objectval[k]
                  .toString()
                  .toLowerCase()
                  .includes(filterText.toLowerCase())
            )
          );
          this.props.Filter_InventorySaleData(result);
        } else {
          this.props.InventorySale_Data();
        }
      } else {
        if (filterText) {
          var result = this.state.lovresult.filter((objectval) =>
            Object.keys(objectval).some(
              (k) =>
                objectval[k] != null &&
                objectval[k]
                  .toString()
                  .toLowerCase()
                  .includes(filterText.toLowerCase())
            )
          );
          this.props.Filter_InventorySaleData(result);
        } else {
          this.props.Filter_InventorySaleData(this.state.lovresult);
        }
      }
    } else {
      if (filterText) {
        var result = this.props.IventoryDataSearch.filter((objectval) =>
          Object.keys(objectval).some(
            (k) =>
              objectval[k] != null &&
              objectval[k]
                .toString()
                .toLowerCase()
                .includes(filterText.toLowerCase())
          )
        );
        this.props.Filter_InventorySaleData(result);
      } else {
        this.props.InventorySale_Data();
      }
    }
  };
  componentDidUpdate(prevProps) {
    const tableRow = [
      "Company",
      "Product Code",
      "Product Description",
      "Product Class",
      "UOM",
      <div style={{ textAlign: "right" }}>Available Quantity</div>,
      <div style={{ textAlign: "right" }}>Selling Price</div>,
      <InfoIcon style={{ textAlign: "center" }} />,
      <AttachFileIcon style={{ textAlign: "center" }} />,
    ];
    if (
      this.props.InventoryInfo &&
      this.props.InventoryInfo !== prevProps.InventoryInfo
    ) {
      if (this.props.InventoryInfo.length > 0) {
        this.setState({ spinner: false, filteredtableRow: tableRow }, () => {
          this.setState({ emptyData: false });
        });
      } else {
        this.setState({ spinner: false }, () => {
          this.setState({ emptyData: true });
        });
      }
    }
  }

  handleClose = () => {
    this.setState({ ModelDialog: false });
  };

  download = (base64, fileName) => {
    const downloadLink = document.createElement("a");

    downloadLink.href = base64;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  inventoryImage = (inventory_item_id) => {
    this.setState({ downloadspinner: true });
    api
      .post(
        apiConfig.inventoryImageSales,
        { inventory_item_id },
        { handlerEnabled: true }
      )
      .then((res) => {
        this.setState({ downloadspinner: false });

        this.download(
          "data:" +
            res.data.FILE_CONTENT_TYPE +
            ";base64," +
            res.data.FILE_DATA,
          res.data.FILE_NAME
        );
      })
      .catch((error) => {});
  };

  onAddtionalInformation = (addtionalInfo) => {
    var additionalArray;
    if (addtionalInfo) {
      additionalArray = addtionalInfo.split("|");
    }
    this.setState({ ModelDialog: true, addtionalInfo: additionalArray });
  };
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {this.state.spinner ? (
          <div className="spinner">
            <CircularProgress className="innerspinner" />
          </div>
        ) : null}
        <Dialog
          open={this.state.ModelDialog}
          onClose={this.handleClose}
          keepMounted
          onClick={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" onClose={this.handleClose}>
            <div className="additionalInfoTitle">Additional Information</div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="dialogcontent">
              {this.state.addtionalInfo.map((item) => {
                return <div className="additionalInfoItems">{item}</div>;
              })}
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GridContainer className="ordertopbar">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selectedCompany}
                      onChange={this.handleChange}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{
                        width: "100%",
                        height: "20px",
                        color: "#202557",
                      }}
                      options={this.state.companyLov}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} className="mbtm-10">
                    <TextField
                      placeholder="Search for Products"
                      variant="outlined"
                      onChange={(event) => {
                        this.SearchProduct(event.target.value);
                      }}
                      className={classes.materialinput}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PageviewIcon className="search_icon" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer className="ordertable">
          <GridItem xs={12}>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {this.state.emptyData ? (
                  <div className="nodatafound">No Data Found</div>
                ) : null}
                {this.state.spinner === false &&
                this.state.emptyData === false ? (
                  <Table>
                    {this.state.downloadspinner ? (
                      <div className="spinner">
                        <CircularProgress className="innerspinner" />
                      </div>
                    ) : null}
                    <TableHead>
                      <TableRow>
                        {this.state.filteredtableRow.map((data, i) => (
                          <TableCell className="bold tablePadding" key={i}>
                            {data}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow></TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.InventoryInfo.slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      ).map((data, i) => {
                        return (
                          <TableRow
                            hover
                            onClick={() => {
                              this.setState({ selectedRowId: i });
                            }}
                            selected={this.state.selectedRowId === i}
                            key={i}
                            classes={{
                              hover: classes.hover,
                              selected: classes.selected,
                            }}
                            className={classes.tableRow}
                          >
                            <TableCell className="tablePadding tablecell">
                              {data.COMPANY_CODE}
                            </TableCell>
                            <TableCell
                              width="15%"
                              className="tablePadding tablecell"
                            >
                              {data.PRODUCT_CODE}
                            </TableCell>
                            <TableCell width="40%" className="tablePadding">
                              {data.PRODUCT_DESCRIPTION}
                            </TableCell>
                            <TableCell width="10%" className="tablePadding">
                              {data.PRODUCT_CLASS}
                            </TableCell>
                            <TableCell className="tablePadding">
                              {data.PRIMARY_UOM_CODE}
                            </TableCell>
                            <TableCell align="right" className="hidden-xs">
                              {data.AVAILABLE_QTY}
                            </TableCell>
                            <TableCell align="right" className="tablePadding">
                              {data.SELLING_PRICE}
                            </TableCell>
                            <TableCell className="tablePadding">
                              <div
                                onClick={() => {
                                  this.onAddtionalInformation(
                                    data.ADDITIONAL_INFO
                                  );
                                }}
                              >
                                <InfoIcon className="infoicon" />
                              </div>
                            </TableCell>
                            <TableCell
                              className={`tablePadding ${
                                data.FILE_NAME == null
                                  ? "AgreementQuantity"
                                  : ""
                              }`}
                              align="center"
                            >
                              <div
                                onClick={() => {
                                  this.inventoryImage(data.INVENTORY_ITEM_ID);
                                }}
                              >
                                <AttachFileIcon className="attachicon" />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          count={this.props.InventoryInfo.length}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    Rtl: state.util.Rtl,
    UserInfo: state.UserInfo.userInfo,
    InventoryInfo: state.InventoryInfo.IventorySaleData,
    IventoryDataSearch: state.InventoryInfo.IventorySaleDataSearch,
  };
};

export default compose(
  connect(
    mapStateToProps,
    { InventorySale_Data, Filter_InventorySaleData, GET_USERINFO }
  ),
  withStyles(styles, { withTheme: true })
)(InventoryManagement);
