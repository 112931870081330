import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEng from "./locales/english";
import translationArab from "./locales/arabic";

i18n
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    }, // we do not use keys in form messages.welcome

    resources: {
      en: {
        translations: translationEng
      },
      arb: {
        translations: translationArab
      }
    }
    // // have a common namespace used around the full app
    // ns: ["translations"],
    // defaultNS: "translations"
  });

export default i18n;
