import InventoryManagement from "./pages/modules/Inventory/InventoryManagement";
import InventorySaleManagement from "./pages/modules/Inventory/InventorySaleManagement";
import OrderFulfillment from "./pages/OrderFulfillment";
import LoginPage from "./pages/auth/LoginPage";
import ResetPassword from "./pages/auth/ResetPassword";
import PersonalInfo from "./pages/modules/MyAccount/PersonalInfo";
import SecurityPrivacy from "./pages/modules/MyAccount/SecurityPrivacy";
import Devices from "./pages/modules/MyAccount/Devices";
import AccountManagement from "./pages/modules/MyAccount/AccountManagement";
import Reports from "./pages/reports/Reports";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircle";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import DevicesIcon from "@material-ui/icons/Devices";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import SecurityIcon from "@material-ui/icons/Security";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import AssessmentIcon from "@material-ui/icons/Assessment";
import MyOrders from "../src/pages/modules/order/MyOrders";
import Order from "../src/pages/modules/order/Order";
import Purchase from "../src/pages/modules/dashboard/Purchase";
import AgreedvsActual from "../src/pages/modules/dashboard/AgreedvsActual";
import ARreports from "../src/pages/modules/dashboard/ARreports";
import CostSavingReport from "../src/pages/modules/dashboard/CostSavingReport";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Dispute from "../src/pages/Dispute";
import FeedbackIcon from "@material-ui/icons/Feedback";
import OrderCart from "../src/pages/modules/Cart/OrderCart";
var dashRoutes = [
  {
    path: "/login-page",
    name: "Login Page",
    display: false,
    mini: "L",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/OrderCart",
    name: "Cart",
    display: false,
    mini: "L",
    component: OrderCart,
    layout: "/admin",
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    display: false,
    component: ResetPassword,
    layout: "/auth",
  },

  {
    collapse: true,
    display: true,
    name: "My Account",
    icon: AccountCircleSharpIcon,
    state: "pageCollapse",
    views: [
      {
        display: true,
        path: "/personalInfo",
        name: "Account Info",
        icon: PersonIcon,
        component: PersonalInfo,
        layout: "/admin",
      },
      {
        display: true,
        path: "/securityPrivacy",
        name: "Security & Privacy",
        icon: SecurityIcon,
        component: SecurityPrivacy,
        layout: "/admin",
      },
      {
        display: true,
        path: "/devices",
        name: "Devices",
        icon: DevicesIcon,
        component: Devices,
        layout: "/admin",
      },
      {
        display: true,
        path: "/accountManagement",
        name: "Account Management",
        icon: PeopleIcon,
        component: AccountManagement,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/inventoryManagement",
    name: "Inventory Management",
    display: true,
    icon: AssignmentIcon,
    component: InventoryManagement,
    layout: "/admin",
  },
  {
    path: "/InventorySaleManagement",
    name: "Inventory Management",
    display: true,
    icon: AssignmentIcon,
    component: InventorySaleManagement,
    layout: "/admin",
  },
  {
    path: "/orderFulfillment",
    name: "Order Fulfilment",
    display: true,
    icon: ShoppingCartIcon,
    component: OrderFulfillment,
    layout: "/admin",
  },

  {
    path: "/MyOrders",
    name: "My Orders",
    display: true,
    icon: ShopTwoIcon,
    component: MyOrders,
    layout: "/admin",
  },
  {
    path: "/Order",
    name: "Orders",
    display: true,
    icon: ShopTwoIcon,
    component: Order,
    layout: "/admin",
  },
  {
    display: true,
    path: "/reports",
    name: "Reports",
    icon: AssessmentIcon,
    component: Reports,
    layout: "/admin",
  },
  {
    display: true,
    path: "/Dispute",
    name: "Dispute",
    icon: FeedbackIcon,
    component: Dispute,
    layout: "/admin",
  },
  {
    collapse: true,
    display: true,
    name: "Dashboard",
    icon: DashboardIcon,
    state: "dashboardpageCollapse",
    views: [
      {
        display: true,
        path: "/CostSavingReport",
        name: "Overview",
        icon: ReceiptIcon,
        component: CostSavingReport,
        layout: "/admin",
      },
      {
        display: true,
        path: "/Purchase",
        name: "Purchase Report",
        icon: AssessmentIcon,
        component: Purchase,
        layout: "/admin",
      },

      {
        display: true,
        path: "/ARreports",
        name: "Outstanding Payments (AR)",
        icon: ReceiptIcon,
        component: ARreports,
        layout: "/admin",
      },
      {
        display: true,
        path: "/AgreedvsActual",
        name: "Stock Utilization Report",
        icon: ReceiptIcon,
        component: AgreedvsActual,
        layout: "/admin",
      },
    ],
  },
];
export default dashRoutes;
