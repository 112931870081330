import React, { Fragment, useEffect } from "react";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import MailIcon from "@material-ui/icons/Mail";
import LockIcon from "@material-ui/icons/Lock";

//internationalization
import { withTranslation } from "react-i18next";

//child components
import { renderLoginTextField } from "utils/components/InputComponent";

import { validateLogin } from "../../validation/LoginValidation";

//css
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import "./login.css";

//redux
import { reduxForm, Field, Form, getFormValues } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";

const useStyles = makeStyles(styles);

const LoginForm = (props) => {
  useEffect(() => {
    if (props.form) {
      props.initialize({
        username: props.form.username,
      });
    }
  }, []);

  const { handleSubmit, onSubmit, t, direction } = props;
  const classes = useStyles();
  return (
    <Fragment>
      <Form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="mtop-20"
      >
        <Field
          name="username"
          type="text"
          className={
            classes.textField + " " + classes.bottomGap + " " + classes.root
          }
          component={renderLoginTextField}
          placeholder="Email Address"
          mline={false}
          direction={direction}
          rows={1}
          formControlProps={
            {
              // fullWidth: true
            }
          }
          inputProps={{ maxLength: 60 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon className="usericon" />
              </InputAdornment>
            ),
          }}
        />
        <Field
          name="password"
          type="password"
          className={
            classes.textField + " " + classes.bottomGap + " " + classes.root
          }
          component={renderLoginTextField}
          placeholder={t("login.password")}
          mline={false}
          rows={1}
          direction={direction}
          formControlProps={
            {
              // fullWidth: true
            }
          }
          inputProps={{ maxLength: 30 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon className="usericon" />
              </InputAdornment>
            ),
          }}
        />
        <div className="alignCenter">
          <Button
            variant="contained"
            disableElevation
            className="loginBtn"
            type="submit"
          >
            {t("login.login")}
          </Button>
        </div>
      </Form>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    form: getFormValues("EmailValidation")(state),
  };
};

export default compose(
  reduxForm({
    form: "LoginForm",
    validate: validateLogin,
  }),
  connect(
    mapStateToProps,
    null
  ),
  withTranslation("translations")
)(LoginForm);
