import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
class AgreedGraph extends Component {
  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip tooltipbg">
          <p className="label" style={{ color: "black" }}>
            {label}
          </p>
          <p className="label" style={{ color: "black" }}>{`${
            payload[0].name
          } : ${parseFloat(payload[0].value)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</p>
          <p className="label" style={{ color: "black" }}>{`${
            payload[1].name
          } : ${parseFloat(payload[1].value)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</p>
        </div>
      );
    }
    return null;
  };
  render() {
    const { spinner } = this.props.headervalue;
    return (
      <div>
        <GridContainer className="ordertable">
          <GridItem xs={12}>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                <div className="grapheader">
                  Agreed/ForeCast Vs Actual Consumption
                </div>
                <div style={{ width: "100%", height: 300 }}>
                  {spinner === false ? (
                    this.props.agreedGraph.length === 0 ? (
                      <div className="nodatafound">No Data Found</div>
                    ) : (
                      <ResponsiveContainer>
                        <ComposedChart
                          data={this.props.agreedGraph}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid />
                          <XAxis dataKey="BASED_ON" />
                          <YAxis />
                          <Tooltip content={this.CustomTooltip} />
                          <Legend verticalAlign="top" />
                          <Bar
                            dataKey="AGREED_VALUE"
                            barSize={20}
                            fill="#8ddfe3"
                          ></Bar>
                          <Line
                            type="monotone"
                            dataKey="ACTUAL_VALUE"
                            stroke="#ee916c"
                          ></Line>
                        </ComposedChart>
                      </ResponsiveContainer>
                    )
                  ) : (
                    <div className="spinner">
                      <CircularProgress className="innerspinner" />
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default AgreedGraph;
