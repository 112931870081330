import axios from 'axios';
import { apiConfig } from './api';
import history from '../config/history';
const api = axios.create({
  baseURL: apiConfig.baseURL
});
const isHandlerEnabled = (config = {}) => {
return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};
const requestHandler = (request) => {
  sessionStorage.setItem("NetworkConnection", null)
if (isHandlerEnabled(request)) {
    request.headers = {
      authorization: `Bearer ${sessionStorage.getItem("tokens")}`,
    };
  }
  return request;
};
api.interceptors.request.use((request) => requestHandler(request));
const errorHandler = async (error) => {
  if (error.message === 'Network Error' && !error.response) {
    sessionStorage.setItem("NetworkConnection", "offline")
  }
  else {
    sessionStorage.setItem("NetworkConnection", null)
    if (isHandlerEnabled(error.config)) {
      console.log('error', error)
      if (error.response.data.message === "jwt expired") {
try {

          let result = await api.post(apiConfig.RefreshToken, { "token": sessionStorage.getItem('refreshToken') }, { handlerEnabled: false })
          sessionStorage.setItem('tokens', result.data.access_token);
          return api(error.config)
        } catch (error) {
          history.push('/auth/login-page');
        }
      }
    }
  }

  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  sessionStorage.setItem("NetworkConnection", null)
if (isHandlerEnabled(response.config)) {
 }
 return response;
};

api.interceptors.response.use(
(response) => successHandler(response),
  (error) => errorHandler(error)
);
export default api;
