import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { compose } from 'redux';
import { connect } from "react-redux";
const useStyles = makeStyles(styles);
function Dashboard(props) {
  const { ...rest } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color] = React.useState("blue");
  const [bgColor] = React.useState("black");
  const [logo] = React.useState(require("assets/img/AT&S VMI Logo-03.png"));
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,

    });
  const mainPanel = React.createRef();
const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveIcon = routes => {
    let activeIcon = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeIcon) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].icon ? routes[i].icon : routes[i].name;
        }
      }
    }
    return activeIcon;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
         
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
         
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  return (
    <div className={classes.wrapper}>
        
      <Sidebar
        routes={routes}
        logoText={"Ooredoo"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
<div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandLogo={getActiveIcon(routes)}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
 {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
           
              <Switch>
                {getRoutes(routes)}

                <Redirect from="/admin" to="/admin//personalInfo" />
              </Switch>
            </div>
          </div>
        ) : (
            <div className={classes.map}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/admin/personalInfo" />
              </Switch>
            </div>
          )}
 {sessionStorage.getItem("NetworkConnection") === "offline" ? <div className="networkerr">Internet Connection Lost</div>: null}
</div>
</div>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    spinner: state.util.spinner,
  }
}
export default compose(
  connect(mapStateToProps, null)
)(Dashboard)