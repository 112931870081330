import React, { Fragment } from "react";
import MyOrderViews from "./MyOrderViews";
import Topbar from "./Topbar";
const MyOrders = () => {
  return (
    <Fragment>
      <Topbar />
      <MyOrderViews />
    </Fragment>
  );
};
export default MyOrders;
