import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import { compose } from "redux";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactSelect from "react-select";
import { IndicatorsContainer } from "../../../utils/components/InputComponent";
import AgreedTable from "./AgreedTable";
import AgreedGraph from "./AgreedGraph";
const customStyles = {
  control: (base, state, meta, input) => ({
    ...base,
    borderColor: "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
    height: 47,
    minHeight: 47,
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      boxShadow: "none",
    },
  }),
};
const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
    },
  },
});
class AgreedvsActual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      rowsPerPage: 5,
      page: 0,
      displayFormat: [],
      sel_Format: { label: "Chart", value: "Chart" },
      selData_Format: { label: "Value", value: "VALUE" },
      DataFormatOptions: [],
      selectedfilter: { value: "PRODUCT_CATEGORY", label: "Product Category" },
      selectedSubType: [{ value: "All", label: "All SubType" }],
      purchaseSubType: [],
      selectedPeriod: { label: "Yearly", value: "YEARLY" },
      agreedGraph: [],
      PeriodOptions: [],
      yearOptions: [],
      selectedYear: {
        value: new Date().getFullYear(),
        label: new Date().getFullYear(),
      },
      agreedActualTable: [],
      filterOptions: null,
      selectedRowId: null,
      years: [],
    };
  }
  componentDidMount() {
    let filterOptions = [
      { value: "PRODUCT_CATEGORY", label: "Product Category" },
      { value: "PRODUCT_CLASS", label: "Product Class" },
      { value: "PRODUCT_CODE", label: "Product Code" },
      { value: "BRAND", label: "Brand" },
    ];
    let displayFormat = [
      { label: "Chart", value: "Chart" },
      { label: "Report", value: "Report" },
    ];
    let PeriodOptions = [
      { label: "Quarterly", value: "QUARTERLY" },
      { label: "Yearly", value: "YEARLY" },
      { label: "Monthly", value: "MONTHLY" },
    ];
    let DataFormatOptions = [
      { label: "Value", value: "VALUE" },
      { label: "Quantity", value: "QUANTITY" },
    ];
    this.setState({
      filterOptions: filterOptions,
      displayFormat: displayFormat,
      PeriodOptions: PeriodOptions,
      DataFormatOptions: DataFormatOptions,
    });
    this.getAgreedApi();
    this.purchaseSubTypeApi(this.state.selectedfilter);
    this.yearApi();
    this.agreedActualTableApi();
  }

  yearApi = () => {
    var years = [];
    let yearOptions = [];
    api
      .post(
        apiConfig.PurchaseSubType,
        {
          group_by: "YEAR",
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        res.data.map((result, i) => {
          years.push(result.YEAR);
          yearOptions.push({ value: result.YEAR, label: result.YEAR });
        });
        this.setState({ yearOptions: yearOptions, years: years });
      })
      .catch((error) => {});
  };
  getAgreedApi = async () => {
    const {
      selectedfilter,
      selectedSubType,
      selectedYear,
      selData_Format,
      selectedPeriod,
    } = this.state;
    if (selectedSubType != null) {
      this.setState({ spinner: true });
      var subType = selectedSubType
        .map((data, i) => {
          return `'${data.value}'`;
        })
        .join(",");
      var selSubtype = subType.includes("All") ? "All" : subType;
      let result = await api.post(
        apiConfig.AgreedValue,
        {
          type: selectedfilter.value,
          subtype: selSubtype,
          period: selectedPeriod.value,
          year: selectedYear.value.toString(),
          valueorqty: selData_Format.value,
        },
        { handlerEnabled: true }
      );

      let ActualValue = await api.post(
        apiConfig.ActualValue,
        {
          type: selectedfilter.value,
          subtype: selSubtype,
          period: selectedPeriod.value,
          year: selectedYear.value.toString(),
          valueorqty: selData_Format.value,
        },
        { handlerEnabled: true }
      );
      if (ActualValue.data.length > 0 && result.data.length > 0) {
        var AGREED_VALUE = result.data[0].AGREED_VALUE;
        const agreedGraph = ActualValue.data.map((el) => ({
          ...el,
          AGREED_VALUE: AGREED_VALUE,
        }));
        this.setState({ agreedGraph: agreedGraph, spinner: false });
      }
    }
  };
  purchaseSubTypeApi = (value) => {
    let purchaseSubType = [{ value: "All", label: "All SubType" }];
    api
      .post(
        apiConfig.PurchaseSubType,
        {
          group_by: value.value,
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        res.data.map((result, i) => {
          var keys = Object.keys(result).map((data, i) => data);
          purchaseSubType.push({
            value: result[keys[0]],
            label: result[keys[0]],
          });
        });
        this.setState({
          purchaseSubType: purchaseSubType,
          selectedSubType: [{ value: "All", label: "All SubType" }],
        });
      })
      .catch((error) => {});
  };
  handleChangePeriod = (value) => {
    this.setState({ selectedPeriod: value }, () => {
      this.getAgreedApi();
    });
  };
  handleChangeYear = (value) => {
    this.setState({ selectedYear: value }, () => {
      this.agreedActualTableApi();

      this.getAgreedApi();
    });
  };
  agreedActualTableApi = () => {
    const { selectedfilter, selectedYear, selectedSubType } = this.state;
    if (selectedSubType != null) {
      this.setState({ spinner: true });
      var subType = selectedSubType
        .map((data, i) => {
          return `'${data.value}'`;
        })
        .join(",");
      var selSubtype = subType.includes("All") ? "All" : subType;
      api
        .post(
          apiConfig.AgreedActual,
          {
            type: selectedfilter.value,
            subtype: selSubtype,
            year: selectedYear.value.toString(),
          },
          { handlerEnabled: true }
        )
        .then((res) => {
          this.setState({ agreedActualTable: res.data, spinner: false });
        })
        .catch((error) => {});
    }
  };

  handleChangeFormat = (value) => {
    this.setState({ sel_Format: value });
  };
  handleChangeSubType = (value) => {
    this.setState({ selectedSubType: value }, () => {
      this.agreedActualTableApi();

      this.getAgreedApi();
    });
  };
  handleChangeFilter = (value) => {
    this.setState(
      {
        selectedfilter: value,
        selectedSubType: [{ value: "All", label: "All SubType" }],
      },
      () => {
        this.agreedActualTableApi();

        this.getAgreedApi();
      }
    );
    this.purchaseSubTypeApi(value);
  };
  handleChangeDataFormat = (value) => {
    this.setState({ selData_Format: value }, () => {
      this.agreedActualTableApi();
      this.getAgreedApi();
    });
  };
  render() {
    var headervalue = { spinner: this.state.spinner };
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GridContainer className="ordertopbar">
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">
                      Search By
                    </InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selectedfilter}
                      onChange={this.handleChangeFilter}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.filterOptions}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">Sub Type</InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selectedSubType}
                      onChange={this.handleChangeSubType}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      isMulti
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.purchaseSubType}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">Period</InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selectedPeriod}
                      onChange={this.handleChangePeriod}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.PeriodOptions}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">Year</InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selectedYear}
                      onChange={this.handleChangeYear}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.yearOptions}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">Display</InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.sel_Format}
                      onChange={this.handleChangeFormat}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.displayFormat}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">Data</InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selData_Format}
                      onChange={this.handleChangeDataFormat}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.DataFormatOptions}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.sel_Format.value === "Report" ? (
          <AgreedTable
            headervalue={headervalue}
            agreedActualTable={this.state.agreedActualTable}
          />
        ) : (
          <AgreedGraph
            Period={this.state.selectedPeriod.value}
            headervalue={headervalue}
            agreedGraph={this.state.agreedGraph}
          />
        )}
      </div>
    );
  }
}
export default compose(withStyles(styles, { withTheme: true }))(AgreedvsActual);
