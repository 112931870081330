export default {
  login:{
    "userName" : "اسم المستخدم",
    "password" : "كلمة السر",
    "selectLanguage" : "اختر لغة",
    "login" : "تسجيل الدخول",
    "english" : "English",
    "arabic" : "عربى",
    "forogotPassword" : "هل نسيت كلمة المرور؟"
  },
  leave:{
    table:{
      "absenceType":"نوع الغياب",
      "absenceCategory":"فئة الغياب",
      "absenceStatus":"حالة الغياب",
      "startDate":"تاريخ البداية",
      "endDate":"تاريخ النهاية",
      "addLeave":"أضف إجازة"
    },
    add:{
      "absenceReason":" سبب الغياب",
      "attachment":"إضافة مرفق",
      "calculateDays":"احتساب المدة",
      "comments":"الملاحظات",
      "note":"ملحوظة",
      "duration":"المدة",
      "detail":"حدد دائمًا نوع الغياب وأدخل أي معلومات أخرى تريد إنشاء طلبك. يمكنك اختيار زر مدة الحساب لاختيار عدد الأيام أو الساعة التي تطلبها"
    },
    button:{
      "submit":"تنفيذ",
      "cancel":"إلغاء"
    }
  },
  salary:{
    "numberofWeeks":"عدد الاسابيع",
    "comments":"الملاحظات",
    "attachment":"إضافة مرفق",
    "status":"الحالة",
    "new":"جديد",
    "submit":"تنفيذ",
    "cancel":"إلغاء"
  },
  allowance:{
    "name":"اسم",
    "school":"مدرسة فكرية",
    "education":"التعليم",
    "startDate":"تاريخ البداية",
    "amount":"كمية",
    "new":"جديد",
    add:{
      "startDate":"تاريخ البداية",
    },
    button:{
      "submit":"تنفيذ",
      "cancel":"إلغاء"
    }
  },
  personalInfo:{
    "basicDetail":"تفاصيل رئيسية",
    "edit":"تعديل",
    "new":"جديد",
    "fullName":"الاسم بالكامل",
    "mStatus":"الحالة الاجتماعية",
    "dob":"تاريخ الميلاد",
    "civilIdentifier":"الرقم المدني",
    "empNumber":"رقم الموظف",
    "orgEmpAddress":"عنوان البريد الإلكتروني للمنشأة",
    "mainAddress":"العنوان الرئيسي",
    "emeContacts":"ممثلو الطوارئ",
    "depBen":"المعال والمستفيد ",
    address:{
      "locationAdd":"Location Address",
      "governorate":"المحافظة",
      "poboxNo":"Post Box Number",
      "street":"الشارع",
      "laneRoad":"الدرب / الطريق",
      "buildApment":"المبنى / الشقة",
      "pobox":"صندوق مكتب البريد",
      "areaName":"Area Name",
      "areaNameArb":"Area Name in Arabic",
      "areaCode":"Area Code",
      "country":"الدولة",
      "laneNo":"Lane Number",
      "email":"Email",
      "phoneExt":"Phone Extension",
      "atDest":"Air Ticket Destination"
    },
    emeCont:{
      "name":"الاسم",
      "relationship":"العلاقة",
      "pContact":"الممثل الأساسي"
    },
    dep:{
      "name":"الاسم",
      "relationship":"العلاقة",
      "title":"مسمى وظيفي",
      "firstName":"الاسم الأول",
      "familyName":"لقب العائلة",
      "rlnStartDate":"اريخ بداية العلاقة",
      "addDepBenInfo":"معلومات إضافية عن المعالين والمستفيدين",
      "useMyAddforPer":"استخدام العنوان الخاص بي لهذا الشخص",
      "phNumb":"أرقام الهاتف",
      "type":"النوع",
      "number":"الرقم",
      "attachment":"المرفقات",
      button:{
        "addNumb":"أضف رقم",
        "submit":"تنفيذ",
        "cancel":"إلغاء"
      }
    },
    modal:{
      "basicDetail":"تفاصيل رئيسية (إختر خيارا)",
      "select":"د نوع التغيير الذي تريد إجراءه",
      "correct":"قم بتصحيح أو إكمال التفاصيل الحالية",
      "new":"أدخل بيانات جديدة بسبب التغيير الحقيقي للتفاصيل الحالية (على سبيل المثال بسبب التغيير في موقف الحالة الاجتماعية)"
    },
    editPage:{
      "ename":"اسم الموظف",
      "oempadd":"عنوان البريد الإلكتروني للمنشأة",
      "name":"الاسم",
      "effDate":"تاريخ الفعالية",
      "title":"مسمى وظيفي",
      "firstName":"الاسم الأول",
      "familyName":"لقب العائلة",
      "addInfo":"معلومة اضافية",
      "empNo":"رقم الموظف",
      "civilIdent":"الرقم المدني",
      "dob":"تاريخ الميلاد",
      "gender":"الجنس",
      "marStatus":"الحالة الاجتماعية",
      "nation":"الجنسية",
      "attachment":"المرفقات"
    }
  }
}