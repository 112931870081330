import React, { Component, Fragment } from "react";
import ExcelDownload from "./ExcelDownload";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from "@material-ui/icons/GetApp";
import { apiConfig } from "../../config/api";
import api from "../../config/apiconfig";
class InventoryOnHand extends Component {
  responseData = [
    {
      columns: [
        {
          title: "Customer Number",
          width: { wch: 17 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Customer Name",
          width: { wch: 30 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Bill To Location",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Product Code",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Customer Product Code",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Product Description",
          width: { wch: 70 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Product Class",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Primary UOM Code",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Location",
          width: { wch: 20 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Agreement",
          width: { wch: 10 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Agreement Qty",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Available Qty",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Selling Price",
          width: { wch: 15 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
        {
          title: "Additional Info",
          width: { wch: 40 },
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "AAD3D3D3" } },
            border: {
              top: { style: "thin", color: { rgb: "#000000" } },
              right: { style: "thin", color: { rgb: "#000000" } },
              bottom: { style: "thin", color: { rgb: "#000000" } },
              left: { style: "thin", color: { rgb: "#000000" } },
            },
            font: { name: "Arial", sz: "10" },
          },
        },
      ],
      data: [],
    },
  ];

  constructor(props) {
    super(props);
    this.state = { showExcel: false, spinner: false };
  }

  showExcelComponent() {
    this.setState({ spinner: true });
    this.responseData[0].data = [];
    api
      .post(
        apiConfig.inventoryOnHandReport,
        { product_class: this.props.productClass },
        { handlerEnabled: true }
      )
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((element) => {
            var array = [];
            array.push({
              value:
                element.CUSTOMER_NUMBER !== null ? element.CUSTOMER_NUMBER : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.CUSTOMER_NAME !== null ? element.CUSTOMER_NAME : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.BILL_TO_LOCATION !== null
                  ? element.BILL_TO_LOCATION
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.PRODUCT_CODE !== null ? element.PRODUCT_CODE : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.CUSTOMER_PRODUCT_CODE !== null
                  ? element.CUSTOMER_PRODUCT_CODE
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.PRODUCT_DESCRIPTION !== null
                  ? element.PRODUCT_DESCRIPTION
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.PRODUCT_CLASS !== null ? element.PRODUCT_CLASS : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.PRIMARY_UOM_CODE !== null
                  ? element.PRIMARY_UOM_CODE
                  : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.LOCATION !== null ? element.LOCATION : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value: element.AGREEMENT !== null ? element.AGREEMENT : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.AGREEMENT_QTY !== null ? element.AGREEMENT_QTY : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.AVAILABLE_QTY !== null ? element.AVAILABLE_QTY : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.SELLING_PRICE !== null ? element.SELLING_PRICE : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            array.push({
              value:
                element.ADDITIONAL_INFO !== null ? element.ADDITIONAL_INFO : "",
              style: {
                border: {
                  top: { style: "thin", color: { rgb: "#000000" } },
                  right: { style: "thin", color: { rgb: "#000000" } },
                  bottom: { style: "thin", color: { rgb: "#000000" } },
                  left: { style: "thin", color: { rgb: "#000000" } },
                },
                font: { name: "Arial", sz: "10" },
              },
            });
            this.responseData[0].data.push(array);
          });
          this.setState({ showExcel: true, spinner: false });
          setTimeout(() => {
            this.setState({ showExcel: false });
          }, 3000);
        } else {
          this.setState({ spinner: false });
          this.props.showerror();
        }
      });
  }

  render() {
    return (
      <Fragment>
        <div>
          {this.state.spinner ? null : (
            <GetAppIcon
              className="attachicon"
              onClick={() => this.showExcelComponent()}
            />
          )}
          {this.state.spinner ? (
            <CircularProgress
              className="innerspinner"
              style={{ height: "24px", width: "24px" }}
            />
          ) : null}
          {this.state.showExcel ? (
            <ExcelDownload
              data={this.responseData}
              excelName="Inventory Onhand Details"
            ></ExcelDownload>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default InventoryOnHand;
