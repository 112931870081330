import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import { compose } from "redux";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import {
  PURCHASE_DATA,
  PURCHASE_QUANTITY_DATA,
  SPINNER,
} from "../../../store/actions/index";
import { IndicatorsContainer } from "../../../utils/components/InputComponent";
import PurchaseView from "./PurchaseView";
import PurchaseQuantityView from "./PurchaseQuantityView";
import PurchaseGraph from "./PurchaseGraph";
import PurchaseByQuantity from "./PurchaseByQuantity";
const customStyles = {
  control: (base, state, meta, input) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
    // width: "94%",
    height: 47,
    minHeight: 47,
    // overwrittes hover style
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      boxShadow: "none",
    },
  }),
};

const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
    },
  },
  table: {
    width: "max-content",
  },
  datePick: {
    "&.MuiFormControl-marginNormal": {
      marginTop: "0px !important",
      marginBottom: "0px !important",
    },
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "red",
    },
  },
});
class PurchaseTopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      shipToLov: [],
      emptyData: false,
      inventoryData: [],
      rowsPerPage: 5,
      page: 0,
      InventoryInfo: [],
      displayFormat: [],
      OriginalPurchaseValue: [],
      OriginalPurchaseQuantity: [],
      sel_Format: { label: "Chart", value: "Chart" },
      selectedfilter: { value: "PRODUCT_CATEGORY", label: "Product Category" },
      selectedSubType: { value: "All", label: "All SubType" },
      purchaseSubType: [],
      selectedPeriod: { label: "Quarterly", value: "QUARTERLY" },
      PeriodOptions: [],
      yearOptions: [],
      selectedYear: { value: "All", label: "All Years" },
      selectedCustomer: { value: null, label: "All Customer" },
      CustomerOptions: [],
      shipToLov: [],
      ModelDialog: false,
      selectedorder: [],
      Uomerror: false,
      error: false,
      filteredOrderColumn: [],
      filterOptions: null,
      isLoading: false,
      Alt_addressform: {},
      open: true,
      selectedRowId: null,
      OrderDate: null,
      years: [],
    };
  }

  componentDidMount() {
    let filterOptions = [
      { value: "PRODUCT_CATEGORY", label: "Product Category" },
      { value: "PRODUCT_CLASS", label: "Product Class" },
      { value: "PRODUCT_CODE", label: "Product Code" },
      { value: "BRAND", label: "Brand" },
    ];
    let displayFormat = [
      { label: "Chart", value: "Chart" },
      { label: "Report", value: "Report" },
    ];
    let PeriodOptions = [
      { label: "Quarterly", value: "QUARTERLY" },
      { label: "Yearly", value: "YEARLY" },
    ];
    this.setState({
      filterOptions: filterOptions,
      displayFormat: displayFormat,
      PeriodOptions: PeriodOptions,
    });
    this.PurchaseApi();
    this.purchaseSubTypeApi(this.state.selectedfilter);
    if (sessionStorage.getItem("ACCOUNT_TYPE") === "SALESPERSON") {
      this.CustomeNamesLov(this.state.selectedName);
    }
    this.yearApi();
  }
  CustomeNamesLov = () => {
    let CustomerOptions = [{ value: "All", label: "All Customer" }];
    api
      .get(apiConfig.CustomerNames, { handlerEnabled: true })
      .then((res) => {
        res.data.map((result, i) => {
          CustomerOptions.push({
            value: result.CUSTOMER_NAME,
            label: result.CUSTOMER_NAME,
          });
        });
        this.setState({
          CustomerOptions: CustomerOptions,
          selectedCustomer: { value: null, label: "All Customer" },
        });
      })
      .catch((error) => {});
  };
  yearApi = () => {
    var years = [];
    let yearOptions = [{ value: "All", label: "All Years" }];
    api
      .post(
        apiConfig.PurchaseSubType,
        {
          group_by: "YEAR",
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        res.data.map((result, i) => {
          years.push(result.YEAR);
          yearOptions.push({ value: result.YEAR, label: result.YEAR });
        });
        this.setState({
          yearOptions: yearOptions,
          selectedYear: { value: "All", label: "All Years" },
          years: years,
        });
      })
      .catch((error) => {});
  };
  PurchaseApi = () => {
    this.props.SPINNER(true);
    console.log("selected customer", this.state.selectedCustomer.value);
    api
      .post(
        apiConfig.PurchaseBy,
        {
          on: "PURCHASE_VALUE",
          group_by: this.state.selectedfilter.value,
          period: this.state.selectedPeriod.value,
          customer_name: this.state.selectedCustomer.value,
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        this.props.SPINNER(false);
        this.setState({ OriginalPurchaseValue: res.data }, () => {
          this.handlefilter();
        });
      })
      .catch((error) => {});
    api
      .post(
        apiConfig.PurchaseBy,
        {
          on: "PURCHASE_QUANTITY",
          group_by: this.state.selectedfilter.value,
          period: this.state.selectedPeriod.value,
          customer_name: this.state.selectedCustomer.value,
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        this.props.SPINNER(false);
        this.setState({ OriginalPurchaseQuantity: res.data }, () => {
          this.handlefilter();
        });
      })
      .catch((error) => {});
  };
  handlefilter = () => {
    const {
      selectedSubType,
      selectedfilter,
      selectedYear,
      yearOptions,
      OriginalPurchaseQuantity,
      OriginalPurchaseValue,
    } = this.state;
    var subtypeBollean = JSON.stringify(selectedSubType).includes("All")
      ? true
      : false;
    if (selectedSubType === null && selectedYear.value === "All") {
      this.props.PURCHASE_DATA(OriginalPurchaseValue);
      this.props.PURCHASE_QUANTITY_DATA(OriginalPurchaseQuantity);
    } else if (
      (selectedSubType === null && selectedYear.value !== "All") ||
      (subtypeBollean === true && selectedYear.value !== "All")
    ) {
      var filteryear = OriginalPurchaseValue.filter(
        (item) => item.YEAR === selectedYear.value
      );
      this.props.PURCHASE_DATA(filteryear);
      var filteryearQuantity = OriginalPurchaseQuantity.filter(
        (item) => item.YEAR === selectedYear.value
      );
      this.props.PURCHASE_QUANTITY_DATA(filteryearQuantity);
    } else if (subtypeBollean === false && selectedYear.value == "All") {
      var filterPurchase = [];
      selectedSubType.map((data, i) => {
        OriginalPurchaseValue.filter((item, i) => {
          var innerkey = Object.keys(item).map((innerobject, i) => innerobject);
          if (item[innerkey[1]] === data.value) {
            filterPurchase.push(item);
          }
        });
      });
      this.props.PURCHASE_DATA(filterPurchase);
      var filterPurchaseQuantity = [];
      selectedSubType.map((data, i) => {
        OriginalPurchaseQuantity.filter((item, i) => {
          var innerkey = Object.keys(item).map((innerobject, i) => innerobject);
          if (item[innerkey[1]] === data.value) {
            filterPurchaseQuantity.push(item);
          }
        });
      });
      this.props.PURCHASE_QUANTITY_DATA(filterPurchaseQuantity);
    } else if (
      (selectedYear.value === "All" && subtypeBollean) ||
      (subtypeBollean && selectedYear.value === "All")
    ) {
      this.props.PURCHASE_DATA(OriginalPurchaseValue);
      this.props.PURCHASE_QUANTITY_DATA(OriginalPurchaseQuantity);
    } else if (selectedYear.value !== "All" && subtypeBollean === false) {
      var filteryear = OriginalPurchaseValue.filter(
        (item) => item.YEAR === selectedYear.value
      );
      var filterPurchase = [];
      selectedSubType.map((data, i) => {
        filteryear.filter((item, i) => {
          var innerkey = Object.keys(item).map((innerobject, i) => innerobject);
          if (item[innerkey[1]] === data.value) {
            filterPurchase.push(item);
          }
        });
      });
      this.props.PURCHASE_DATA(filterPurchase);
      var filteryearQuantity = OriginalPurchaseQuantity.filter(
        (item) => item.YEAR === selectedYear.value
      );
      var filterPurchaseQuantity = [];
      selectedSubType.map((data, i) => {
        filteryearQuantity.filter((item, i) => {
          var innerkey = Object.keys(item).map((innerobject, i) => innerobject);
          if (item[innerkey[1]] === data.value) {
            filterPurchaseQuantity.push(item);
          }
        });
      });
      this.props.PURCHASE_QUANTITY_DATA(filterPurchaseQuantity);
    }
  };

  purchaseSubTypeApi = (value) => {
    let purchaseSubType = [{ value: "All", label: "All SubType" }];
    api
      .post(
        apiConfig.PurchaseSubType,
        {
          group_by: value.value,
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        res.data.map((result, i) => {
          var keys = Object.keys(result).map((data, i) => data);
          purchaseSubType.push({
            value: result[keys[0]],
            label: result[keys[0]],
          });
        });
        this.setState({
          purchaseSubType: purchaseSubType,
          selectedSubType: { value: "All", label: "All SubType" },
        });
      })
      .catch((error) => {});
  };
  handleChangeYear = (value) => {
    this.setState({ selectedYear: value }, () => this.handlefilter());
  };
  handleChangeFormat = (value) => {
    this.setState({ sel_Format: value });
  };
  handleChangeCustomer = (value) => {
    this.setState({ selectedCustomer: value }, () => {
      this.PurchaseApi();
    });
  };
  handleChangeSubType = (value) => {
    this.setState({ selectedSubType: value }, () => this.handlefilter());
  };
  handleChangeFilter = (value) => {
    this.setState(
      {
        selectedfilter: value,
        selectedPeriod: { label: "Quarterly", value: "QUARTERLY" },
        selectedYear: { value: "All", label: "All Years" },
        selectedSubType: { value: "All", label: "All SubType" },
      },
      () => {
        this.PurchaseApi();
      }
    );
    this.purchaseSubTypeApi(value);
  };

  handleChangePeriod = (value) => {
    this.setState({ selectedPeriod: value }, () => {
      this.PurchaseApi();
    });
  };
  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GridContainer className="ordertopbar">
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">
                      Search By
                    </InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selectedfilter}
                      onChange={this.handleChangeFilter}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.filterOptions}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">Sub Type</InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selectedSubType}
                      onChange={this.handleChangeSubType}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      isMulti
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.purchaseSubType}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">Period</InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selectedPeriod}
                      onChange={this.handleChangePeriod}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.PeriodOptions}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">Year</InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.selectedYear}
                      onChange={this.handleChangeYear}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.yearOptions}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    className="alignLabelcenter  mbtm-10 bold"
                  >
                    <InputLabel className="ordertextcolor">Display</InputLabel>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "auto" }}
                    className="mbtm-10"
                  >
                    <ReactSelect
                      value={this.state.sel_Format}
                      onChange={this.handleChangeFormat}
                      components={{ IndicatorsContainer }}
                      fullWidth
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPosition={this.state.isFixed ? "fixed" : "absolute"}
                      styles={customStyles}
                      style={{ width: "100%" }}
                      options={this.state.displayFormat}
                      menuPlacement={this.state.portalPlacement}
                    />
                  </GridItem>
                  {sessionStorage.getItem("ACCOUNT_TYPE") === "SALESPERSON" ? (
                    <>
                      <GridItem
                        xs={6}
                        sm={6}
                        md={2}
                        className="alignLabelcenter  mbtm-10 bold"
                      >
                        <InputLabel className="ordertextcolor">
                          Search By Customer
                        </InputLabel>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={4}
                        style={{ marginTop: "auto" }}
                        className="mbtm-10"
                      >
                        <ReactSelect
                          value={this.state.selectedCustomer}
                          onChange={this.handleChangeCustomer}
                          components={{ IndicatorsContainer }}
                          fullWidth
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPosition={
                            this.state.isFixed ? "fixed" : "absolute"
                          }
                          styles={customStyles}
                          style={{ width: "100%" }}
                          options={this.state.CustomerOptions}
                          menuPlacement={this.state.portalPlacement}
                        />
                      </GridItem>
                    </>
                  ) : null}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.sel_Format.value === "Report" ? (
          <Fragment>
            {" "}
            <PurchaseView
              yearOptions={this.state.years}
              Period={this.state.selectedPeriod.value}
              headervalues={this.state.selectedfilter.label}
              subType={this.state.purchaseSubType}
              selectedSubType={this.state.selectedSubType}
              selectedYear={this.state.selectedYear}
            />
            <PurchaseQuantityView
              yearOptions={this.state.years}
              Period={this.state.selectedPeriod.value}
              headervalues={this.state.selectedfilter.label}
              subType={this.state.purchaseSubType}
              selectedSubType={this.state.selectedSubType}
              selectedYear={this.state.selectedYear}
            />
          </Fragment>
        ) : (
          <Fragment>
            <PurchaseGraph
              Period={this.state.selectedPeriod.value}
              headervalues={this.state.selectedfilter.label}
            />
            <PurchaseByQuantity
              Period={this.state.selectedPeriod.value}
              headervalues={this.state.selectedfilter.label}
            />
          </Fragment>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PurchaseData: state.ReportInfo.PurchaseData,
    UserInfo: state.UserInfo.userInfo,
    MyOrderSearch: state.OrderFulfillmentInfo.MyOrderSearch,
  };
};
export default compose(
  connect(
    mapStateToProps,
    { PURCHASE_DATA, PURCHASE_QUANTITY_DATA, SPINNER }
  ),
  withStyles(styles, { withTheme: true })
)(PurchaseTopBar);
