import React, { Component, Fragment } from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Button from "components/CustomButtons/Button.js";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GET_MYORDERINFO } from "../../../store/actions/index";
import { compose } from "redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { MYORDER_SEARCH } from "../../../store/actions/index";
import ReportDownload from "../order/ReportDownload";
const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
});

const columns = [
  {
    id: "File Name",
    label: "File Name",
    align: "left",
    minWidth: 100,
  },
  {
    id: "Uploaded On",
    label: "Uploaded On",
    align: "left",
    minWidth: 100,
  },
  {
    id: "Uploaded By",
    label: "Uploaded By",
    align: "left",
    minWidth: 100,
  },
  {
    id: "Download",
    label: "Download",
    align: "center",
    minWidth: 100,
  },
  {
    id: "Delete",
    label: "Delete Document",
    align: "center",
    minWidth: 100,
  },
];

class OrderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      emptyData: false,
      inventoryData: [],
      rowsPerPage: 5,
      isFixed: false,
      portalPlacement: "bottom",
      page: 0,
      ModelDialog: false,
      addtionalInfo: [],
      lovresult: [],
      selecteProductClass: null,
      ProductClassLov: [],
      selectedFile: null,
      selectedRowId: null,
      documentList: [],
      uploadDisable: true,
      errorStatus: false,
      errorMessage: null,
      uploadSpinner: false,
      filteredColumn: [],
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  Delete_Document = (inputs) => {
    const { ORDER_NUMBER, DO_NUMBER, CUST_PO_NUMBER } = this.props.selectedRow;
    const { FILE_NAME, FILE_TYPE, FILE_LOCATION } = inputs;
    this.setState({ spinner: true });
    api
      .post(
        apiConfig.removedocument,
        {
          order_number: parseInt(ORDER_NUMBER),
          DO_number: parseInt(DO_NUMBER),
          cust_po_number: CUST_PO_NUMBER,
          file_name: FILE_NAME,
          file_type: FILE_TYPE,
          file_location: FILE_LOCATION,
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        this.setState({ spinner: false, documentList: res.data.uploadDetails });
      })
      .catch((error) => {});
  };
  onFileChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      uploadDisable: false,
    });
  };
  onFileUpload = async () => {
    this.setState({ uploadSpinner: true });
    var fileName = this.state.selectedFile.name;
    const result = this.state.documentList.find(
      ({ FILE_NAME }) => FILE_NAME === this.state.selectedFile.name
    );
    if (this.state.selectedFile.size / 1024 / 1024 > 5) {
      this.setState({
        errorStatus: true,
        errorMessage: "File Size should not Exceed 5 MB!",
      });
      this.setState({ uploadSpinner: false });
    } else if (result) {
      this.setState({
        errorStatus: true,
        errorMessage: "File Name Already Exist!",
      });
      this.setState({ uploadSpinner: false });
    } else {
      this.setState({ errorStatus: false, errorMessage: "" });
      var fileType = this.state.selectedFile.type;
      var fileContent = await this.toBase64(this.state.selectedFile);
      var removedBase64 = fileContent.substring(
        fileContent.indexOf(";base64,") + 8,
        fileContent.length
      );
      api
        .post(
          apiConfig.orderDocumentUpload,
          {
            order_number: this.props.selectedRow.ORDER_NUMBER,
            DO_number: this.props.selectedRow.DO_NUMBER,
            cust_po_number: this.props.selectedRow.CUST_PO_NUMBER,
            file_name: fileName,
            file_type: fileType,
            file_content: removedBase64,
          },
          { handlerEnabled: true }
        )
        .then((res) => {
          this.setState({
            uploadSpinner: false,
            documentList: res.data.uploadDetails,
          });
        })
        .catch((error) => {
          this.setState({ uploadSpinner: false });
        });
    }
  };
  componentDidMount() {
    var filteredColumn;
    if (sessionStorage.getItem("ACCOUNT_TYPE") === "IT") {
      this.setState({ filteredColumn: columns });
    } else {
      filteredColumn = columns.filter((item) => item.id !== "Delete");
      this.setState({ filteredColumn: filteredColumn });
    }
    window.scrollTo(0, 0);
    this.setState({ spinner: true });
    api
      .post(
        apiConfig.orderDocuments,
        {
          order_number: this.props.selectedRow.ORDER_NUMBER,
          DO_number: this.props.selectedRow.DO_NUMBER,
          cust_po_number: this.props.selectedRow.CUST_PO_NUMBER,
          type: this.props.type,
        },
        { handlerEnabled: true }
      )
      .then((res) => {
        this.setState({ spinner: false, documentList: res.data });
      })
      .catch((error) => {
        this.setState({ spinner: false });
      });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.spinner ? (
          <div className="spinner">
            <CircularProgress className="innerspinner" />
          </div>
        ) : null}
        <Fragment>
          <div style={{ textAlign: "center" }}>
            <input type="file" onChange={this.onFileChange} />
            {this.state.uploadSpinner ? (
              <CircularProgress style={{ height: "20px", width: "20px" }} />
            ) : (
              <Button
                className="Orderbutton"
                onClick={this.onFileUpload}
                disabled={this.state.uploadDisable}
              >
                Upload!
              </Button>
            )}
          </div>
          {this.state.errorStatus ? (
            <div className="loginerror" style={{ textAlign: "center" }}>
              {this.state.errorMessage}
            </div>
          ) : null}
          {this.state.spinner === false ? (
            this.state.documentList.length === 0 ? (
              <div className="nodatafound">No Data Found</div>
            ) : (
              <div style={{ overflowX: "scroll" }}>
                <Table>
                  <TableHead>
                    <TableRow hover>
                      {this.state.filteredColumn.map((column) => (
                        <TableCell
                          className="bold tablePadding"
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.documentList
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((data, i) => {
                        return (
                          <TableRow
                            hover
                            onClick={() => {
                              this.setState({ selectedRowId: i });
                            }}
                            selected={this.state.selectedRowId === i}
                            key={i}
                            classes={{
                              hover: classes.hover,
                              selected: classes.selected,
                            }}
                            className={classes.tableRow}
                          >
                            <TableCell align="left" className="tablePadding">
                              {data.FILE_NAME}
                            </TableCell>
                            <TableCell align="left" className="tablePadding">
                              {data.CREATION_DATE}
                            </TableCell>
                            <TableCell align="left" className="tablePadding">
                              {data.CREATED_BY}
                            </TableCell>
                            <TableCell align="center" className="tablePadding">
                              <ReportDownload
                                type="WD"
                                value={data.FILE_LOCATION}
                                fileTye={data.FILE_TYPE}
                              ></ReportDownload>
                            </TableCell>
                            {sessionStorage.getItem("ACCOUNT_TYPE") === "IT" ? (
                              <TableCell
                                align="center"
                                className="tablePadding"
                              >
                                <DeleteForeverIcon
                                  className="deleteicon"
                                  onClick={() => this.Delete_Document(data)}
                                />
                              </TableCell>
                            ) : (
                              ""
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        count={this.state.documentList.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          native: true,
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            )
          ) : null}
        </Fragment>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserInfo: state.UserInfo.userInfo,
    MyordersData: state.OrderFulfillmentInfo.MyordersData,
  };
};
export default compose(
  connect(
    mapStateToProps,
    { GET_MYORDERINFO, MYORDER_SEARCH }
  ),
  withStyles(styles, { withTheme: true })
)(OrderView);
