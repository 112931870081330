import React,{Component} from 'react';
import {create} from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import {createGenerateClassName} from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const generateClassName = createGenerateClassName();

// export default props => (
//     <StylesProvider  jss={jss} generateClassName={generateClassName}>
//         <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
//     </StylesProvider>
// );

class Rtl extends Component{
  render(){
    const theme = createMuiTheme({
      direction: this.props.Rtl === true ? 'rtl' : 'ltr',
      typography:  {
        fontFamily: '"Noto Sans"',
      }
    });
    return(
      <StylesProvider  jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>{this.props.children}</MuiThemeProvider>
      </StylesProvider>
    )
  }
}

const mapStateToProps = state => {
  return {
    Rtl: state.util.Rtl
  }
}

export default connect(
  mapStateToProps,
  null
)(Rtl)