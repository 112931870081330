import React from "react";
import { Route, Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
const RouteGuard = ({ component: Component, ...rest }) => {
  const username = sessionStorage.getItem("username");
  return (
    <Route
      {...rest}
      render={(props) =>
        username === null ? (
          <Redirect
            to={{
              pathname: "/auth/login-page",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    Rtl: state.util.Rtl,
    username: state.InventoryInfo.username,
    IventoryDataSearch: state.InventoryInfo.IventoryDataSearch,
  };
};
export default compose(
  connect(
    mapStateToProps,
    null
  )
)(RouteGuard);
