/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import history from "../src/config/history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
//components
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
//css
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "assets/fonts/Notosans/NotoSans-Black.ttf";
//redux
import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer } from "./store/reducers/commonReducer";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import RouteGuard from "RouteGuard";
//internationalization
import { I18nextProvider } from "react-i18next";
import i18n from "./internationalization/i18n";
import ResetPassword from "../src/pages/auth/ResetPassword";
//Rtl
import RTL from "./Rtl";
import "./index.css";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <RTL>
        <Router history={history}>
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            <Route path="/ResetPassword" exact component={ResetPassword} />
            <Redirect exact from="/" to="/auth/login-page" />
            <RouteGuard path="/admin" component={AdminLayout} />
          </Switch>
        </Router>
      </RTL>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
