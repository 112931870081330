import React, { useState, useEffect, useRef, Fragment } from "react";
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// @material-ui/icons
import LockIcon from '@material-ui/icons/Lock';
//img
import logo from "assets/img/AT&S VMI Logo-03.png";
//css
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import './login.css';

import { apiConfig } from '../../config/api';
import api from '../../config/apiconfig';
import {ResetPasswordValidation}  from '../../validation/LoginValidation';
//internationalization
import { withTranslation } from "react-i18next";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { reduxForm, Field, Form } from "redux-form";
import { renderLoginTextField } from 'utils/components/InputComponent';
import { compose } from "redux";
var CryptoJS = require("crypto-js");
const useStyles = makeStyles(styles);
const ResetPassword = (props) => {
  const search =props.location.search;
  const params = new URLSearchParams(search);
  const sec = params.get('sec');
  let history = useHistory();
  const { t, Rtl, error, loginfail,handleSubmit } = props; // t = translation , Rtl = for right to left
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [direction, setDirection] = useState("ltr");
  const [Resetstatus, setResetstatus] = useState(null);
  const [ResetMessage, setResetMessage] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [match, setmatch] = useState(false);
  
  console.log("reset password",Resetstatus,ResetMessage);

  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const useCompare = (val) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  let prevRtl = useCompare(Rtl);
  useEffect(() => {
    if (prevRtl) {
      Rtl ? setDirection("rtl") : setDirection("ltr");
    }
  }, [Rtl, prevRtl]);

//handle password submit
const PasswordSubmit=(formvalues)=>{
  sessionStorage.setItem("tokens",sec);
  setmatch(formvalues.password===formvalues.confirmpassword?false:true)
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(formvalues.password),'atswe1c0me@4i').toString();
 if(formvalues.password===formvalues.confirmpassword?true:false){
  setSpinner(true)
 api.post(apiConfig.ResetPassword, { password:  ciphertext},{ handlerEnabled: true }).then(res => {
        setSpinner(false)
        setResetstatus(res.data.STATUS === "SUCCESS"?true:false); 
        setResetMessage(res.data.MESSAGE);      
   }).catch(error => {
        setSpinner(false)
        setResetstatus(false)
        
        if(error.response.data.message==="Reset Link Expired")
        setResetMessage("Reset Pasword link expired!");
        else
        setResetMessage("Some thing went wrong! Please try again later!");

      });
  }
  

}


  //Language Detection
  const detectLanguage = (language) => {
    props.i18n.changeLanguage(language);
    props.RTL_SUPPORT(language)
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem>
          <div className="logoheader">
            <img src={logo} alt="logo" />
          </div>
         
         
            <Card login className={classes[cardAnimaton]+" "+"logincard"}>
              <CardBody className="alignCenter">
                <span style={{fontSize:"24px"}}>Reset Password</span>
                {Resetstatus ===null?null:Resetstatus? <div className="loginsuccess"><Alert severity="success">{ResetMessage}</Alert></div> : <div className="loginerror"><Alert severity="error">{ResetMessage}</Alert></div>}
                <Form autoComplete="off"  className="mtop-20" onSubmit={handleSubmit(PasswordSubmit)}>
        <Field
          name="password"
          type="password"
          className={classes.textField + " " + classes.bottomGap + " " + classes.root}
          component={renderLoginTextField}
          placeholder="Enter New password"
          mline={false}
          direction={direction}
          rows={1}
          formControlProps={{
            // fullWidth: true
          }}
          inputProps= {{maxLength:30}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon  className="usericon"/>
              </InputAdornment>
            ),
          }}
        />
        <Field
          name="confirmpassword"
          type="password"
          className={classes.textField + " " + classes.bottomGap + " " + classes.root}
          component={renderLoginTextField}
          placeholder="Confirm Password"
          mline={false}
          direction={direction}
          rows={1}
          inputProps= {{maxLength:30}}
          formControlProps={{
            // fullWidth: true
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
               <LockIcon  className="usericon"/>
              </InputAdornment>
            ),
          }}
        />
        {match ? <div className="loginerror">Password and Confirm Password should be same</div> :null }
        <div className="alignCenter">
          <Button variant="contained" disableElevation className="loginBtn" type="submit">
           Submit
          </Button>
        </div>
      </Form>
              </CardBody>
              <div className="alignCenter">
                {spinner ? (
                  <div className="spinner">
                    <CircularProgress className="innerspinner" />
                  </div>
                ) : null}
               
                <div className="forgotPwd">
                {/* <Snackbar open={alert}
           autoHideDuration={80000}
           className="snackbar"
           onClose={()=>{setalert(false)}}
           anchorOrigin={{
            vertical: 'center',
            horizontal: 'bottom',
          }}
          >
            {Resetstatus?<Alert severity="success">Reset has been reset successfully!</Alert>:<Alert severity="error">Something went wrong please try after sometime</Alert>}
            
          </Snackbar> */}
                  <a onClick={()=>{history.push('/auth/login-page');}}> Login </a>
                </div> 
                </div> 
             
            </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}




export default compose(
    reduxForm({
        form: "ResetPasswordForm",
        validate: ResetPasswordValidation
      }),
  withTranslation("translations")
)(ResetPassword)