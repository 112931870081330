import React, { useState, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import CardHeader from "components/Card/CardHeader.js";
import { ChangePasswordValidation } from "../../../validation/LoginValidation";
import Alert from "@material-ui/lab/Alert";
import { reduxForm, Field, Form } from "redux-form";
import { renderLoginTextField } from "utils/components/InputComponent";
import { compose } from "redux";
import LockIcon from "@material-ui/icons/Lock";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import CircularProgress from "@material-ui/core/CircularProgress";
var CryptoJS = require("crypto-js");
const useStyles = makeStyles(styles);
const SecurityPrivacy = (props) => {
  window.scrollTo(0, 0);
  const classes = useStyles();
  const { t, Rtl, error, loginfail, handleSubmit } = props;
  const [direction, setDirection] = useState("ltr");
  const [match, setmatch] = useState(false);
  const [oldnewmatch, setoldnewmatch] = useState(false);
  const [Passwordstatus, setPasswordstatus] = useState(null);
  const [PasswordMessage, setPasswordMessage] = useState(null);
  const [spinner, setSpinner] = useState(false);
const PasswordSubmit = (formvalues) => {
    setmatch(formvalues.password === formvalues.confirmpassword ? false : true);
    if (formvalues.password === formvalues.confirmpassword) {
      setoldnewmatch(
        formvalues.oldpassword === formvalues.password ? true : false
      );
    }
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(formvalues.password),
      "atswe1c0me@4i"
    ).toString();
    var oldpassword = CryptoJS.AES.encrypt(
      JSON.stringify(formvalues.oldpassword),
      "atswe1c0me@4i"
    ).toString();
if (
      (formvalues.password === formvalues.confirmpassword ? true : false) &&
      (formvalues.oldpassword === formvalues.password ? false : true)
    ) {
      setSpinner(true);
      api
        .post(
          apiConfig.ChangePassword,
          { password: ciphertext, oldpassword: oldpassword },
          { handlerEnabled: true }
        )
        .then((res) => {
          setSpinner(false);
          setPasswordstatus(res.data.STATUS === "SUCCESS" ? true : false);
          setPasswordMessage(res.data.MESSAGE);
})
        .catch((error) => {});
    }
  };

  return (
    <div>
      <Fragment>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader className="personalCard">Change Password</CardHeader>
              <CardBody>
 <GridContainer className="alignCenter">
                  {Passwordstatus === null ? null : Passwordstatus ? (
                    <div className="loginsuccess alertbottommargin">
                      <Alert severity="success">{PasswordMessage}</Alert>
                    </div>
                  ) : (
                    <div className="loginerror alertbottommargin">
                      <Alert severity="error">{PasswordMessage}</Alert>
                    </div>
                  )}

                  <Form
                    autoComplete="off"
                    className="changepwdform"
                    onSubmit={handleSubmit(PasswordSubmit)}
                  >
                    <Field
                      name="oldpassword"
                      type="password"
                      className={
                        classes.textField +
                        " " +
                        classes.bottomGap +
                        " " +
                        classes.root
                      }
                      component={renderLoginTextField}
                      placeholder="Current Password"
                      mline={false}
                      direction={direction}
                      rows={1}
                      formControlProps={
                        {
                          // fullWidth: true
                        }
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon className="usericon" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      name="password"
                      type="password"
                      className={
                        classes.textField +
                        " " +
                        classes.bottomGap +
                        " " +
                        classes.root
                      }
                      component={renderLoginTextField}
                      placeholder="Enter New Password"
                      mline={false}
                      direction={direction}
                      rows={1}
                      formControlProps={
                        {
                          // fullWidth: true
                        }
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon className="usericon" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      name="confirmpassword"
                      type="password"
                      className={
                        classes.textField +
                        " " +
                        classes.bottomGap +
                        " " +
                        classes.root
                      }
                      component={renderLoginTextField}
                      placeholder="Confirm Password"
                      mline={false}
                      direction={direction}
                      rows={1}
                      formControlProps={
                        {
                          // fullWidth: true
                        }
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon className="usericon" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {spinner ? (
                      <div className="spinner">
                        <CircularProgress className="innerspinner" />
                      </div>
                    ) : null}
                    {match ? (
                      <div className="loginerror alertbottommargin">
                        Password and Confirm Password should be same
                      </div>
                    ) : null}
                    {oldnewmatch ? (
                      <div className="loginerror alertbottommargin">
                        Old Password and New Password Cannot be same
                      </div>
                    ) : null}
                    <div className="alignCenter">
                      <Button
                        variant="contained"
                        disableElevation
                        className="loginBtn"
                        type="submit"
                      >
                        submit
                      </Button>
                    </div>
                  </Form>
                </GridContainer>
                {/* } */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fragment>
    </div>
  );
};

export default compose(
  reduxForm({
    form: "SecurityPrivacyForm",
    validate: ChangePasswordValidation,
  })
)(SecurityPrivacy);
