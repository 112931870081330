import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Symbols,
  Surface,
  LabelList,
  Label,
  ResponsiveContainer,
} from "recharts";
import _ from "lodash";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
const colors = [
  "#1b9093",
  "#8ddfe3",
  "#ffcb97",
  "#f2f0a2",
  "#ee916c",
  "#7acdf6",
  "#ffc000",
  "#ff8b8b",
  "#8dfbc1",
  "#76a6c4",
  "#7ccede",
  "#b48fff",
  "#8ddfe3",
  "#8ddfe3",
  "#8ddfe3",
  "#8ddfe3",
  "#8ddfe3",
  "#8ddfe3",
  "#8ddfe3",
  "#8ddfe3",
  "#8ddfe3",
  "#8ddfe3",
];
class PurchaseByQuantity extends Component {
  renderCusomizedLegend = (barvalues) => {
    return (
      <div>
        {barvalues.map((data, i) => {
          return (
            <span>
              <Surface width={10} height={10} viewBox="0 0 10 10">
                <Symbols
                  cx={5}
                  cy={5}
                  type="circle"
                  size={50}
                  fill={colors[i % 20]}
                />
              </Surface>
              <span style={{ marginRight: "10px" }}>{data}</span>
            </span>
          );
        })}
      </div>
    );
  };
  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="tooltipbg">
          <p className="label" style={{ color: "black" }}>
            {label}
          </p>
          {payload.map((data, i) => (
            <div>
              <p className="label" style={{ color: "black" }}>{`${
                data.dataKey
              }:  ${parseFloat(data.value)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</p>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };
  render() {
    var bardata = [];
    var barvalues = [];
    var PurchaseData = this.props.PurchaseQuantityData;
    if (this.props.Period === "QUARTERLY") {
      let groupByYear = _.groupBy(PurchaseData, "YEAR");
      let yearKeys = Object.keys(groupByYear);
      yearKeys.sort().reverse();
      for (let j = 0; j < yearKeys.length; j++) {
        let year = yearKeys[j];
        var quarterQ1 = {};
        var quarterQ2 = {};
        var quarterQ3 = {};
        var quarterQ4 = {};
        var displayQ1 = `Q1${year}`;
        var displayQ2 = `Q2${year}`;
        var displayQ3 = `Q3${year}`;
        var displayQ4 = `Q4${year}`;
        groupByYear[year].map((data, i) => {
          var innerkey = Object.keys(data).map((innerobject, i) => innerobject);
          if (data.QUARTER === "Q1") {
            console.log("data.QUARTER", data.QUARTER);
            quarterQ1[data[innerkey[1]]] = parseFloat(
              data[innerkey[0]].toFixed(2)
            );
            barvalues.push(data[innerkey[1]]);
          } else if (data.QUARTER === "Q2") {
            quarterQ2[data[innerkey[1]]] = parseFloat(
              data[innerkey[0]].toFixed(2)
            );
            barvalues.push(data[innerkey[1]]);
          } else if (data.QUARTER === "Q3") {
            quarterQ3[data[innerkey[1]]] = parseFloat(
              data[innerkey[0]].toFixed(2)
            );
            barvalues.push(data[innerkey[1]]);
          } else {
            quarterQ4[data[innerkey[1]]] = parseFloat(
              data[innerkey[0]].toFixed(2)
            );
            barvalues.push(data[innerkey[1]]);
          }
        });

        quarterQ1.year = displayQ1;
        quarterQ2.year = displayQ2;
        quarterQ3.year = displayQ3;
        quarterQ4.year = displayQ4;
        bardata.push(quarterQ1, quarterQ2, quarterQ3, quarterQ4);
      }
      barvalues = [...new Set(barvalues)];
    } else {
      var grouped = _.mapValues(_.groupBy(PurchaseData, "YEAR"), (clist) =>
        clist.map((PurchaseData) => _.omit(PurchaseData, "YEAR"))
      );
      for (const [key, value] of Object.entries(grouped)) {
        var innerarray = [];
        value.map((data, i) => {
          var innerkey = Object.keys(data).map((innerobject, i) => innerobject);
          innerarray.push({
            category: data[innerkey[1]],
            amount: parseFloat(data[innerkey[0]].toFixed(2)),
          });
          barvalues.push(data[innerkey[1]]);
        });
        var newObj = Object.assign(
          {},
          ...innerarray.map((item) => ({ [item.category]: item.amount }))
        );
        newObj.year = key;
        bardata.push(newObj);
      }
      barvalues = [...new Set(barvalues)];
    }
    return (
      <div>
        <GridContainer className="ordertable">
          <GridItem xs={12}>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                <div className="grapheader">Purchase History (By Quantity)</div>
                <div style={{ width: "100%", height: 300 }}>
                  {this.props.apiSpinner === false ? (
                    this.props.PurchaseQuantityData.length === 0 ? (
                      <div className="nodatafound">No Data Found</div>
                    ) : (
                      <ResponsiveContainer>
                        <BarChart
                          data={bardata}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                          maxBarSize={100}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="year">
                            <Label
                              value={this.props.headervalues}
                              offset={0}
                              position="insideBottomRight"
                            />
                          </XAxis>
                          <YAxis
                            label={{
                              value: "Quantity",
                              angle: -90,
                              position: "insideBottomLeft",
                            }}
                          />
                          <Tooltip content={this.CustomTooltip} />
                          <Legend
                            verticalAlign="top"
                            content={this.renderCusomizedLegend(barvalues)}
                            height={36}
                          />
                          {barvalues.map((barval, i) => (
                            <Bar
                              dataKey={barval}
                              isAnimationActive={false}
                              background={{ fill: "#eee" }}
                            >
                              <LabelList
                                dataKey={barval}
                                position="top"
                                style={{ fontSize: "15px" }}
                              />
                              {bardata.map((_, ind) => (
                                <Cell key={`cell-${i}`} fill={colors[i % 20]} />
                              ))}
                            </Bar>
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    )
                  ) : (
                    <div className="spinner">
                      <CircularProgress className="innerspinner" />
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PurchaseData: state.ReportInfo.PurchaseData,
    PurchaseQuantityData: state.ReportInfo.PurchaseQuantityData,
    apiSpinner: state.util.spinner,
  };
};
export default compose(
  connect(
    mapStateToProps,
    null
  )
)(PurchaseByQuantity);
