import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GET_MYORDERINFO } from "../../../store/actions/index";
import { compose } from "redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { MYORDER_SEARCH } from "../../../store/actions/index";
import DocumentView from "./DocumentView";
import ReportDownload from "./ReportDownload";
const styles = (theme) => ({
  materialinput: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      //borderBottomColor: 'yellow',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: ooredooRedColor,
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
  table: {
    width: "max-content",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hover: {},
  selected: {},
});
const columns = [
  {
    id: "Order Number",
    label: "Order Number",
    align: "left",
    minWidth: 125,
  },
  {
    id: "Delivery Number",
    label: "Delivery Number",
    align: "left",
    minWidth: 135,
  },
  {
    id: "Customer PO",
    label: "Customer PO",
    align: "left",
    minWidth: 225,
  },
  {
    id: "Order Date",
    label: "Order Date",
    align: "left",
    minWidth: 100,
  },
  {
    id: "Status",
    label: "Status",
    align: "left",
    minWidth: 135,
  },
  {
    id: "Order Total",
    label: "Order Total",
    align: "right",
    minWidth: 100,
  },
  {
    id: "Shipment Total",
    label: "Shipment Total",
    align: "right",
    minWidth: 100,
  },
  {
    id: "DO",
    label: "DO",
    align: "center",
    minWidth: 100,
  },
  {
    id: "Packing List",
    label: "Packing List",
    align: "center",
    minWidth: 100,
  },
  {
    id: "Shipping Invoice",
    label: "Shipping Invoice",
    align: "center",
    minWidth: 100,
  },

  {
    id: "Proforma Invoice",
    label: "Proforma Invoice",
    align: "center",
    minWidth: 100,
  },
  {
    id: "Tax Invoice",
    label: "Tax Invoice",
    align: "center",
    minWidth: 100,
  },
  {
    id: "Upload Document",
    label: "Upload Document",
    align: "center",
    minWidth: 100,
  },
];

class MyOrderViews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      emptyData: false,
      inventoryData: [],
      rowsPerPage: 5,
      isFixed: false,
      portalPlacement: "bottom",
      page: 0,
      ModelDialog: false,
      addtionalInfo: [],
      lovresult: [],
      selecteProductClass: null,
      ProductClassLov: [],
      selectedRowId: null,
      dialogopen: false,
      selectedRow: {},
      documentViewType: "upload",
    };
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  openDocument = (data, type) => {
    this.setState({
      dialogopen: true,
      selectedRow: data,
      documentViewType: type,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.Myorders_Data();
  }
  Myorders_Data = () => {
    api
      .get(apiConfig.MyOrders, { handlerEnabled: true })
      .then((res) => {
        this.props.GET_MYORDERINFO(res.data);
        this.props.MYORDER_SEARCH(res.data);
        this.setState({ spinner: false });
      })
      .catch((error) => {});
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.spinner ? (
          <div className="spinner">
            <CircularProgress className="innerspinner" />
          </div>
        ) : null}
        <Dialog
          open={this.state.dialogopen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <DocumentView
                selectedRow={this.state.selectedRow}
                type={this.state.documentViewType}
              ></DocumentView>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="Orderbutton"
              onClick={() => this.setState({ dialogopen: false })}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <GridContainer className="ordertable">
          <GridItem xs={12}>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {this.state.spinner === false ? (
                  <Fragment>
                    <Table>
                      <TableHead>
                        <TableRow hover>
                          {columns.map((column) => (
                            <TableCell
                              className="bold tablePadding"
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.MyordersData.slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        ).map((data, i) => {
                          console.log("MyordersData", data);

                          return (
                            <TableRow
                              hover
                              onClick={() => {
                                this.setState({ selectedRowId: i });
                              }}
                              selected={this.state.selectedRowId === i}
                              key={i}
                              classes={{
                                hover: classes.hover,
                                selected: classes.selected,
                              }}
                              className={classes.tableRow}
                            >
                              <TableCell
                                align="left"
                                className="tablePadding tablecell"
                              >
                                {data.ORDER_NUMBER}
                              </TableCell>
                              <TableCell align="left" className="tablePadding">
                                {data.DO_NUMBER}
                              </TableCell>
                              <TableCell align="left" className="tablePadding">
                                {data.CUST_PO_NUMBER}
                              </TableCell>
                              <TableCell align="left" className="tablePadding">
                                {Moment(data.ORDERED_DATE).format("MM/DD/YYYY")}
                              </TableCell>
                              <TableCell align="left" className="tablePadding">
                                {data.STATUS}
                              </TableCell>
                              <TableCell align="right" className="tablePadding">
                                {parseFloat(data.ORDER_AMOUNT)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </TableCell>
                              <TableCell align="right" className="tablePadding">
                                {parseFloat(data.DO_AMOUNT)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tablePadding"
                              >
                                <ReportDownload
                                  className="attachicon"
                                  type="DO"
                                  value={data.DO_REPORT_PATH}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tablePadding"
                              >
                                <ReportDownload
                                  className="attachicon"
                                  type="PL"
                                  value={data.PACKING_LIST_REPORT_PATH}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tablePadding"
                              >
                                <ReportDownload
                                  className="attachicon"
                                  type="SI"
                                  value={data.SHIPPING_INVOICE_REPORT_PATH}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tablePadding"
                              >
                                <ReportDownload
                                  className="attachicon"
                                  type="PI"
                                  value={data.PROFORMA_INVOICE_REPORT_PATH}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tablePadding"
                              >
                                <ReportDownload
                                  className="attachicon"
                                  type="TI"
                                  value={data.TAX_INVOICE_REPORT_PATH}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tablePadding"
                              >
                                {data.DO_NUMBER ? (
                                  <AttachFileIcon
                                    className="attachicon"
                                    onClick={() =>
                                      this.openDocument(data, "DOWNLOAD")
                                    }
                                  />
                                ) : (
                                  <AttachFileIcon
                                    className="attachicon"
                                    style={{ color: "grey" }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={this.props.MyordersData.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            SelectProps={{
                              inputProps: { "aria-label": "rows per page" },
                              native: true,
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                    {this.props.MyordersData.length === 0 ? (
                      <div className="nodatafound">No Data Found</div>
                    ) : null}
                  </Fragment>
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserInfo: state.UserInfo.userInfo,
    MyordersData: state.OrderFulfillmentInfo.MyordersData,
  };
};
export default compose(
  connect(
    mapStateToProps,
    { GET_MYORDERINFO, MYORDER_SEARCH }
  ),
  withStyles(styles, { withTheme: true })
)(MyOrderViews);
