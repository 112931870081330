const INITIAL_STATE = {
  IventoryData: [],
  AddtoCartQuantity: [],
  selectedOrder: [],
  MyOrderSearch: [],
  OrderheaderDet: {},
  MyordersData: [],
  IventoryDataSearch: [],
  IventorySaleData: [],
  IventorySaleDataSearch: [],
  username: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ORDERFULFILLMENT_DATA":
      return {
        ...state,
        IventoryData: action.payload,
      };
    case "ADDTO_CART_QUANTITY":
      return {
        ...state,
        AddtoCartQuantity: action.payload,
      };
    case "SELECTED_ORDER":
      return {
        ...state,
        selectedOrder: action.payload,
      };
    case "MYORDER_SEARCH":
      return {
        ...state,
        MyOrderSearch: action.payload,
      };

    case "GET_MYORDERINFO":
      return {
        ...state,
        MyordersData: action.payload,
      };

    default:
      return state;
  }
};
