const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const validateLogin = (values) => {
  const errors = {};
  const requiredFields = ["username", "password"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (!values.username) {
    errors.username = "Enter your Username";
  }
  if (!values.password) {
    errors.password = "Enter your Password";
  }
  if (values.password && values.password.length < 6) {
    errors.password = "Password should have more than 6 characters";
  }

  return errors;
};

export const validateEmail = (values) => {
  const errors = {};
  const requiredFields = ["username"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (!values.username) {
    errors.username = "Enter your Username";
  }

  if (values.username && values.username.match(mailformat) ? false : true) {
    errors.username = "You have entered an invalid email address!";
  }

  return errors;
};

export const ResetPasswordValidation = (values) => {
  const errors = {};
  const requiredFields = ["password", "confirmpassword"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (!values.password) {
    errors.password = "Enter your password";
  }

  if (!values.confirmpassword) {
    errors.confirmpassword = "Enter your confirm password";
  }

  if (values.password && values.password.length < 6) {
    errors.password = "Password should have more than 6 characters";
  }
  return errors;
};

export const ChangePasswordValidation = (values) => {
  const errors = {};
  const requiredFields = ["oldpassword", "password", "confirmpassword"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  if (!values.oldpassword) {
    errors.oldpassword = "Enter your password";
  }
  if (!values.password) {
    errors.password = "Enter your new password";
  }

  if (!values.confirmpassword) {
    errors.confirmpassword = "Enter confirm new password";
  }

  if (values.password && values.password.length < 6) {
    errors.password = "Password should have more than 6 characters";
  }

  return errors;
};
