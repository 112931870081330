import React, { Component, Fragment } from "react";
import { apiConfig } from "../../../config/api";
import api from "../../../config/apiconfig";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CircularProgress from "@material-ui/core/CircularProgress";
class AccountManagement extends Component {
  state = {
    accessdetailsArray: [],
    page: 0,
    rowsPerPage: 5,
    spinner: true,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    api.get(apiConfig.AccessDetails, { handlerEnabled: true }).then((res) => {
      this.setState({ accessdetailsArray: res.data, spinner: false });
    });
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  render() {
    const tableRow = [
      "Contact Name",
      "Contact No",
      "Contact Email Address",
      "Access Level",
    ];
    return (
      <Fragment>
        {this.state.spinner === true ? (
          <div className="spinner">
            <CircularProgress className="innerspinner" />
          </div>
        ) : (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardBody style={{ overflowX: "scroll" }}>
                  <Table style={{ width: "max-content" }}>
                    <TableHead>
                      <TableRow>
                        {tableRow.map((data, i) => (
                          <TableCell className="bold tablePadding" key={i}>
                            {data}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.accessdetailsArray
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((data, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell className="tablePadding tablecell">
                                {data.CONTACT_NAME}
                              </TableCell>
                              <TableCell className="tablePadding">
                                {data.CONTACT_NO}
                              </TableCell>
                              <TableCell className="tablePadding">
                                {data.CONTACT_EMAIL}
                              </TableCell>
                              <TableCell className="tablePadding">
                                {data.ACCESS_LEVEL}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          count={this.state.accessdetailsArray.length}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </Fragment>
    );
  }
}
export default AccountManagement;
